import React, { useContext } from 'react';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Checkbox, Icon } from 'semantic-ui-react';
import { StoreContext } from '../../contexts/store';

import CbxNamHoc from '../../components/editor/cmb_namhoc';
import ComboboxChienDichMultiple from '../../components/editor/cmb_chiendich_multiple';
import ComboboxChienDichTrangThaiMultiple from '../../components/editor/cmb_chiendich_trangthai_multiple';
import { DropDownButton } from 'devextreme-react';

const ToolbarDataGrid = ({
  set_is_show_confirm_change_status,
  grid,
  set_is_show_modal,
  set_is_show_import_modal,
  list_campaign_selected,
  chiendich,
  setChienDich,
  trangThai,
  setTrangThai,
  namhoc,
  setNamhoc,
  displayAll,
  setDisplayAll,
  set_selected_campaign,
}) => {
  const { can_accessed } = useContext(StoreContext);
  const btnAdd_OnItemClick = (e) => {
    if (e.itemData.id === 1) {
      set_selected_campaign(0);
      set_is_show_modal(true);
    }
    if (e.itemData.id === 2) {
      set_selected_campaign(0);
      set_is_show_import_modal(true);
    }
  };
  return (
    <>
      <Toolbar className="bg-background pr-2">
        <Item
          location="before"
          render={() => {
            return <h3>Danh sách khách hàng</h3>;
          }}
        />

        <Item
          location="after"
          render={() => (
            <Checkbox
              defaultChecked={displayAll}
              onChange={() => setDisplayAll(!displayAll)}
              label={<label>Hiển thị tất cả</label>}
            />
          )}
        />
        <Item
          location="after"
          render={() => <CbxNamHoc value={namhoc} onValueChanged={setNamhoc} />}
        />
        <Item
          location="after"
          render={() => (
            <ComboboxChienDichMultiple
              namhoc={namhoc}
              value={chiendich}
              onValueChanged={setChienDich}
            />
          )}
        />
        <Item
          location="after"
          render={() => (
            <ComboboxChienDichTrangThaiMultiple
              value={trangThai}
              onValueChanged={setTrangThai}
            />
          )}
        />
      </Toolbar>
      <Toolbar className="bg-background" style={{ marginTop: '-1rem' }}>
        {can_accessed('api/ts_chiendich_phuhuynh/insert') && (
          <Item
            location="after"
            locateInMenu="never"
            render={() => {
              return (
                <React.Fragment>
                  <DropDownButton
                    text="Thêm"
                    className="PrimaryDropDownButton"
                    icon="fas fa-plus"
                    dropDownOptions={{ width: 230 }}
                    displayExpr="name"
                    keyExpr="id"
                    items={[
                      { id: 1, name: 'Nhập thủ công', icon: 'user' },
                      { id: 2, name: 'Import từ excel', icon: 'xlsfile' },
                    ]}
                    onItemClick={btnAdd_OnItemClick}
                  />
                </React.Fragment>
              );
            }}
          />
        )}
        <Item
          location="after"
          render={() => {
            return (
              <a
                className="p-btn p-btn-mob p-lime p-white-color p-white-color p-btn-sm"
                onClick={() => {
                  set_is_show_confirm_change_status(true);
                }}
              >
                <Icon name="check"></Icon>
                Gán CSKH{' '}
                {list_campaign_selected.length > 0
                  ? '(' + list_campaign_selected.length + ')'
                  : ''}
              </a>
            );
          }}
        />

        <Item
          location="after"
          render={() => {
            return (
              <a
                className="p-btn  p-btn-sm"
                onClick={() => {
                  grid.current.instance.exportToExcel();
                }}
              >
                <Icon name="file excel outline"></Icon>
                Xuất excel
              </a>
            );
          }}
        />
      </Toolbar>
    </>
  );
};

export default ToolbarDataGrid;
