import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export async function select_by_campaign(campaing_id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/select_by_campaign',
    campaing_id
  );
}

export async function campaign_detail_insert(data) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/insert',
    data
  );
}

export async function campaign_detail_update(data) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/update',
    data
  );
}

export async function campaign_detail_select_by_id(id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/select_by_id',
    id
  );
}
export async function campaign_detail_remove(id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/delete',
    id
  );
}
export async function campaign_detail_toggle_status(id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh_chitiet/toggle_status',
    id
  );
}
