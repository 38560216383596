import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DateBox } from 'devextreme-react';
import TextArea from 'devextreme-react/text-area';

import { Label, Modal, Loader } from 'semantic-ui-react';
import {
  campaign_detail_select_by_id,
  campaign_detail_update,
  campaign_detail_insert,
} from '../../../api/ts_chiendich_phuhuynh_chitiet_api';
import { StoreContext } from '../../../contexts/store';

const DetailModal = ({
  is_show_model,
  id,
  onSuccess,
  onCancel,
  campaignId,
}) => {
  const [is_saving, set_is_saving] = useState(false);
  const { set_notification } = useContext(StoreContext);
  const [campaign_detail, set_campaign_detail] = useState({});
  const { register, trigger, errors } = useForm();
  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);

  const reload = async () => {
    if (id > 0) {
      const res = await campaign_detail_select_by_id(id);
      if (res.is_success) {
        set_campaign_detail(res.data);
      }
    } else {
      set_campaign_detail({ ngay_tu_van: new Date() });
    }
  };
  const { ts_chiendich_phuhuynh_id, ngay_tu_van, noi_dung_tu_van } =
    campaign_detail;

  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (id > 0) {
          set_is_saving(true);
          const res = await campaign_detail_update(campaign_detail);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: 'Cập nhật thông tin thành công',
              type: 'success',
            });
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await campaign_detail_insert({
            ...campaign_detail,
            ts_chiendich_phuhuynh_id: campaignId,
          });
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: 'Thêm mới thông tin thành công',
              type: 'success',
            });
          } else {
            set_notification({ text: res.message, type: 'error' });
          }
        }
      }
    });
  };
  const onCancelModal = () => {
    onCancel();
    set_campaign_detail({});
  };
  return (
    <div>
      <Modal
        open={is_show_model}
        size="mini"
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={id}></input>
            <input
              className="hide"
              name="ts_chiendich_phuhuynh_id"
              defaultValue={ts_chiendich_phuhuynh_id}
            ></input>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Ngày tư vấn</div>
                <br></br>
                <DateBox
                  type="date"
                  value={ngay_tu_van}
                  stylingMode="outlined"
                  displayFormat="dd/MM/yyyy"
                  onValueChanged={(e) => {
                    debugger;
                    set_campaign_detail(
                      Object.assign({}, campaign_detail, {
                        ngay_tu_van: e.value,
                      })
                    );
                  }}
                />
                <input
                  name="ngay_tu_van"
                  className="hide"
                  defaultValue={ngay_tu_van}
                  value={ngay_tu_van}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.ngay_tu_van && (
                  <Label basic color="red" pointing>
                    Ngày tư vấn không được để trống
                  </Label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Nội dung tư vấn</div>
                <br></br>
                <TextArea
                  autoResizeEnabled={true}
                  value={noi_dung_tu_van}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign_detail(
                      Object.assign({}, campaign_detail, {
                        noi_dung_tu_van: e.value,
                      })
                    );
                  }}
                />
                <input
                  name="noi_dung_tu_van"
                  className="hide"
                  defaultValue={noi_dung_tu_van}
                  value={noi_dung_tu_van}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.noi_dung_tu_van && (
                  <Label basic color="red" pointing>
                    Nội dung tư vấn không được để trống
                  </Label>
                )}
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={onCancelModal} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? 'Cập nhật' : 'Thêm mới'}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DetailModal;
