import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { select_lop } from '../../api/common_api_combo';
import { useAuth } from '../../contexts/auth';
export default ({ value, onValueChanged, cmb, nam_hoc, dm_truong_id, dm_khoi_id, dm_he_id, showClearButton, is_readonly }) => {
    const [dm_lops, set_dm_lops] = useState([]);

    useEffect(() => {
        (async () => {
            let res = null;
            if (dm_truong_id > 0 && dm_khoi_id > 0 && dm_he_id > 0) {
                res = await select_lop({
                    nam_hoc:nam_hoc,
                    id_truong: dm_truong_id,
                    id_khoi: dm_khoi_id,
                    id_he: dm_he_id
                });
                if (res.is_success) {
                    set_dm_lops(res.data);
                } else {
                    set_dm_lops([]);
                }
            } else {
                set_dm_lops([]);
            }

        })();

    }, [dm_truong_id, dm_khoi_id, dm_he_id]);
    return (
        <SelectBox dataSource={dm_lops}
            displayExpr={"ten_lop"}
            valueExpr={"id"}
            value={value}
            readOnly={is_readonly === true ? is_readonly : false}
            stylingMode="outlined"
            ref={cmb}
            placeholder="Chọn lớp"
            width="100%"
            showClearButton={showClearButton}
            onValueChanged={(e) => {
                onValueChanged(e.value);
            }} />
    );
};





