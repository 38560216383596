import { TextBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { SelectBox } from 'devextreme-react/select-box';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Confirm, Label } from "semantic-ui-react";
import { select_all as dm_capquanly_cbnv_select_all } from '../../api/dm_capquanly_cbnv';
import { select_all as dm_quanhe_cbnv_select_all } from '../../api/dm_quanhe_cbnv';
import { select_all as pnl_select_all } from '../../api/pnl';
import { insert, remove, select_by_hocsinh, update } from '../../api/ts_thongtin_cbnv';
import DefaultDataGrid from '../../components/datagrid/datagrid';
import { StoreContext } from "../../contexts/store";
export default ({ ts_hocsinh_id, height }) => {
    const grid = useRef(null);
    const [datasource, set_datasource] = useState([]);
    const [dm_capquanly_cbnvs, set_dm_capquanly_cbnvs] = useState([]);
    const [dm_quanhe_cbnvs, set_dm_quanhe_cbnvs] = useState([]);
    const [pnls, set_pnls] = useState([]);
    const [is_show_edit_form, set_is_show_edit_form] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);

    const [ts_thongtin_cbnv_id, set_ts_thongtin_cbnv_id] = useState(0);
    const [ts_thongtin_cbnv, set_ts_thongtin_cbnv] = useState(0);
    const { set_notification } = useContext(StoreContext);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {

        trigger().then(async (isValid) => {
            if (isValid) {
                var result = {};
                if (ts_thongtin_cbnv_id == 0) {
                    result = await insert({ ...ts_thongtin_cbnv, ts_hocsinh_id: ts_hocsinh_id });
                } else {
                    result = await update({ ...ts_thongtin_cbnv, id: ts_thongtin_cbnv_id, ts_hocsinh_id: ts_hocsinh_id });
                }
                if (result.is_success) {
                    set_notification({ text: "Cập nhật thành công " + ts_thongtin_cbnv.ma_nhan_vien, type: "success" });
                    set_ts_thongtin_cbnv_id(0);
                    set_is_show_edit_form(false);
                    reload();
                } else {
                    set_notification({ text: result.message, type: "error" })
                }
            }
        })
    }
    useEffect(() => {
        (async () => {
            const res = await dm_capquanly_cbnv_select_all();
            if (res.is_success) {
                set_dm_capquanly_cbnvs(res.data);
            } else {
                set_dm_capquanly_cbnvs([]);
            }
        })();

        (async () => {
            const res = await dm_quanhe_cbnv_select_all();
            if (res.is_success) {
                set_dm_quanhe_cbnvs(res.data);
            } else {
                set_dm_quanhe_cbnvs([]);
            }
        })();

        (async () => {
            const res = await pnl_select_all();
            if (res.is_success) {
                set_pnls(res.data);
            } else {
                set_pnls([]);
            }
        })();

    }, []);
    useEffect(() => {
        reload();
    }, [ts_hocsinh_id]);
    const reload = async () => {
        const res = await select_by_hocsinh({ ts_hocsinh_id: ts_hocsinh_id });
        if (res.is_success) {
            set_datasource(res.data);
        } else {
            set_datasource([]);
        }
    }

    const btnThem_click = () => {
        set_ts_thongtin_cbnv_id(0);
        set_is_show_edit_form(true);
    }
    const btnEdit_click = (data) => {
        set_ts_thongtin_cbnv_id(data.id);
        set_ts_thongtin_cbnv({ ...data, ma_hs: data.ma_hs == "" ? data.ma_tn : data.ma_hs });
        set_is_show_edit_form(true);
    }
    const btnDelete_click = (data) => {
        set_ts_thongtin_cbnv_id(data.id);
        set_ts_thongtin_cbnv({ ...data, ma_hs: data.ma_hs == "" ? data.ma_tn : data.ma_hs });
        set_is_show_confirm_delete(true);
    }
    const confirmDelete = async () => {
        const res = await remove(ts_thongtin_cbnv_id);
        if (res.is_success) {
            reload();
            set_notification({ text: "Cập nhật thành công " + ts_thongtin_cbnv.ma_nhan_vien, type: "success" });
            set_ts_thongtin_cbnv_id(0);
            set_is_show_confirm_delete(false);
        } else {
            set_notification({ text: res.message, type: "error" })
        }
    }
    const cellRender = (data) => {
        return <div>
            <span style={{ cursor: "pointer" }} onClick={() => { btnEdit_click(data.data) }}><i aria-hidden="true" className="icon edit"></i> </span>
            <span style={{ cursor: "pointer" }} onClick={() => { btnDelete_click(data.data) }}><i aria-hidden="true" className="icon trash"></i> </span>

        </div>;
    }
    const column = [
        <Column key={0} cellRender={cellRender} caption="Thao tác" width={100}></Column>,
        <Column key={1} dataField="ma_tn" caption="Mã TN"></Column>,
        <Column key={2} dataField="ma_hs" caption="Mã HS"></Column>,
        <Column key={3} dataField="ho_ten" caption="Họ tên"></Column>,
        <Column key={4} dataField="ma_nhan_vien" caption="Mã nhân viên"></Column>,
        <Column key={5} dataField="ten_nhan_vien" caption="Tên nhân viên"></Column>,
        <Column key={6} dataField="capquanly_cbnv" caption="Cấp QL"></Column>,
        <Column key={6} dataField="ten_pnl" caption="Tên PnL"></Column>,
        <Column key={7} dataField="quanhe_cbnv" caption="Quan hệ"></Column>,

    ];
    return (
        <React.Fragment>
            <div style={{ width: 550, marginLeft: "auto", marginRight: "auto" }}>
                <div className="row padding-2rem">
                    {is_show_edit_form &&
                        <React.Fragment>
                            <div className="col-md-12">
                                <form className="ui">
                                    <input style={{ display: "none" }} name="id" defaultValue={ts_thongtin_cbnv.id}></input>
                                    <div className="row">
                                        {!ts_hocsinh_id > 0 && <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">Mã TN/Mã HS</div>
                                            <br></br>
                                            <TextBox value={ts_thongtin_cbnv.ma_hs}
                                                stylingMode="outlined"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { ma_hs: e.value }));
                                                    trigger("ma_hs");
                                                }}
                                            />
                                            <input name="ma_hs"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.ma_hs}
                                                ref={register({ required: true })}></input>
                                            {
                                                errors.ma_hs &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng điền mã tiềm năng hoặc mã học sinh
                          </Label>
                                            }
                                        </div>
                                        }
                                        <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">Mã nhân viên</div>
                                            <br></br>
                                            <TextBox value={ts_thongtin_cbnv.ma_nhan_vien}
                                                stylingMode="outlined"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { ma_nhan_vien: e.value }));
                                                    trigger("ma_nhan_vien");
                                                }}
                                            />
                                            <input name="ma_nhan_vien"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.ma_nhan_vien}
                                                ref={register({ required: true })}></input>
                                            {
                                                errors.ma_nhan_vien &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng điền mã nhân viên
                          </Label>
                                            }
                                        </div>

                                        <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">Họ tên</div>
                                            <br></br>
                                            <TextBox value={ts_thongtin_cbnv.ten_nhan_vien}
                                                stylingMode="outlined"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { ten_nhan_vien: e.value }));
                                                    trigger("ten_nhan_vien");
                                                }}
                                            />
                                            <input name="ten_nhan_vien"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.ten_nhan_vien}
                                                ref={register({ required: true })}></input>
                                            {
                                                errors.ten_nhan_vien &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng điền tên nhân viên
                          </Label>
                                            }
                                        </div>
                                        <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">Cấp quản lý</div>
                                            <br></br>
                                            <SelectBox dataSource={dm_capquanly_cbnvs}
                                                displayExpr={"capquanly_cbnv"}
                                                valueExpr={"id"}
                                                value={ts_thongtin_cbnv.dm_capquanly_cbnv_id}
                                                stylingMode="outlined"
                                                placeholder="Chọn cấp quản lý"
                                                width="100%"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { dm_capquanly_cbnv_id: e.value }));
                                                    trigger("dm_capquanly_cbnv_id");
                                                }} />
                                            <input name="dm_capquanly_cbnv_id"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.dm_capquanly_cbnv_id}
                                                ref={register({ required: true, min: 1 })}></input>
                                            {
                                                errors.dm_capquanly_cbnv_id &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng chọn cấp quản lý
                          </Label>
                                            }
                                        </div>
                                        <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">PnL</div>
                                            <br></br>
                                            <SelectBox dataSource={pnls}
                                                displayExpr={"ten_pnl"}
                                                valueExpr={"id"}
                                                value={ts_thongtin_cbnv.dm_pnl_id}
                                                stylingMode="outlined"
                                                placeholder="Chọn PnL"
                                                width="100%"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { dm_pnl_id: e.value }));
                                                    trigger("dm_pnl_id");
                                                }} />
                                            <input name="dm_pnl_id"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.dm_pnl_id}
                                                ref={register({ required: true, min: 1 })}></input>
                                            {
                                                errors.dm_pnl_id &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng chọn PnL
                          </Label>
                                            }
                                        </div>
                                        <div className="col-md-6 padding-top-1rem">
                                            <div className="dx-field-label">Quan hệ</div>
                                            <br></br>
                                            <SelectBox dataSource={dm_quanhe_cbnvs}
                                                displayExpr={"quanhe_cbnv"}
                                                valueExpr={"id"}
                                                value={ts_thongtin_cbnv.dm_quanhe_cbnv_id}
                                                stylingMode="outlined"
                                                placeholder="Chọn quan hệ"
                                                width="100%"
                                                onValueChanged={(e) => {
                                                    set_ts_thongtin_cbnv(Object.assign({},
                                                        ts_thongtin_cbnv,
                                                        { dm_quanhe_cbnv_id: e.value }));
                                                    trigger("dm_quanhe_cbnv_id");
                                                }} />
                                            <input name="dm_quanhe_cbnv_id"
                                                style={{ display: "none" }} defaultValue={ts_thongtin_cbnv.dm_quanhe_cbnv_id}
                                                ref={register({ required: true, min: 1 })}></input>
                                            {
                                                errors.dm_quanhe_cbnv_id &&
                                                <Label basic color='red' pointing>
                                                    Vui lòng chọn quan hệ với học sinh
                          </Label>
                                            }
                                        </div>
                                        <div className="col-md-12 padding-top-1rem">
                                            <div className="text-align-center">
                                                <button type="button" onClick={() => { set_is_show_edit_form(false) }} className="p-btn p-btn-sm">
                                                    Đóng
                    </button>
                                                <button type="button" onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                                                    {ts_thongtin_cbnv_id > 0 ? "Cập nhật" : "Thêm mới"}
                                                </button>

                                            </div>
                                        </div>
                                    </div>




                                </form>
                            </div>
                        </React.Fragment>
                    }


                </div>


            </div>
            <div className="row">
                <div className="col-md-12">
                    <Toolbar>
                        {!is_show_edit_form && <Item location="after"
                            render={() => {
                                return (
                                    <button onClick={btnThem_click} className="p-btn p-btn-mob p-lime p-white-color p-white-color p-btn-sm">
                                        <i className="icon plus"></i>
                    Thêm CBNV
                                    </button>
                                );
                            }}
                        />}

                    </Toolbar>

                </div>
            </div>
            <DefaultDataGrid columns={column}
                dataSource={datasource}
                height={height ? height : window.innerHeight - 120}
                keyColumn="id"
                grid={grid}
            />
            <Confirm
                open={is_show_confirm_delete}
                dimmer={"blurring"}
                header='Lưu ý'
                content='Bạn có chắc chắn muốn xóa dữ liệu ?'
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm_delete(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={confirmDelete} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Xóa thông tin CBNV
                        </button>
                    );
                }}
            // onCancel={() => { set_is_show_confirm_delete(false) }}
            // onConfirm={confirmDelete}
            />
        </React.Fragment>
    );
}