import React, { useEffect, useState ,useContext} from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import {SelectBox} from 'devextreme-react/select-box';
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/lop_api";
import { StoreContext } from "../../contexts/store";
import { select_khoi,select_he,select_coso,select_truong } from "../../api/common_api_combo";
import { useAuth } from "../../contexts/auth"

export default ({ is_show_model, id, onSuccess, onCancel,dataNamhoc,dataTruong,dataKhoi,dataHe }) => {

    const { set_notification } = useContext(StoreContext)
    const [datalop, setLop] = useState({});
    const [selectCoSo, setCoSo] = useState({});
    const [selectTruong, setTruong] = useState({});
    const [selectKhoi, setKhoi] = useState();
    const [selectHe, setHe] = useState();
    const { user } = useAuth();

    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                if (datalop.id > 0) {
                    set_is_saving(true);
                    const res = await update(datalop);
                    set_is_saving(false);
                    if (res.is_success) {
                        set_notification({ text: "Cập nhật thành công " , type: "success" })
                        onSuccess();
                    } else {
                        set_notification({ text: res.message, type: "error" });
                    }
                } else {
                    set_is_saving(true);
                    const res = await insert(datalop);
                    set_is_saving(false);
                    if (res.is_success) {
                        set_notification({ text: "Thêm mới thành công " , type: "success" })
                        onSuccess();
                    } else {
                        set_notification({ text: res.message, type: "error" });
                    }
                }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    const reload = async () => {

        if (id > 0) {
            const res = await select_by_id(id);
            if (res.is_success) {
                setLop(res.data)
            }
        } else {
            setLop({});
        }


        let user_id = user.id;
        const resCoSo = await select_coso(user_id);
        if(resCoSo.is_success){
            setCoSo(resCoSo.data)
        }


    }



    const reloadTruong = async (id) => {
        const res = await select_truong(id);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeCoSo = async (id) => { 
        setLop(Object.assign({}, datalop, { co_so_id: id }));
        const truong =  await reloadTruong(id);
        setTruong(truong)
    }

    const reloadKhoi = async (id_truong) => {
        const res = await select_khoi(id_truong);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeTruong = async (id_truong) => { 
        setLop(Object.assign({}, datalop, { dm_truong_id: id_truong }));
        const khoi =  await reloadKhoi(id_truong);
        setKhoi(khoi)
    }
    const reloadHe = async (id_khoi) => {
        const res = await select_he(id_khoi);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeKhoi= async (id_khoi) => { 
        setLop(Object.assign({}, datalop, { dm_khoi_id: id_khoi }));
        const khoi =  await reloadHe(id_khoi);
        setHe(khoi)
    }

    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    {datalop.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <input className="hide" name="id" defaultValue={datalop.id}></input>
                        <div className="row">
                            
                            
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Năm học</div>
                                <br></br>
                                <SelectBox dataSource={dataNamhoc}
                                    disabled={datalop.id? true : false}
                                    displayExpr="nam_hoc"
                                    valueExpr="nam_hoc"
                                    value={datalop.nam_hoc}
                                    placeholder="Chọn năm học"
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        setLop(Object.assign({}, datalop, { nam_hoc: e.value })); trigger("nam_hoc")
                                    }}
                                />
                                <input name="nam_hoc"
                                    className="hide"
                                    defaultValue={datalop.nam_hoc}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.nam_hoc &&
                                    <Label basic color='red' pointing>
                                       Vui lòng chọn năm học
                          </Label>
                                }
                            </div>
                            
                            <div className="col-md-12 padding-top-1rem"  style={{ display: datalop.id? 'none' : ''}} >
                                <div className="dx-field-label">Cơ sở đào tạo</div>
                                <br></br>
                                <SelectBox dataSource={selectCoSo}
                                    placeholder="Chọn cơ sở"
                                    displayExpr="ten_co_so"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {handleChangeCoSo(e.value); trigger("co_so_id")
                                    }}
                                />
                            </div>
                            <input name="co_so_id" type="number"
                                    style={{ display: "none" }} defaultValue={datalop.co_so_id ? datalop.co_so_id : 0}
                                    ref={register({ validate: value => datalop.co_so_id > 0 })} />
                                {
                                    errors.co_so_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn cơ sở
                                     </Label>
                                }


                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Trường học</div>
                                <br></br>
                                <SelectBox dataSource={ datalop.id? dataTruong : selectTruong}
                                    disabled={datalop.id? true : false}
                                    noDataText ="Vui lòng chọn cơ sở trước"
                                    placeholder="Chọn trường học"
                                    displayExpr="ten_truong"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={datalop.dm_truong_id}
                                    onValueChanged={(e) => {handleChangeTruong(e.value);trigger("dm_truong_id")
                                    }}
                                    // onValueChanged={(e) => {
                                    //     setLop(Object.assign({}, datalop, { dm_truong_id: e.value })); trigger("dm_truong_id")
                                    // }}
                                />
                                <input name="dm_truong_id" type="number"
                                    style={{ display: "none" }} defaultValue={datalop.dm_truong_id ? datalop.dm_truong_id : 0}
                                    ref={register({ validate: value => datalop.dm_truong_id > 0 })} />
                                {
                                    errors.dm_truong_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn trường
                          </Label>
                                }
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Khối học</div>
                                <br></br>
                                <SelectBox dataSource={ datalop.id? dataKhoi : selectKhoi}
                                    disabled={datalop.id? true : false}
                                    noDataText ="Vui lòng chọn trường học trước"
                                    placeholder="Chọn khối"
                                    displayExpr="ten_khoi"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={datalop.dm_khoi_id}
                                    onValueChanged={(e) => {handleChangeKhoi(e.value);trigger("dm_khoi_id")
                                    }}
                                    // onValueChanged={(e) => {
                                    //     setLop(Object.assign({}, datalop, { dm_khoi_id: e.value })); trigger("dm_khoi_id")
                                    // }}
                                />
                                <input name="dm_khoi_id" type="number"
                                    style={{ display: "none" }} defaultValue={datalop.dm_khoi_id ? datalop.dm_khoi_id : 0}
                                    ref={register({ validate: value => datalop.dm_khoi_id > 0 })} />
                                {
                                    errors.dm_khoi_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn khối
                          </Label>
                                }
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Hệ học</div>
                                <br></br>
                                <SelectBox dataSource={ datalop.id? dataHe : selectHe}
                                    disabled={datalop.id? true : false}
                                    noDataText ="Vui lòng chọn khối học trước"
                                    placeholder="Chọn hệ học"
                                    displayExpr="ten_he"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={datalop.dm_he_id}
                                    onValueChanged={(e) => {
                                        setLop(Object.assign({}, datalop, { dm_he_id: e.value })); trigger("dm_he_id")
                                    }}
                                />
                                <input name="dm_he_id" type="number"
                                    style={{ display: "none" }} defaultValue={datalop.dm_he_id ? datalop.dm_he_id : 0}
                                    ref={register({ validate: value => datalop.dm_he_id > 0 })} />
                                {
                                    errors.dm_he_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn hệ
                          </Label>
                                }
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Tên lớp</div>
                                <br></br>
                                <TextBox value={datalop.ten_lop}
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        setLop(Object.assign({},
                                            datalop,
                                            { ten_lop: e.value }));
                                        trigger("ten_lop");
                                    }}
                                />
                                <input name="ten_lop"
                                    className="hide"
                                    defaultValue={datalop.ten_lop}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.ten_lop &&
                                    <Label basic color='red' pointing>
                                        Vui lòng điền tên lớp
                          </Label>
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
</button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {id > 0 ? "Cập nhật" : "Thêm mới"}
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
