import React, { useEffect, useState, useContext } from 'react';
import { Label, Modal } from 'semantic-ui-react';
import { SelectBox } from 'devextreme-react/select-box';
import { useForm } from "react-hook-form";
import { hschuyenlop } from "../../api/hocsinh_lop";
import { StoreContext } from "../../contexts/store";
import { select_lop_by_role_user } from "../../api/common_api_combo";
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Paging, Pager, HeaderFilter, Scrolling, Column } from 'devextreme-react/data-grid';
import { select_khoi, select_he, select_coso, select_truong, select_namhoc, select_lop, select_hocsinh_by_ids } from "../../api/common_api_combo";



export default ({ is_show_model, id, onSuccess, onCancel, dataChuyenLop, dataNamHocChuyenLop, totalHocSinh, id_hocsinhs }) => {

    const { set_notification } = useContext(StoreContext)


    const [selectLopMoi, setLopMoi] = useState({});
    const [selectCoSo, setCoSo] = useState({});
    const [selectTruong, setTruong] = useState({});
    const [selectKhoi, setKhoi] = useState();
    const [selectHe, setHe] = useState();
    const [selectNamHoc, setNamHoc] = useState();
    const [selectHocSinh, setHocSinh] = useState();
    const [dataHocSinh, setDataHocSinh] = useState({});
    const [isGridBoxOpened, setGridBoxOpened] = useState(false);
    const [gridBoxValue, setGridBoxValue] = useState();
    const [dataNamHoc, setdataNamHoc] = useState();


    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                set_is_saving(true);
                const res = await hschuyenlop({
                    nam_hoc: dataNamHoc,
                    id_he: dataHocSinh.id_he,
                    id_truong: dataHocSinh.id_truong,
                    id_khoi: dataHocSinh.id_khoi,
                    id_lop_moi: dataHocSinh.id_lop_moi,
                    id_hocsinhs: id_hocsinhs
                });
                set_is_saving(false);
                if (res.is_success) {
                    set_notification({ text: "Chuyển lớp thành công ", type: "success" })
                    onSuccess();
                } else {
                    set_notification({ text: res.message, type: "error" });
                }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_truong: 0 }));
    }, [dataHocSinh.co_so_id]);

    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_khoi: 0 }));
    }, [dataHocSinh.id_truong]);

    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_he: 0, id_lop_moi:0}));
    }, [dataHocSinh.id_khoi]);

    const reload = async () => {

        const res = await select_namhoc();

        if (res.is_success) {
            setNamHoc(res.data);
            const temp = res.data.filter(x => x.is_default);
            if (temp.length > 0) {
                setdataNamHoc(temp[0].nam_hoc);
            }
        }

        const resCoSo = await select_coso();
        if (resCoSo.is_success) {
            setCoSo(resCoSo.data)
        }

        let obj1 = { id_lop: 'THEMHOCSINH' };
        let obj2 = Object.assign({}, obj1);
        const resHS = await select_hocsinh_by_ids(obj2);
        if (resHS.is_success) {
            setHocSinh(resHS.data)
        }

    }


    const reloadTruong = async (id) => {
        const res = await select_truong(id);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeCoSo = async (id) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { co_so_id: id }));
        const truong = await reloadTruong(id);
        setTruong(truong)
    }

    const reloadKhoi = async (id_truong) => {
        const res = await select_khoi(id_truong);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeTruong = async (id_truong) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_truong: id_truong }));
        const khoi = await reloadKhoi(id_truong);
        setKhoi(khoi)
    }
    const reloadHe = async (id_khoi) => {
        const res = await select_he(id_khoi);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeKhoi = async (id_khoi) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_khoi: id_khoi }));
        const khoi = await reloadHe(id_khoi);
        setHe(khoi)
    }

    const reloadLop = async (id) => {

        // setDataHocSinh(Object.assign({}, dataLop, { nam_hoc: dataPhanLop.nam_hoc ,id_he: dataPhanLop.id_he,id_truong: dataPhanLop.id_truong,id_khoi: dataPhanLop.id_khoi }))
        let obj1 = { nam_hoc: dataNamHoc, id_truong: dataHocSinh.id_truong, id_khoi: dataHocSinh.id_khoi, id_he: id };
        let obj2 = Object.assign({}, obj1);
        const res = await select_lop(obj2);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeHe = async (id) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_he: id })); trigger("id_he")
        const lop = await reloadLop(id);
        setLopMoi(lop)
    }
    const handleChangeNamhoc = async (value) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { nam_hoc: value })); trigger("nam_hoc")
        setdataNamHoc(value)

    }
    const syncDataGridSelection = (e) => {
        setGridBoxValue(e.value); trigger("id_hocsinh");
    }

    const onGridBoxOpened = (e) => {
        if (e.name === 'opened') {
            setGridBoxOpened(true)
        }
    }

    const dataGrid_onSelectionChanged = (e) => {
        let val = e.selectedRowKeys[0].id_hocsinh;
        setGridBoxValue(val); trigger("id_hocsinh");
        setGridBoxOpened(false)
        setDataHocSinh(Object.assign({}, dataHocSinh, { id_hocsinh: val }));
    }
    const gridColumns = [
        <Column key={0} dataField="ma_hs" caption="Mã học sinh"></Column>,
        <Column key={1} dataField="ho_ten" caption="Tên học sinh"></Column>,
    ];

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={selectHocSinh}

                hoverStateEnabled={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={(e) => {
                    dataGrid_onSelectionChanged(e);
                }}
            >
                {gridColumns}
                <Selection mode="single" />
                <Scrolling mode="virtual" />
                {/* <Paging enabled={true} pageSize={10} /> */}
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50, 200, 500]}
                    infoText="Trang {0} /{1} ({2} dòng)"
                    showInfo={true} />
                <HeaderFilter visible={true} />
            </DataGrid>
        );
    }

    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    <p>Chuyển lớp</p>
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <p>Số lượng học sinh: {totalHocSinh} </p>
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Năm học</div>
                                <br></br>
                                <SelectBox dataSource={selectNamHoc}
                                    displayExpr="nam_hoc"
                                    valueExpr="nam_hoc"
                                    placeholder="chọn năm học"
                                    value={dataNamHoc}
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        handleChangeNamhoc(e.value)
                                    }}
                                />
                                <input name="nam_hoc"
                                    className="hide"
                                    defaultValue={dataNamHoc}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.nam_hoc &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn năm
                                    </Label>
                                }
                            </div>

                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Cơ sở</div>
                                <br></br>
                                <SelectBox dataSource={selectCoSo}
                                    placeholder="chọn cơ sở"
                                    displayExpr="ten_co_so"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={dataHocSinh.co_so_id}
                                    onValueChanged={(e) => {
                                        handleChangeCoSo(e.value); trigger("co_so_id")
                                    }}
                                />

                                <input name="co_so_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.co_so_id ? dataHocSinh.co_so_id : 0}
                                    ref={register({ validate: value => dataHocSinh.co_so_id > 0 })} />
                                {
                                    errors.co_so_id &&
                                    <Label basic color='red' pointing>
                                        Chọn cơ sở
                                    </Label>
                                }

                            </div>
                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Trường</div>
                                <br></br>
                                <SelectBox dataSource={selectTruong}
                                    noDataText="Vui lòng chọn cơ sở trước"
                                    placeholder="chọn trường"
                                    stylingMode="outlined"
                                    displayExpr="ten_truong"
                                    valueExpr="id"
                                    value={dataHocSinh.id_truong}
                                    onValueChanged={(e) => {
                                        handleChangeTruong(e.value); trigger("id_truong")
                                    }}
                                />
                                <input name="id_truong" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.id_truong ? dataHocSinh.id_truong : 0}
                                    ref={register({ validate: value => dataHocSinh.id_truong > 0 })} />
                                {
                                    errors.id_truong &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn trường
                                    </Label>
                                }
                            </div>
                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Khối</div>
                                <br></br>
                                <SelectBox dataSource={selectKhoi}
                                    noDataText="Vui lòng chọn trường học trước"
                                    placeholder="chọn khối"
                                    stylingMode="outlined"
                                    displayExpr="ten_khoi"
                                    valueExpr="id"
                                    value={dataHocSinh.id_khoi}
                                    onValueChanged={(e) => {
                                        handleChangeKhoi(e.value); trigger("id_khoi")
                                    }}
                                />
                                <input name="id_khoi" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.id_khoi ? dataHocSinh.id_khoi : 0}
                                    ref={register({ validate: value => dataHocSinh.id_khoi > 0 })} />
                                {
                                    errors.id_khoi &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn khối
                                    </Label>
                                }
                            </div>
                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Hệ</div>
                                <br></br>
                                <SelectBox dataSource={selectHe}
                                    noDataText="Vui lòng chọn khối trước"
                                    placeholder="chọn hệ"
                                    displayExpr="ten_he"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={dataHocSinh.id_he}
                                    onValueChanged={(e) => {
                                        handleChangeHe(e.value);
                                    }}
                                />
                                <input name="id_he" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.id_he ? dataHocSinh.id_he : 0}
                                    ref={register({ validate: value => dataHocSinh.id_he > 0 })} />
                                {
                                    errors.id_he &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn hệ
                                    </Label>
                                }
                            </div>

                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Lớp</div>
                                <br></br>
                                <SelectBox dataSource={selectLopMoi}
                                    noDataText="Vui lòng chọn hệ trước"
                                    placeholder="chọn lớp mới"
                                    displayExpr="ten_lop"
                                    valueExpr="id"
                                    stylingMode="outlined"
                                    value={dataHocSinh.id_lop_moi}
                                    onValueChanged={(e) => {
                                        setDataHocSinh(Object.assign({}, dataHocSinh, { id_lop_moi: e.value })); trigger("id_lop_moi")
                                    }}
                                />
                                <input name="id_lop_moi" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.id_lop_moi ? dataHocSinh.id_lop_moi : 0}
                                    ref={register({ validate: value => dataHocSinh.id_lop_moi > 0 })} />
                                {
                                    errors.id_lop_moi &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn lớp
                                    </Label>
                                }
                            </div>

                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>

                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            Lưu lại
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
