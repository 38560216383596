import React, { useEffect, useState ,useContext} from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import {SelectBox} from 'devextreme-react/select-box';
import { useForm } from "react-hook-form";
import { hschuyenlop,themhsvaolop,lenlop} from "../../api/hocsinh_lop";
import DropDownBox from 'devextreme-react/drop-down-box';
import { StoreContext } from "../../contexts/store";
import DataGrid, { Selection, Paging, HeaderFilter, Scrolling,Column } from 'devextreme-react/data-grid';
import { select_khoi,select_he,select_coso,select_truong ,select_next_year,select_lop,select_namhoc} from "../../api/common_api_combo";
import { useAuth } from "../../contexts/auth"

export default ({ is_show_model, id, onSuccess, onCancel, id_hocsinhs }) => {

    const { set_notification } = useContext(StoreContext)
    const [selectLop, setLop] = useState({});
    const [selectCoSo, setCoSo] = useState({});
    const [selectTruong, setTruong] = useState({});
    const [selectKhoi, setKhoi] = useState();
    const [selectHe, setHe] = useState();
    const [dataNamHoc,setNamHoc] = useState();
    const [dataHocSinh,setDataHocSinh] = useState({});
    const [isGridBoxOpened,setGridBoxOpened] = useState(false);
    const [gridBoxValue,setGridBoxValue] =  useState();
    const [set_visible, setVisible] = useState("none");
    const [defaultValue_HS, setDefaultValue_HS] = useState();
    const [defaultValue_Lop_Moi, setDefaultValue_Lop_Moi] = useState();

    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {

                    let obj5 = { nam_hoc: dataNamHoc,id_he: dataHocSinh.dm_he_id,id_truong: dataHocSinh.dm_truong_id,
                        id_khoi: dataHocSinh.dm_khoi_id, list_id_hocsinh: id_hocsinhs.toString()
                };
                debugger
                    let obj6 = Object.assign({}, obj5);
                    set_is_saving(true);
                    const res = await lenlop(obj6);
                    set_is_saving(false);
                    if (res.is_success) {
                        set_notification({ text: "Lên lớp thành công " , type: "success" })
                        onSuccess();
                    } else {
                        set_notification({ text: res.message, type: "error" });
                    }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_truong_id: 0 }));
    }, [dataHocSinh.co_so_id]);

    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_khoi_id: 0 }));
    }, [dataHocSinh.dm_truong_id]);

    useEffect(() => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_he_id: 0, id_lop: 0 }));
    }, [dataHocSinh.dm_khoi_id]);

    const reload = async () => {
        const res = await select_next_year();
        if(res.is_success){
            setNamHoc(res.data.nam_hoc);
        }
        const resCoSo = await select_coso();
        if(resCoSo.is_success){
            setCoSo(resCoSo.data)
        }


    }



    const reloadTruong = async (id) => {
        const res = await select_truong(id);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeCoSo = async (id) => { 
        setDataHocSinh(Object.assign({}, dataHocSinh, { co_so_id: id }));
        const truong =  await reloadTruong(id);
        setTruong(truong)
    }

    const reloadKhoi = async (id_truong) => {
        const res = await select_khoi(id_truong);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeTruong = async (id_truong) => { 
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_truong_id: id_truong }));
        const khoi =  await reloadKhoi(id_truong);
        setKhoi(khoi)
    }
    const reloadHe = async (id_khoi) => {
        const res = await select_he(id_khoi);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeKhoi= async (id_khoi) => { 
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_khoi_id: id_khoi }));
        const khoi =  await reloadHe(id_khoi);
        setHe(khoi)
    }

    const reloadLop = async (id) => {
        let CurrentYear = new Date().getFullYear()
        let CurrentYearNext  = CurrentYear + 1;
        let get_nam_hoc = `${CurrentYear}-${CurrentYearNext}`;
        let obj1 = { nam_hoc: get_nam_hoc,id_truong: dataHocSinh.dm_truong_id,id_khoi: dataHocSinh.dm_khoi_id,id_he: id};
        let obj2 = Object.assign({}, obj1);
        const res = await select_lop(obj2);
        if (res.is_success) {
          return res.data;
        } else {
          return [];
        }
      }
    const handleChangeHe = async (id) => {
        setDataHocSinh(Object.assign({}, dataHocSinh, { dm_he_id: id })); trigger("dm_he_id")
        const lop =  await reloadLop(id);
        setLop(lop)
    }




    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                  <p>Lên lớp</p>
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <div className="row">
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Năm học tiếp theo</div>
                                <br></br>
                                <TextBox value={dataNamHoc}
                                  disabled= {true}
                                />
                            </div>
                            
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label">Cơ sở</div>
                                <br></br>
                                <SelectBox dataSource={selectCoSo}
                                    placeholder="chọn cơ sở"
                                    stylingMode="outlined"
                                    displayExpr="ten_co_so"
                                    valueExpr="id"
                                    value={dataHocSinh.co_so_id}
                                    onValueChanged={(e) => {handleChangeCoSo(e.value); trigger("co_so_id")
                                    }}
                                />
                            </div>
                            <input name="co_so_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.co_so_id ? dataHocSinh.co_so_id : 0}
                                    ref={register({ validate: value => dataHocSinh.co_so_id > 0 })} />
                                {
                                    errors.co_so_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn cơ sở
                                     </Label>
                                }


                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Trường</div>
                                <br></br>
                                <SelectBox dataSource={selectTruong}
                                    noDataText ="Vui lòng chọn cơ sở trước"
                                    placeholder="chọn trường"
                                    stylingMode="outlined"
                                    displayExpr="ten_truong"
                                    valueExpr="id"
                                    value={dataHocSinh.dm_truong_id}
                                    onValueChanged={(e) => {handleChangeTruong(e.value);trigger("dm_truong_id")
                                    }}
                                />
                                <input name="dm_truong_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.dm_truong_id ? dataHocSinh.dm_truong_id : 0}
                                    ref={register({ validate: value => dataHocSinh.dm_truong_id > 0 })} />
                                {
                                    errors.dm_truong_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn trường
                                    </Label>
                                }
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Khối</div>
                                <br></br>
                                <SelectBox dataSource={selectKhoi}
                                    noDataText ="Vui lòng chọn trường học trước"
                                    placeholder="chọn khối"
                                    stylingMode="outlined"
                                    displayExpr="ten_khoi"
                                    valueExpr="id"
                                    value={dataHocSinh.dm_khoi_id}
                                    onValueChanged={(e) => {handleChangeKhoi(e.value);trigger("dm_khoi_id")
                                    }}
                                />
                                <input name="dm_khoi_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.dm_khoi_id ? dataHocSinh.dm_khoi_id : 0}
                                    ref={register({ validate: value => dataHocSinh.dm_khoi_id > 0 })} />
                                {
                                    errors.dm_khoi_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn khối
                                    </Label>
                                }
                            </div>

                            <div className="col-md-12" style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Hệ</div>
                                <br></br>
                                <SelectBox dataSource={selectHe}
                                    noDataText ="Vui lòng chọn khối học trước"
                                    placeholder="chọn hệ"
                                    stylingMode="outlined"
                                    displayExpr="ten_he"
                                    valueExpr="id"
                                    value={dataHocSinh.dm_he_id}
                                    onValueChanged={(e) => {handleChangeHe(e.value);
                                    }}
                                />
                                <input name="dm_he_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.dm_he_id ? dataHocSinh.dm_he_id : 0}
                                    ref={register({ validate: value => dataHocSinh.dm_he_id > 0 })} />
                                {
                                    errors.dm_he_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn hệ
                                    </Label>
                                }
                            </div>
                            {/*<div className="col-md-12" style={{ paddingTop: 25 }}>*/}
                            {/*    <div className="dx-field-label">Lớp</div>*/}
                            {/*    <br></br>*/}
                            {/*    <SelectBox dataSource={selectLop}*/}
                            {/*        noDataText ="Vui lòng chọn hệ học trước"*/}
                            {/*        placeholder="chọn lớp"*/}
                            {/*        stylingMode="outlined"*/}
                            {/*        displayExpr="ten_lop"*/}
                            {/*        valueExpr="id"*/}
                            {/*        value={dataHocSinh.id_lop}*/}
                            {/*        onValueChanged={(e) => {*/}
                            {/*            setDataHocSinh(Object.assign({}, dataHocSinh, { id_lop: e.value })); trigger("id_lop")*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    <input name="id_lop" type="number"*/}
                            {/*        style={{ display: "none" }} defaultValue={dataHocSinh.id_lop ? dataHocSinh.id_lop : 0}*/}
                            {/*        ref={register({ validate: value => dataHocSinh.id_lop > 0 })} />*/}
                            {/*    {*/}
                            {/*        errors.id_lop &&*/}
                            {/*        <Label basic color='red' pointing>*/}
                            {/*            Vui lòng chọn lớp*/}
                            {/*        </Label>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                           Lưu lại
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
