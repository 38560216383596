import { withNavigationWatcher } from './contexts/navigation';

import {
  HomePage,
  LoaiGiayToPage,
  CoSoPage,
  ConCBNV,
  TruongPage,
  NguonBietToiPage,
  PnLPage,
  Lop,
  PhanLop,
  HocSinhTheoLop,
  HocSinhThoiHoc,
} from './pages';
import ThongTinHocSinh from './pages/thong_tin_hoc_sinh/thong-tin-hoc-sinh';
import ChamSocKhachHang from './pages/chamsockhachhang/chamsockhachhang';
import Campaigns from './pages/campaign/Campaigns';
import DmChienDich from './pages/dm_chiendich/DmChienDich';
const routes = [
  {
    path: '/truong',
    component: TruongPage,
  },
  {
    path: '/pnl',
    component: PnLPage,
  },
  {
    path: '/nguon_biet_toi',
    component: NguonBietToiPage,
  },
  {
    path: '/loai_giay_to',
    component: LoaiGiayToPage,
  },
  {
    path: '/con_cbnv',
    component: ConCBNV,
  },
  {
    path: '/co_so',
    component: CoSoPage,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/lop',
    component: Lop,
  },
  {
    path: '/phanlop',
    component: PhanLop,
  },
  {
    path: '/ds_hoc_sinh_lop',
    component: HocSinhTheoLop,
  },
  {
    path: '/ds_thoihoc',
    component: HocSinhThoiHoc,
  },
  {
    path: '/danh_sach_hoc_sinh',
    component: ThongTinHocSinh,
  },
  {
    path: '/cham_soc_khach_hang',
    component: ChamSocKhachHang,
  },
  {
    path: '/campaigns',
    component: Campaigns,
  },
  {
    path: '/dm_chiendich',
    component: DmChienDich,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
