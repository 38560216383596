import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function select_all(nam_hoc, dm_trangthaihocsinh_ids, searchText) {
  return call_post_api(appInfo.api_url + '/hocsinh/select_all', {
    nam_hoc,
    dm_trangthaihocsinh_ids,
    searchText,
  });
}

export async function select_not_in_ace_chi_tiet(data) {
  return call_post_api(
    appInfo.api_url + '/hocsinh/select_not_in_ace_chi_tiet',
    data
  );
}

export async function select_by_id(id) {
  return call_post_api(appInfo.api_url + '/hocsinh/select_by_id', id);
}

export async function hoc_sinh_select_by_id(id) {
  return call_post_api(appInfo.api_url + '/hocsinh/select_by_id', id);
}

export async function hoc_sinh_select_ace_by_id(data) {
  return call_post_api(appInfo.api_url + '/hocsinh/select_ace_by_id', data);
}
export async function insert(data) {
  return call_post_api(appInfo.api_url + '/hocsinh/insert', data);
}

export async function update(data) {
  return call_post_api(appInfo.api_url + '/hocsinh/update', data);
}

export async function remove(data) {
  return call_post_api(appInfo.api_url + '/hocsinh/delete', data);
}
export async function approve(data) {
  return call_post_api(appInfo.api_url + '/hocsinh/approve', data);
}
