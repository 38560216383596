import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useState } from 'react';
import { Confirm, Modal } from 'semantic-ui-react';
import {
  import_data,
  read_import_file,
} from '../../../api/ts_chiendich_phuhuynh_api';
import {
  DefaultButton,
  PrimaryButton,
} from '../../../components/editor/button';
import ComboboxChienDich from '../../../components/editor/cmb_chiendich';
import ComboboxChienDichTrangThai from '../../../components/editor/cmb_chiendich_trangthai';
import UploadExcel from '../../../components/file-upload/UploadExcel';
import { StoreContext } from '../../../contexts/store';
import { action_type, ini_state, reducer, status_enum } from './reducer';
import appInfo from '../../../app-info';
import ComboboxTuVanVien from '../../../components/editor/cmb_tu_van_vien';
const ImportModal = ({ onCancel, onSuccess, namhoc }) => {
  const [state, dispatch] = useReducer(reducer, ini_state);
  const { set_notification } = useContext(StoreContext);

  const [dm_chiendich_id, setDm_chiendich_id] = useState(1);
  const [dm_chiendich_trangthai_id, setDm_chiendich_trangthai_id] = useState(1);
  const [user_id_tuvan, setUser_id_tuvan] = useState();

  const [showWarningDataInValid, setShowWarningDataInValid] = useState(false);
  const isDataValid =
    Boolean(dm_chiendich_id) &&
    Boolean(dm_chiendich_trangthai_id) &&
    Boolean(user_id_tuvan);

  const grid = useRef(null);
  const { status, file_name, data_source } = state;
  useEffect(() => {
    if (file_name) {
      read_data();
    }
  }, [file_name]);
  useEffect(() => {
    if (status === status_enum.is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [status]);
  const errr_code = useMemo(() => {
    const count = data_source.filter((x) => !x.is_valid).length;
    if (count > 0) {
      return `Có ${count} dòng không hợp lệ, vui lòng kiểm tra lại dữ liệu`;
    }
    return '';
  }, [data_source]);
  const read_data = async () => {
    if (!user_id_tuvan) {
      setShowWarningDataInValid(true);
      return;
    }
    dispatch({ type: action_type.BEGIN_SELECT_DATA });
    const res = await read_import_file({
      file_name: file_name,
      sheet_index: 0,
      user_id_tuvan,
    });
    if (res.is_success) {
      dispatch({ type: action_type.END_SELECT_DATA, payload: res.data });
    } else {
      set_notification({ type: 'error', text: res.message });
    }
  };
  const handleSaveChanges = async () => {
    dispatch({ type: action_type.BEGIN_SAVING });
    const res = await import_data({
      dm_chiendich_id,
      dm_chiendich_trangthai_id,
      user_id_tuvan,
      model: data_source,
    });
    if (res.is_success) {
      dispatch({ type: action_type.END_SAVING });
      set_notification({ type: 'success', text: 'Import thành công' });
      onSuccess();
    } else {
      set_notification({ type: 'error', text: res.message });
    }
  };
  const renderTuVanVien = ({ data }) => {
    const { username_tuvan, full_name_tuvan } = data;
    return `${full_name_tuvan} (${username_tuvan})`;
  };
  return (
    <React.Fragment>
      <Confirm
        open={showWarningDataInValid}
        cancelButton={false}
        confirmButton={'Quay lại'}
        onConfirm={() => setShowWarningDataInValid(false)}
        size="mini"
        content={'Nhân viên tư vấn không được để trống.'}
      />
      <Modal
        open={true}
        size="large"
        dimmer={'blurring'}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Import dữ liệu từ excel ({namhoc})</Modal.Header>
        <Modal.Content>
          <div className="row">
            <div className="col-md-6">
              <UploadExcel
                onSuccess={(filename) => {
                  dispatch({
                    type: action_type.CHANGE_FILENAME,
                    payload: filename,
                  });
                }}
                onError={(message) => {
                  set_notification({ type: 'error', text: message });
                }}
              />
              {errr_code && (
                <p className="text-danger padding-top-1rem">{errr_code}</p>
              )}
            </div>
            <div className="col-md-6 text-align-right">
              <a
                href="https://docs.google.com/spreadsheets/d/1JGrF2dYO7qfvN_lss1mc92bE9hlFHH1j/edit?usp=sharing&ouid=107742175437065738637&rtpof=true&sd=true"
                target="_blank"
              >
                Tải file mẫu
              </a>
            </div>
            <div className="row padding-left-1rem">
              <div className="col-md-4 padding-top-1rem">
                <div className="dx-field-label">Trạng thái</div>
                <br></br>
                <ComboboxChienDichTrangThai
                  value={dm_chiendich_trangthai_id}
                  onValueChanged={(value) => {
                    setDm_chiendich_trangthai_id(value);
                  }}
                />
              </div>
              <div className="col-md-4 padding-top-1rem">
                <div className="dx-field-label">Chiến dịch</div>
                <br></br>
                <ComboboxChienDich
                  value={dm_chiendich_id}
                  onValueChanged={({ value }) => {
                    setDm_chiendich_id(value);
                  }}
                  namhoc={namhoc}
                />
              </div>
              <div className="col-md-4 padding-top-1rem">
                <div className="dx-field-label">Người tư vấn</div>
                <br></br>
                <ComboboxTuVanVien
                  value={user_id_tuvan}
                  onValueChanged={(e) => {
                    setUser_id_tuvan(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 padding-top-1rem">
              <DataGrid
                ref={grid}
                keyExpr="id"
                dataSource={data_source}
                columnHidingEnabled={false}
                columnAutoWidth={true}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={
                  errr_code
                    ? window.innerHeight - 300
                    : window.innerHeight - 300
                }
                noDataText="Không có dữ liệu"
                wordWrapEnabled={true}
              >
                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                <Column dataField="id" caption="TT" />
                <Column dataField="is_valid" caption="Hợp lệ" />
                <Column dataField="error" caption="Mã lỗi" />
                <Column dataField="ten_ph" caption="Tên PH" />
                <Column dataField="dien_thoai_ph" caption="Điện thoại PH" />
                <Column dataField="dien_thoai_khac" caption="Điện thoại khác" />
                <Column cellRender={renderTuVanVien} caption="Tư vấn viên" />

                <Column dataField="email_ph" caption="Email PH" />
                <Column dataField="dm_khoi_id" caption="Khối" />
                <Column dataField="ten_hoc_sinh" caption="Tên HS" />
                <Column dataField="ghi_chu" caption="Ghi chú" />
              </DataGrid>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <DefaultButton onClick={onCancel} text="Đóng" />
            {file_name && !errr_code && (
              <PrimaryButton
                onClick={handleSaveChanges}
                text="Import"
                disabled={status === status_enum.is_saving || !isDataValid}
                isLoading={status === status_enum.is_saving}
                icon="fas fa-check"
              />
            )}
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default ImportModal;
