import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { select_all } from '../../api/dm_truong_api';
import { useAuth } from '../../contexts/auth';
export default ({ value, onValueChanged, cmb, showClearButton,is_readonly }) => {
    const [dm_truongs, set_dm_truongs] = useState([]);
    const { user } = useAuth();
    useEffect(() => {
        (async () => {
            const res = await select_all();
            if (res.is_success) {
                set_dm_truongs(res.data);
            } else {
                set_dm_truongs([]);
            }
        })();

    }, []);
    return (
        <SelectBox dataSource={dm_truongs}
            displayExpr={"ten_truong"}
            valueExpr={"id"}
            value={value}
            stylingMode="outlined"
            ref={cmb}
            readOnly={is_readonly === true ? is_readonly : false}
            placeholder="Chọn trường"
            width="100%"
            showClearButton={showClearButton}
            onValueChanged={(e) => {

                onValueChanged(e.value);
            }} />
    );
};





