import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function dm_chiendich_select_all() {
  return call_post_api(appInfo.api_url + '/dm_chiendich/select_all');
}
export async function dm_chiendich_select_by_nam_hoc({ namhoc }) {
  return call_post_api(
    appInfo.api_url + '/dm_chiendich/select_by_nam_hoc?namhoc=' + namhoc
  );
}
export async function chiendich_select_by_id(id) {
  return call_post_api(appInfo.api_url + '/dm_chiendich/select_by_id', id);
}
export async function chiendich_insert(data) {
  return call_post_api(appInfo.api_url + '/dm_chiendich/insert', data);
}

export async function chiendich_update(data) {
  return call_post_api(appInfo.api_url + '/dm_chiendich/update', data);
}

export async function chiendich_remove(id) {
  return call_post_api(appInfo.api_url + '/dm_chiendich/delete', id);
}
