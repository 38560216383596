import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { select_all, select_by_truong } from '../../api/dm_khoi_api';
export default ({
  value,
  onValueChanged,
  cmb,
  dm_truong_id,
  showClearButton,
  is_readonly,
}) => {
  const [dm_khois, set_dm_khois] = useState([]);
  useEffect(() => {
    (async () => {
      let res = null;
      if (dm_truong_id > 0) {
        res = await select_by_truong(dm_truong_id);
      } else {
        res = await select_all();
      }
      if (res.is_success) {
        set_dm_khois(res.data);
      } else {
        set_dm_khois([]);
      }
    })();
  }, [dm_truong_id]);
  return (
    <SelectBox
      dataSource={dm_khois}
      displayExpr={'ten_khoi'}
      valueExpr={'id'}
      value={value}
      readOnly={is_readonly === true ? is_readonly : false}
      stylingMode="outlined"
      ref={cmb}
      placeholder="Chọn khối"
      width="100%"
      showClearButton={showClearButton}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};
