import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { Transition, Icon, Confirm, Modal } from "semantic-ui-react";
import { Column } from "devextreme-react/data-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { StoreContext } from "../../contexts/store";
import { Input } from "antd";
import {
  chamsockhachhang_select_all,
  chamsockhachhang_remove,
  chamsockhachhang_change_status,
} from "../../api/chamsockhachhang_api";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
import DetailModal from "./chamsockhachhang_detail_modal";
const ini_state = {
  list_cskh: [],
  selected_cskh: {},
};

const action_type = {
  GET_LIST_CSKH: "GET_LIST_CSKH",
  SET_SELECTED_CSKH: "SET_SELECTED_CSKH",
};
const { Search } = Input;
export default () => {
  const [is_loading, set_is_loading] = useState(false);
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const [is_show_confirm_change_status, set_is_show_confirm_change_status] = useState(false);
  const [list_cskh_selected, set_list_cskh_selected] = useState([]);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const grid = useRef(null);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case action_type.GET_LIST_CSKH:
        return {
          ...state,
          list_cskh: action.payload,
        };
      case action_type.SET_SELECTED_CSKH:
        return {
          ...state,
          selected_cskh: action.payload,
        };
      default:
        return state;
    }
  }, ini_state);
  useEffect(() => {
    reload();
  }, []);
  useEffect(() => {
    if (is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [is_loading]);
  const reload = async () => {
    set_is_loading(true);
    const res = await chamsockhachhang_select_all("");
    set_is_loading(false);
    if (res.is_success) {
      dispatch({ type: action_type.GET_LIST_CSKH, payload: res.data });
    }
  };

  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_CSKH, payload: data });
    set_is_show_modal(true);
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_CSKH, payload: data });
    set_is_show_confirm_delete(true);
  };

  const confirmDelete = async () => {
    const res = await chamsockhachhang_remove(state.selected_cskh.id);
    if (res.is_success) {
      reload();
      set_is_show_confirm_delete(false);
    } else {
      set_notification({ text: res.message, type: "error" });
    }
  };

  const onSearchCSKH = async (value) => {
    set_is_loading(true);
    const res = await chamsockhachhang_select_all(value);
    set_is_loading(false);
    if (res.is_success) {
      dispatch({ type: action_type.GET_LIST_CSKH, payload: res.data });
    }
  };

  const handleSubmitChangeStatus = async () => {
    const res = await chamsockhachhang_change_status(list_cskh_selected);
    if (res.is_success) {
      set_is_show_confirm_change_status(false);
      set_list_cskh_selected([]);
      reload();
      set_notification({ text: "Thay đổi trạng thái thành công", type: "success" });
    }
    else {
      set_notification({ text: res.message, type: "error" });
    }
  }

  const cellRender = (data) => {
    return (
      <div>
        {can_accessed("api/chamsockhachhang/update") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{" "}
          </span>
        )}
        {can_accessed("api/chamsockhachhang/delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{" "}
          </span>
        )}
      </div>
    );
  };

  const column = [
    <Column
      key={0}
      cellRender={cellRender}
      caption="Thao tác"
      width={80}
    ></Column>,
    <Column
      key={1}
      dataField="dien_thoai"
      caption="Điện thoại"
      width={120}
    ></Column>,
    <Column
      key={2}
      dataField="ten_khach_hang"
      caption="Tên KH"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="noi_dung"
      caption="Nội dung"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="created_time"
      caption="Ngày tiếp nhận"
      dataType="date"
      format="dd/MM/yyyy"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="ten_nguoi_tiep_nhan"
      caption="Người tiếp nhận"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="da_xu_ly"
      caption="Đã xử lý"
      width={150}
    ></Column>,
    <Column key={3} dataField="ghi_chu" caption="Ghi chú" width={200}></Column>,

    <Column key={3} dataField="ma_tn" caption="Mã TN" width={150}></Column>,
    <Column key={3} dataField="ma_hs" caption="Mã HS" width={150}></Column>,
    <Column
      key={3}
      dataField="ten_hoc_sinh"
      caption="Tên HS"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="ten_nguoi_xu_ly"
      caption="Người xử lý"
      width={150}
    ></Column>,
    <Column
      key={3}
      dataField="ngay_xu_ly"
      caption="Ngày xử lý"
      dataType="date"
      format="dd/MM/yyyy"
      width={150}
    ></Column>,
  ];
  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  render={() => {
                    return <h3>Chăm sóc khách hàng</h3>;
                  }}
                />
                <Item
                  location="after"
                  render={() => {
                    return (
                      <Search
                        placeholder="Nhập SĐT, tên KH, học sinh,..."
                        allowClear
                        onSearch={onSearchCSKH}
                        style={{ width: 350 }}
                        size="middle"
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn p-btn-mob p-lime p-white-color p-white-color p-btn-sm"
                        onClick={() => {
                          set_is_show_confirm_change_status(true);
                        }}
                      >
                        <Icon name="check"></Icon>
                        Xử lý{" "}
                        {list_cskh_selected.length > 0
                          ? "(" + list_cskh_selected.length + ")"
                          : ""}
                      </a>
                    );
                  }}
                />
                {can_accessed("api/chamsockhachhang/insert") && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <a
                          className="p-btn p-prim-col  p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal(true);
                            dispatch({
                              type: action_type.SET_SELECTED_CSKH,
                              payload: { id: 0 },
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          Thêm mới
                        </a>
                      );
                    }}
                  />
                )}
                <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn  p-btn-sm"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      >
                        <Icon name="file excel outline"></Icon>
                        Xuất excel
                      </a>
                    );
                  }}
                />
              </Toolbar>
            </div>
            <div className="col-md-12">
              <DataGrid
                ref={grid}
                keyExpr={"id"}
                dataSource={state.list_cskh}
                columnHidingEnabled={false}
                //defaultColumns={columns}
                columnAutoWidth={false}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={"nextColumn"}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={window.innerHeight - 130}
                className={"table-noborder-left"}
                wordWrapEnabled={true}
                selectedRowKeys={list_cskh_selected}
                onSelectionChanged={({ selectedRowKeys, selectedRowsData }) => {
                  set_list_cskh_selected(selectedRowKeys);
                }}
              >
                <Sorting mode="multiple" />
                <Paging defaultPageSize={10} />
                <Selection mode="multiple" showCheckBoxesMode="always" />
                
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                {/* <Scrolling mode="infinite" /> */}
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                {column}
              </DataGrid>
            </div>
            <DetailModal
              is_show_model={is_show_modal}
              onCancel={() => {
                set_is_show_modal(false);
              }}
              onSuccess={() => {
                set_is_show_modal(false);
                reload();
              }}
              id={state.selected_cskh.id}
            ></DetailModal>
          </div>
        </div>
      </Transition>
      <Confirm
        open={is_show_confirm_delete}
        // dimmer={"blurring"}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn xóa thông tin này ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_delete(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                confirmDelete();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Xóa
            </button>
          );
        }}
      />

<Confirm
        open={is_show_confirm_change_status}
        // dimmer={"blurring"}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn thay đổi trạng thái xử lý ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_change_status(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                handleSubmitChangeStatus();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Lưu
            </button>
          );
        }}
      />

    </React.Fragment>
  );
};
