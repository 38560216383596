import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function select_all() {
    return call_post_api(appInfo.api_url + '/loai_giay_to/select_all');
};
export async function select_by_id(id) {
    return call_post_api(appInfo.api_url + '/loai_giay_to/select_by_id',id);
};
export async function insert(data) {
    return call_post_api(appInfo.api_url + '/loai_giay_to/insert',data);
}

export async function update(data) {
    return call_post_api(appInfo.api_url + '/loai_giay_to/update',data);
}

export async function remove(id) {
    return call_post_api(appInfo.api_url + '/loai_giay_to/delete',id);
}
