import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../contexts/store";
import { Label, Modal, Loader } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { Card } from "antd";
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/hocsinh_api";
import { dm_quanhe_select_all } from "../../api/dm_quanhe_api";
import { dm_gioitinh_select_all } from "../../api/dm_gioitinh_api";
import { dm_namhoc_select_all } from "../../api/dm_namhoc_api";
import { dm_coso_select_all } from "../../api/co_so_api";
import { dm_truong_select_by_coso } from "../../api/api_truong.js";
import { dm_khoi_select_by_truong } from "../../api/dm_khoi_api";
import { dm_he_select_by_khoi } from "../../api/dm_he_api";
import { dm_quoctich_select_all } from "../../api/dm_quoctich_api";
import { dm_dantoc_select_all } from "../../api/dm_dantoc_api";
import { dm_tongiao_select_all } from "../../api/dm_tongiao_api";
import { dm_nguonbiettoi_select_all } from "../../api/dm_nguonbiettoi_api";
import {
  dm_tinh_select_all,
  dm_huyen_select_by_tinh,
  dm_xa_select_by_huyen,
} from "../../api/dm_diachi_api";
import { Switch } from "devextreme-react/switch";
export default ({ id, onSuccess, onCancel }) => {
  const [hocsinh, set_hocsinh] = useState({});
  const [is_saving, set_is_saving] = useState(false);
  const [list_dm_quanhe, set_list_dm_quanhe] = useState();
  const [list_dm_gioitinh, set_list_dm_gioitinh] = useState();
  const [list_dm_he, set_list_dm_he] = useState();
  const [list_dm_coso, set_list_dm_coso] = useState();
  const [list_dm_truong_by_coso, set_list_dm_truong_by_coso] = useState();
  const [list_dm_khoi_by_truong, set_dm_khoi_by_truong] = useState();
  const [list_dm_he_by_khoi, set_dm_he_by_khoi] = useState();
  const [list_dm_tinh, set_list_dm_tinh] = useState();
  const [list_dm_xa, set_list_dm_xa] = useState();
  const [list_dm_huyen, set_list_dm_huyen] = useState();
  const [list_dm_quoctich, set_list_dm_quoctich] = useState();
  const [list_dm_dantoc, set_list_dm_dantoc] = useState();
  const [list_dm_tongiao, set_list_dm_tongiao] = useState();
  const [list_dm_nguonbiettoi, set_list_dm_nguonbiettoi] = useState();
  const [list_dm_namhoc, set_list_dm_namhoc] = useState();
  const [default_nam_hoc, set_default_nam_hoc] = useState();
  const { register, trigger, errors, clearErrors } = useForm();
  const { set_notification, can_accessed } = useContext(StoreContext);
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (hocsinh.id > 0) {
          set_is_saving(true);
          const res = await update(hocsinh);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: "Cập nhật thông tin thành công.",
              type: "success",
            });
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await insert(
            hocsinh?.nam_hoc
              ? hocsinh
              : Object.assign({}, hocsinh, { nam_hoc: default_nam_hoc })
          );
          set_is_saving(false);
          if (res.is_success) {
            onSuccess(res.data);
            set_notification({
              text: "Thêm mới thông tin thành công.",
              type: "success",
            });
          } else {
            console.log(res.message);
          }
        }
      }
    });
  };

  useEffect(() => {
    get_list_dm_quanhe();
    get_list_dm_gioitinh();
    get_list_dm_namhoc();
    get_list_dm_coso();
    get_list_dm_tinh();
    get_list_dm_quoctich();
    get_list_dm_dantoc();
    get_list_dm_tongiao();
    get_list_dm_nguonbiettoi();
    clearErrors();
  }, []);

  useEffect(() => {
    reload();
  }, [id]);
  

  const reload = async () => {
    if (id > 0) {
      const res = await select_by_id(id);
      if (res.is_success) {
        
        set_hocsinh(res.data);
        get_list_dm_truong_by_coso(res.data.dm_coso_id);
        get_list_dm_khoi_by_truong(res.data.dm_truong_id);
        get_list_dm_he_by_khoi(res.data.dm_he_id);
        get_list_dm_huyen_by_tinh(res.data.dm_tinh_id);
        get_list_dm_xa_by_huyen(res.data.dm_huyen_id);
      }
    } else {
      set_hocsinh({});
    }
  };

  const get_list_dm_quanhe = async () => {
    const res = await dm_quanhe_select_all();
    if (res.is_success) {
      set_list_dm_quanhe(res.data);
    }
  };

  const get_list_dm_nguonbiettoi = async () => {
    const res = await dm_nguonbiettoi_select_all();
    if (res.is_success) {
      set_list_dm_nguonbiettoi(res.data);
    }
  };

  const get_list_dm_quoctich = async () => {
    const res = await dm_quoctich_select_all();
    if (res.is_success) {
      set_list_dm_quoctich(res.data);
    }
  };
  const get_list_dm_tongiao = async () => {
    const res = await dm_tongiao_select_all();
    if (res.is_success) {
      set_list_dm_tongiao(res.data);
    }
  };

  const get_list_dm_dantoc = async () => {
    const res = await dm_dantoc_select_all();
    if (res.is_success) {
      set_list_dm_dantoc(res.data);
    }
  };

  const get_list_dm_gioitinh = async () => {
    const res = await dm_gioitinh_select_all();
    if (res.is_success) {
      set_list_dm_gioitinh(res.data);
    }
  };
  const get_list_dm_namhoc = async () => {
    const res = await dm_namhoc_select_all();
    if (res.is_success) {
      set_list_dm_namhoc(res.data);

      if (id > 0) {
        set_default_nam_hoc(hocsinh.nam_hoc);
      } else {
        set_default_nam_hoc(
          res.data?.find((x) => x.is_default == true).nam_hoc
        );
      }
    }
  };

  const get_list_dm_coso = async () => {
    const res = await dm_coso_select_all();
    if (res.is_success) {
      set_list_dm_coso(res.data);
    }
  };

  const get_list_dm_truong_by_coso = async (id) => {
    const res = await dm_truong_select_by_coso(id);
    if (res.is_success) {
      set_list_dm_truong_by_coso(res.data);
    }
  };

  const get_list_dm_khoi_by_truong = async (id) => {
    const res = await dm_khoi_select_by_truong(id);
    if (res.is_success) {
      set_dm_khoi_by_truong(res.data);
    }
  };

  const get_list_dm_he_by_khoi = async (id) => {
    const res = await dm_he_select_by_khoi(id);
    if (res.is_success) {
      set_dm_he_by_khoi(res.data);
    }
  };

  const get_list_dm_tinh = async () => {
    const res = await dm_tinh_select_all();
    if (res.is_success) {
      set_list_dm_tinh(res.data);
    }
  };

  const get_list_dm_huyen_by_tinh = async (id) => {
    const res = await dm_huyen_select_by_tinh(id);
    if (res.is_success) {
      set_list_dm_huyen(res.data);
    }
  };

  const get_list_dm_xa_by_huyen = async (id) => {
    const res = await dm_xa_select_by_huyen(id);
    if (res.is_success) {
      set_list_dm_xa(res.data);
    }
  };

  return (
    <>
      <form className="ui">
        <input className="hide" name="id" defaultValue={hocsinh.id}></input>
        <Card title="THÔNG TIN ĐĂNG KÝ" size="small">
          <div className="row">
            <div className="col-md-3">
              <div className="dx-field-label">Năm học</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                // defaultValue={default_nam_hoc}
                value={hocsinh.nam_hoc ? hocsinh.nam_hoc : default_nam_hoc}
                dataSource={list_dm_namhoc}
                displayExpr="nam_hoc"
                valueExpr="nam_hoc"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  console.log("eeeeeeeeeeeeee", e);
                  set_hocsinh(Object.assign({}, hocsinh, { nam_hoc: e.value }));
                  trigger("nam_hoc");
                }}
              />
              <input
                name="nam_hoc"
                className="hide"
                defaultValue={hocsinh.nam_hoc}
                // ref={register({ required: true })}
              ></input>
              {errors.nam_hoc && (
                <Label basic color="red" pointing>
                  Vui lòng chọn năm học
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Họ tên</div>
              <br></br>
              <TextBox
                value={hocsinh.ho_ten}
                stylingMode="outlined"
                placeholder="Nhập họ tên học sinh"
                onValueChanged={(e) => {
                  set_hocsinh(Object.assign({}, hocsinh, { ho_ten: e.value }));
                  trigger("ho_ten");
                }}
              />
              <input
                name="ho_ten"
                className="hide"
                defaultValue={hocsinh.ho_ten}
                value={hocsinh.ho_ten}
                ref={register({ required: true })}
              ></input>
              {errors.ho_ten && (
                <Label basic color="red" pointing>
                  Vui lòng điền họ tên
                </Label>
              )}
            </div>
          </div>
          <div className="row padding-top-1rem">
            {/* <div className="col-md-3">
              <div className="dx-field-label">Mã hồ sơ</div>
              <br></br>
              <TextBox
                value={hocsinh.ma_tn}
                stylingMode="outlined"
                onValueChanged={(e) => {
                  set_hocsinh(Object.assign({}, hocsinh, { ma_tn: e.value }));
                  trigger("ma_tn");
                }}
                disabled={true}
              />
              <input
                name="ma_tn"
                className="hide"
                defaultValue={hocsinh.ma_tn}
                value={hocsinh.ma_tn}
              ></input>
            </div> */}
            <div className="col-md-3">
              <div className="dx-field-label">*Ngày sinh</div>
              <br></br>
              <DateBox
                type="date"
                displayFormat={"dd/MM/yyyy"}
                showClearButton={true}
                useMaskBehavior={true}
                placeholder="Nhập hoặc chọn ngày sinh"
                value={hocsinh.ngay_sinh}
                stylingMode="outlined"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { ngay_sinh: e.value })
                  );
                  trigger("ngay_sinh");
                }}
              />
              <input
                name="ngay_sinh"
                className="hide"
                defaultValue={hocsinh.ngay_sinh}
                ref={register({ required: true })}
              ></input>
              {errors.ngay_sinh && (
                <Label basic color="red" pointing>
                  Vui lòng chọn ngày sinh
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Giới tính</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder="Chọn giới tính"
                defaultValue={hocsinh?.dm_gioitinh_id}
                value={hocsinh.dm_gioitinh_id}
                dataSource={list_dm_gioitinh}
                displayExpr="gioi_tinh"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dm_gioitinh_id: e.value })
                  );
                  trigger("dm_gioitinh_id");
                }}
              />
              <input
                name="dm_gioitinh_id"
                className="hide"
                defaultValue={hocsinh.dm_gioitinh_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_gioitinh_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn giới tính
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Nơi sinh</div>
              <br></br>
              <TextBox
                value={hocsinh.dia_chi_noi_sinh}
                stylingMode="outlined"
                placeholder="Nhập nơi sinh"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dia_chi_noi_sinh: e.value })
                  );
                  trigger("dia_chi_noi_sinh");
                }}
              />
              <input
                name="dia_chi_noi_sinh"
                className="hide"
                defaultValue={hocsinh.dia_chi_noi_sinh}
                value={hocsinh.dia_chi_noi_sinh}
                ref={register({ required: true })}
              ></input>
              {errors.dia_chi_noi_sinh && (
                <Label basic color="red" pointing>
                  Vui lòng điền nơi sinh
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">Trường cũ</div>
              <br></br>
              <TextBox
                value={hocsinh.truong_hoc_cu}
                placeholder="Nhập trường cũ"
                stylingMode="outlined"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { truong_hoc_cu: e.value })
                  );
                  trigger("truong_hoc_cu");
                }}
              />
              <input
                name="truong_hoc_cu"
                className="hide"
                defaultValue={hocsinh.truong_hoc_cu}
                value={hocsinh.truong_hoc_cu}
                // ref={register({ required: true })}
              ></input>
            </div>
          </div>

          {/* row 2 */}
          <div className="row padding-top-1rem">
            <div className="col-md-3">
              <div className="dx-field-label">*Cơ sở</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn cơ sở"}
                defaultValue={hocsinh?.dm_coso_id}
                value={hocsinh?.dm_coso_id}
                dataSource={list_dm_coso}
                displayExpr="ten_co_so"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_coso_id: e.value,
                      dm_truong_id: null,
                      dm_khoi_id: null,
                      dm_he_id: null,
                    })
                  );
                  trigger("dm_coso_id");
                  get_list_dm_truong_by_coso(e.value);
                }}
              />
              <input
                name="dm_coso_id"
                className="hide"
                defaultValue={hocsinh.dm_coso_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_coso_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn cơ sở
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Trường</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Hãy chọn cơ sở trước"}
                defaultValue={hocsinh?.dm_truong_id}
                value={hocsinh.dm_truong_id}
                dataSource={list_dm_truong_by_coso}
                displayExpr="ten_truong"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_truong_id: e.value,
                      dm_khoi_id: null,
                      dm_he_id: null,
                    })
                  );
                  trigger("dm_truong_id");
                  get_list_dm_khoi_by_truong(e.value);
                }}
              />
              <input
                name="dm_truong_id"
                className="hide"
                defaultValue={hocsinh.dm_truong_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_truong_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn trường
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Khối đăng ký</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Hãy chọn trường trước"}
                defaultValue={hocsinh?.dm_khoi_id}
                value={hocsinh.dm_khoi_id}
                dataSource={list_dm_khoi_by_truong}
                displayExpr="ten_khoi"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_khoi_id: e.value,
                      dm_he_id: null,
                    })
                  );
                  trigger("dm_khoi_id");
                  get_list_dm_he_by_khoi(e.value);
                }}
              />
              <input
                name="dm_khoi_id"
                className="hide"
                defaultValue={hocsinh.dm_khoi_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_khoi_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn khối
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Hệ đăng ký</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Hãy chọn khối trước"}
                defaultValue={hocsinh?.dm_he_id}
                value={hocsinh.dm_he_id}
                dataSource={list_dm_he_by_khoi}
                displayExpr="ten_he"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dm_he_id: e.value })
                  );
                  trigger("dm_he_id");
                }}
              />
              <input
                name="dm_he_id"
                className="hide"
                defaultValue={hocsinh.dm_he_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_he_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn hệ
                </Label>
              )}
            </div>
          </div>
        </Card>
        <Card title="ĐỊA CHỈ ĐANG Ở" size="small" style={{ marginTop: 0 }}>
          <div className="row">
            <div className="col-md-3">
              <div className="dx-field-label">*Thành phố/Tỉnh</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn tỉnh thành"}
                defaultValue={hocsinh?.dm_tinh_id}
                value={hocsinh.dm_tinh_id}
                dataSource={list_dm_tinh}
                displayExpr="ten_tinh"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                searchEnabled={true}
                searchExpr="ten_tinh"
                searchMode="contains"
                searchTimeout={500}
                showClearButton={true}
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_tinh_id: e.value,
                      dm_huyen_id: null,
                      dm_xa_id: null,
                    })
                  );
                  trigger("dm_tinh_id");
                  get_list_dm_huyen_by_tinh(e.value);
                }}
              />
              <input
                name="dm_tinh_id"
                className="hide"
                defaultValue={hocsinh.dm_tinh_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_tinh_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn tỉnh/thành phố
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Quận/Huyện</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn tỉnh thành trước"}
                defaultValue={hocsinh?.dm_huyen_id}
                value={hocsinh.dm_huyen_id}
                dataSource={list_dm_huyen}
                displayExpr="ten_huyen"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                searchEnabled={true}
                searchExpr="ten_huyen"
                searchMode="contains"
                searchTimeout={500}
                showClearButton={true}
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_huyen_id: e.value,
                      dm_xa_id: null,
                    })
                  );
                  trigger("dm_huyen_id");
                  get_list_dm_xa_by_huyen(e.value);
                }}
              />
              <input
                name="dm_huyen_id"
                className="hide"
                defaultValue={hocsinh.dm_huyen_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_huyen_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn quận/huyện
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Phường/Xã</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn quận/huyện trước"}
                defaultValue={hocsinh?.dm_xa_id}
                value={hocsinh.dm_xa_id}
                dataSource={list_dm_xa}
                displayExpr="ten_xa"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                searchEnabled={true}
                searchExpr="ten_xa"
                searchMode="contains"
                searchTimeout={500}
                showClearButton={true}
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_xa_id: e.value,
                    })
                  );
                  trigger("dm_xa_id");
                }}
              />
              <input
                name="dm_xa_id"
                className="hide"
                defaultValue={hocsinh.dm_xa_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_xa_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn xã
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Số nhà/ngõ(Thôn/xóm)</div>
              <br></br>
              <TextBox
                value={hocsinh.dia_chi_tt}
                stylingMode="outlined"
                placeholder="Nhập số nhà/thôn xóm"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dia_chi_tt: e.value })
                  );
                  trigger("dia_chi_tt");
                }}
              />
              <input
                name="dia_chi_tt"
                className="hide"
                defaultValue={hocsinh.dia_chi_tt}
                value={hocsinh.dia_chi_tt}
                ref={register({ required: true })}
              ></input>
              {errors.dia_chi_tt && (
                <Label basic color="red" pointing>
                  Vui lòng điền địa chỉ
                </Label>
              )}
            </div>
          </div>
          {/* row 2 card 2 */}
          <div className="row padding-top-1rem">
            <div className="col-md-3">
              <div className="dx-field-label">*Quốc tịch</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn quốc tịch"}
                defaultValue={hocsinh?.dm_quoctich_id}
                value={hocsinh.dm_quoctich_id}
                dataSource={list_dm_quoctich}
                displayExpr="quoc_tich"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                searchEnabled={true}
                searchExpr="quoc_tich"
                searchMode="contains"
                searchTimeout={500}
                showClearButton={true}
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_quoctich_id: e.value,
                    })
                  );
                  trigger("dm_quoctich_id");
                }}
              />
              <input
                name="dm_quoctich_id"
                className="hide"
                defaultValue={hocsinh.dm_quoctich_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_quoctich_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn quốc tịch
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Tôn giáo</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder={"Chọn tôn giáo"}
                defaultValue={hocsinh?.dm_tongiao_id}
                value={hocsinh.dm_tongiao_id}
                dataSource={list_dm_tongiao}
                displayExpr="ton_giao"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                searchEnabled={true}
                searchExpr="ton_giao"
                searchMode="contains"
                searchTimeout={500}
                showClearButton={true}
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, {
                      dm_tongiao_id: e.value,
                    })
                  );
                  trigger("dm_tongiao_id");
                }}
              />
              <input
                name="dm_tongiao_id"
                className="hide"
                defaultValue={hocsinh.dm_tongiao_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_tongiao_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn tôn giáo
                </Label>
              )}
            </div>
          </div>
        </Card>
        <Card
          title="TÌNH TRẠNG SỨC KHỎE"
          size="small"
          style={{ marginTop: 0 }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="dx-field-label">Tình trạng sức khỏe hiện tại</div>
              <br></br>
              <TextBox
                value={hocsinh.tt_suc_khoe}
                placeholder="Nhập tình trạng sức khỏe"
                stylingMode="outlined"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { tt_suc_khoe: e.value })
                  );
                  trigger("tt_suc_khoe");
                }}
              />
              <input
                name="tt_suc_khoe"
                className="hide"
                defaultValue={hocsinh.tt_benh_tat}
                value={hocsinh.tt_benh_tat}
                // ref={register({ required: true })}
              ></input>
            </div>
            <div className="col-md-6">
              <div className="dx-field-label">
                Tiểu sử bệnh tật (Nêu chi tiết nếu có)
              </div>
              <br></br>
              <TextBox
                value={hocsinh.tt_benh_tat}
                placeholder="Nhập tình trạng bệnh tật"
                stylingMode="outlined"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { tt_benh_tat: e.value })
                  );
                  trigger("tt_benh_tat");
                }}
              />
              <input
                name="tt_benh_tat"
                className="hide"
                defaultValue={hocsinh.tt_benh_tat}
                value={hocsinh.tt_benh_tat}
                // ref={register({ required: true })}
              ></input>
            </div>
          </div>
        </Card>
        <Card
          title="THÔNG TIN NGƯỜI LIÊN HỆ"
          size="small"
          style={{ marginTop: 0 }}
        >
          <div className="row">
            <div className="col-md-3">
              <div className="dx-field-label">*Họ tên người liên hệ</div>
              <br></br>
              <TextBox
                value={hocsinh.ho_ten_lien_he}
                stylingMode="outlined"
                placeholder="Nhập họ tên người liên hệ"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { ho_ten_lien_he: e.value })
                  );
                  trigger("ho_ten_lien_he");
                }}
              />
              <input
                name="ho_ten_lien_he"
                className="hide"
                defaultValue={hocsinh.ho_ten_lien_he}
                value={hocsinh.ho_ten_lien_he}
                ref={register({ required: true })}
              ></input>
              {errors.ho_ten_lien_he && (
                <Label basic color="red" pointing>
                  Vui lòng điền họ tên người liên hệ
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Điện thoại</div>
              <br></br>
              <TextBox
                value={hocsinh.dien_thoai_lien_he}
                stylingMode="outlined"
                placeholder="Nhập SĐT người liên hệ"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dien_thoai_lien_he: e.value })
                  );
                  trigger("dien_thoai_lien_he");
                }}
              />
              <input
                name="dien_thoai_lien_he"
                className="hide"
                defaultValue={hocsinh.dien_thoai_lien_he}
                value={hocsinh.dien_thoai_lien_he}
                ref={register({ required: true, pattern: /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/})}
                ></input>
              {errors.dien_thoai_lien_he && (
                <Label basic color="red" pointing>
                  SĐT không hợp lệ
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Quan hệ với học sinh</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder="Chọn quan hệ"
                defaultValue={hocsinh?.dm_quanhe_id}
                value={hocsinh.dm_quanhe_id}
                dataSource={list_dm_quanhe}
                displayExpr="quan_he"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dm_quanhe_id: e.value })
                  );
                  trigger("dm_quanhe_id");
                }}
              />
              <input
                name="dm_quanhe_id"
                className="hide"
                defaultValue={hocsinh.dm_quanhe_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_quanhe_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn quan hệ
                </Label>
              )}
            </div>
          </div>
          <div className="row padding-top-1rem">
            <div className="col-md-3">
              <div className="dx-field-label">*Email</div>
              <br></br>
              <TextBox
                value={hocsinh.email_lien_he}
                stylingMode="outlined"
                placeholder="Nhập email người liên hệ"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { email_lien_he: e.value })
                  );
                  trigger("email_lien_he");
                }}
              />
              <input
                name="email_lien_he"
                className="hide"
                defaultValue={hocsinh.email_lien_he}
                value={hocsinh.email_lien_he}
                ref={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })}
                ></input>
              {errors.email_lien_he && (
                <Label basic color="red" pointing>
                  Email không hợp lệ
                </Label>
              )}
            </div>
            <div className="col-md-3">
              <div className="dx-field-label">*Nguồn biết tới</div>
              <br></br>
              <SelectBox
                stylingMode="outlined"
                placeholder="Chọn nguồn biết tới"
                defaultValue={hocsinh?.dm_nguonbiettoi_id}
                value={hocsinh.dm_nguonbiettoi_id}
                dataSource={list_dm_nguonbiettoi}
                displayExpr="nguon_biet_toi"
                valueExpr="id"
                noDataText="Không có dữ liệu"
                onValueChanged={(e) => {
                  set_hocsinh(
                    Object.assign({}, hocsinh, { dm_nguonbiettoi_id: e.value })
                  );
                  trigger("dm_nguonbiettoi_id");
                }}
              />
              <input
                name="dm_nguonbiettoi_id"
                className="hide"
                defaultValue={hocsinh.dm_nguonbiettoi_id}
                ref={register({ required: true })}
              ></input>
              {errors.dm_nguonbiettoi_id && (
                <Label basic color="red" pointing>
                  Vui lòng chọn nguồn biết tới
                </Label>
              )}
            </div>
          </div>
        </Card>
      </form>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 20,
          marginRight: 20,
        }}
      >
        <button onClick={() => onCancel()} className="p-btn p-btn-sm">
          Đóng
        </button>
        <button
          disabled={is_saving}
          onClick={handleSaveChanges}
          className="p-btn p-prim-col p-btn-sm margin-left-10"
        >
          {id > 0 ? "Cập nhật" : "Thêm mới"}
          {is_saving && <Loader active inline size="mini" />}
        </button>
      </div>
    </>
  );
};
