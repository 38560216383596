import appInfo from "../app-info";
import { call_post_api } from "./api_helper";
export async function dm_tinh_select_all() {
  return call_post_api(appInfo.api_url + "/tinh/select_all");
}
export async function dm_huyen_select_by_tinh(id) {
  return call_post_api(appInfo.api_url + "/huyen/select_by_tinh", id);
}
export async function dm_xa_select_by_huyen(id) {
  return call_post_api(appInfo.api_url + "/xa/select_by_huyen", id);
}
