import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export async function themhsvaolop(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_lop/themhsvaolop',data);
}

export async function lenlop(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_lop/lenlop',data);
}

export async function xoahskhoilop(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_lop/xoahskhoilop',data);
}

export async function hschuyenlop(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_lop/hschuyenlop',data);
}