import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { Transition, Icon, Confirm } from 'semantic-ui-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid from '../../components/datagrid/datagrid';
// import DetailModal from './detail_modal';
import { Column } from 'devextreme-react/data-grid';
import { StoreContext } from '../../contexts/store';
import {
  chiendich_remove,
  dm_chiendich_select_all,
} from '../../api/dm_chiendich_api';
import DetailModal from './DetailModal';
const ini_state = {
  chiendichs: [],
  selected_chiendich: {},
};
const action_type = {
  SET_CHIEN_DICHS: 'SET_CHIEN_DICHS',
  SET_SELECTED_CHIEN_DICH: 'SET_SELECTED_CHIEN_DICH',
};
const DmChienDich = () => {
  const [is_loading, set_is_loading] = useState(false);
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const grid = useRef(null);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case action_type.SET_CHIEN_DICHS:
        return {
          ...state,
          chiendichs: action.payload,
        };
      case action_type.SET_SELECTED_CHIEN_DICH:
        return {
          ...state,
          selected_chiendich: action.payload,
        };
      default:
        return state;
    }
  }, ini_state);
  useEffect(() => {
    reload();
  }, []);
  useEffect(() => {
    if (is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [is_loading]);
  const reload = async () => {
    set_is_loading(true);
    const res = await dm_chiendich_select_all();
    set_is_loading(false);
    if (res.is_success) {
      dispatch({ type: action_type.SET_CHIEN_DICHS, payload: res.data });
    }
  };
  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_CHIEN_DICH, payload: data });
    set_is_show_modal(true);
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_CHIEN_DICH, payload: data });
    set_is_show_confirm_delete(true);
  };
  const confirmDelete = async () => {
    const res = await chiendich_remove(state.selected_chiendich.id);
    if (res.is_success) {
      reload();
      set_is_show_confirm_delete(false);
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };
  const cellRender = (data) => {
    return (
      <div>
        {can_accessed('api/dm_chiendich/update') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{' '}
          </span>
        )}
        {can_accessed('api/dm_chiendich/delete') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{' '}
          </span>
        )}
      </div>
    );
  };
  const column = [
    <Column
      key={0}
      cellRender={cellRender}
      caption="Thao tác"
      width={100}
    ></Column>,
    <Column key={1} dataField="nam_hoc" caption="Năm học"></Column>,
    <Column key={2} dataField="ten_chien_dich" caption="Tên chiến dịch" />,
    <Column key={3} dataField="ngay_chien_dich" caption="Ngày chiến dịch" />,
    <Column key={4} dataField="dia_diem" caption="Địa điểm" />,
  ];
  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  render={() => {
                    return <h3>Danh mục chiến dịch</h3>;
                  }}
                />
                {can_accessed('api/dm_chiendich/insert') && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <a
                          className="p-btn p-prim-col  p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal(true);
                            dispatch({
                              type: action_type.SET_SELECTED_CHIEN_DICH,
                              payload: { id: 0 },
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          Thêm chiến dịch
                        </a>
                      );
                    }}
                  />
                )}
                <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn  p-btn-sm"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      >
                        <Icon name="file excel outline"></Icon>
                        Xuất excel
                      </a>
                    );
                  }}
                />
              </Toolbar>
            </div>
            <div className="col-md-12">
              <DataGrid
                key="id"
                dataSource={state.chiendichs}
                height={window.innerHeight - 130}
                columns={column}
                grid={grid}
              ></DataGrid>
            </div>
          </div>
        </div>
      </Transition>
      <Confirm
        open={is_show_confirm_delete}
        dimmer={'blurring'}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn xóa dữ liệu ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_delete(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                confirmDelete();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Xóa chiến dịch
            </button>
          );
        }}
      />
      <DetailModal
        is_show_model={is_show_modal}
        onCancel={() => {
          set_is_show_modal(false);
        }}
        onSuccess={() => {
          set_is_show_modal(false);
          reload();
        }}
        id={state.selected_chiendich.id}
      />
    </React.Fragment>
  );
};

export default DmChienDich;
