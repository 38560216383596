import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';

import _ from 'lodash';
import { Transition, Icon, Confirm, Modal } from 'semantic-ui-react';

import { Column } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
} from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react';

import { StoreContext } from '../../contexts/store';
import { select_all, remove, approve } from '../../api/hocsinh_api';
import { dm_namhoc_select_all } from '../../api/dm_namhoc_api';
import { select_all as dm_trangthaihocsinh_select_all } from '../../api/dm_trangthaihocsinh_api';
import DetailModal from './list_tab_modal';
import ComboboxTrangThaiHocSinhMultiple from '../../components/editor/cmb_trangthai_hocsinh_multiple';

const ini_state = {
  list_hocsinh: [],
  selected_hocsinh: {},
};

const action_type = {
  GET_LIST_HOCSINH: 'GET_LIST_HOCSINH',
  SET_SELECTED_HOCSINH: 'SET_SELECTED_HOCSINH',
};
const ThongTinHocSinh = () => {
  const [is_loading, set_is_loading] = useState(false);
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_modal_trangthai, set_is_show_modal_trangthai] =
    useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);

  const [list_dm_namhoc, set_list_dm_namhoc] = useState();
  const [dm_trangthaihocsinhs, set_dm_trangthaihocsinhs] = useState([]);
  const [dm_trangthaihocsinh_id, set_dm_trangthaihocsinh_id] = useState(0);
  const [ts_hocsinh_ids, set_ts_hocsinh_ids] = useState([]);
  const [default_nam_hoc, set_default_nam_hoc] = useState();
  const [nam_hoc_selected, set_nam_hoc_selected] = useState();

  const [dm_trangthaihocsinh_ids, set_dm_trangthaihocsinh_ids] = useState([]);
  const [searchText, setSearchText] = useState();

  const [data_source, set_data_source] = useState([]);

  const { set_notification, can_accessed } = useContext(StoreContext);
  const grid = useRef(null);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case action_type.GET_LIST_HOCSINH:
        return {
          ...state,
          list_hocsinh: action.payload,
        };
      case action_type.SET_SELECTED_HOCSINH:
        return {
          ...state,
          selected_hocsinh: action.payload,
        };
      default:
        return state;
    }
  }, ini_state);


  useEffect(() => {
    if (searchText) {
      const source = state.list_hocsinh.filter(x => x.ma_tn.includes(searchText)
        || x.ma_hs.includes(searchText)
        || x.ho_ten.includes(searchText)
        || x.dien_thoai_lien_he.includes(searchText)
        || x.ho_ten_lien_he.includes(searchText))
      set_data_source(source)
    } else {
      set_data_source(state.list_hocsinh);
    }
  }, [state.list_hocsinh, searchText])

  useEffect(() => {
    get_list_dm_namhoc();
    (async () => {
      const res = await dm_trangthaihocsinh_select_all();
      if (res.is_success) {
        set_dm_trangthaihocsinhs(
          _.filter(
            res.data,
            ({ trang_thai }) =>
              !['Thôi học', 'Bảo lưu', 'Chuyển trường'].includes(trang_thai)
          )
        );
      } else {
        set_dm_trangthaihocsinhs([]);
      }
    })();
  }, []);
  useEffect(() => {
    reload();
  }, [nam_hoc_selected, default_nam_hoc, dm_trangthaihocsinh_ids]);
  useEffect(() => {
    if (is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [is_loading]);
  const reload = async () => {
    if (nam_hoc_selected && dm_trangthaihocsinh_ids && dm_trangthaihocsinh_ids.length > 0) {
      set_is_loading(true);
      const res = await select_all(
        nam_hoc_selected ? nam_hoc_selected : default_nam_hoc,
        dm_trangthaihocsinh_ids,
        ''
      );
      set_is_loading(false);
      if (res.is_success) {
        dispatch({ type: action_type.GET_LIST_HOCSINH, payload: res.data });
      }
    } else {
      dispatch({ type: action_type.GET_LIST_HOCSINH, payload: [] });
    }

  };

  const get_list_dm_namhoc = async () => {
    const res = await dm_namhoc_select_all();
    if (res.is_success) {
      set_list_dm_namhoc(res.data);
      set_default_nam_hoc(res.data?.find((x) => x.is_default == true).nam_hoc);
      set_nam_hoc_selected(res.data?.find((x) => x.is_default == true).nam_hoc);
    }
  };
  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_HOCSINH, payload: data });
    set_is_show_modal(true);
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_HOCSINH, payload: data });
    set_is_show_confirm_delete(true);
  };

  const confirmDelete = async () => {
    const res = await remove(state.selected_hocsinh.id);
    if (res.is_success) {
      reload();
      set_is_show_confirm_delete(false);
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };

  const cellRender = (data) => {
    return (
      <div>
        {can_accessed('api/hocsinh/update') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{' '}
          </span>
        )}
        {can_accessed('api/hocsinh/delete') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{' '}
          </span>
        )}
      </div>
    );
  };
  const handleSaveChangeTrangThais = async () => {
    const res = await approve({
      dm_trangthaihocsinh_id: dm_trangthaihocsinh_id,
      ts_hocsinh_ids: ts_hocsinh_ids,
    });
    if (res.is_success) {
      set_is_show_modal_trangthai(false);
      reload();
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    set_ts_hocsinh_ids(selectedRowKeys);
  };
  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <Toolbar className="bg-background">
                {/* <Item
                  location="before"
                  render={() => {
                    return <h3>Danh sách học sinh</h3>;
                  }}
                /> */}

                <Item
                  location="after"
                  render={() => {
                    return (
                      <SelectBox
                        stylingMode="outlined"
                        defaultValue={default_nam_hoc}
                        value={
                          nam_hoc_selected ? nam_hoc_selected : default_nam_hoc
                        }
                        dataSource={list_dm_namhoc}
                        displayExpr="nam_hoc"
                        valueExpr="nam_hoc"
                        noDataText="Không có dữ liệu"
                        placeholder="Chọn năm học"
                        onValueChanged={(e) => {
                          set_nam_hoc_selected(e.value);
                        }}
                      />
                    );
                  }}
                />
                <Item
                  location="after"
                  render={() => (
                    <ComboboxTrangThaiHocSinhMultiple
                      value={dm_trangthaihocsinh_ids}
                      onValueChanged={set_dm_trangthaihocsinh_ids}
                    />
                  )}
                />
                <Item
                  location="after"
                  render={() => {
                    return (
                      <TextBox
                        placeholder="Tìm kiếm"
                        defaultValue={searchText}
                        stylingMode='outlined'
                        onValueChanged={(e) => setSearchText(e.value)}
                        width={200}
                      />
                    );
                  }}
                />
                {can_accessed('api/hocsinh/insert') && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <a
                          className="p-btn p-prim-col  p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal(true);
                            dispatch({
                              type: action_type.SET_SELECTED_HOCSINH,
                              payload: { id: 0 },
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          Thêm mới
                        </a>
                      );
                    }}
                  />
                )}
                {can_accessed('api/hocsinh/approve') && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <button
                          disabled={ts_hocsinh_ids.length <= 0}
                          className="p-btn p-btn-mob p-lime p-white-color p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal_trangthai(true);
                          }}
                        >
                          Đổi trạng thái, tạo mã học sinh{' '}
                          {ts_hocsinh_ids.length > 0
                            ? '(' + ts_hocsinh_ids.length + ')'
                            : ''}
                        </button>
                      );
                    }}
                  />
                )}
                <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn  p-btn-sm"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      >
                        <Icon name="file excel outline"></Icon>
                        Xuất excel
                      </a>
                    );
                  }}
                />
              </Toolbar>
            </div>

            <div className="col-md-12">
              <DataGrid
                ref={grid}
                keyExpr={'id'}
                dataSource={data_source}
                columnHidingEnabled={false}
                //defaultColumns={columns}
                columnAutoWidth={false}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={window.innerHeight - 130}
                className={'table-noborder-left'}
                wordWrapEnabled={false}
                selectedRowKeys={ts_hocsinh_ids}
                onSelectionChanged={onSelectionChanged}
              >
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                {/* <Selection mode="single"></Selection> */}
                <Selection mode="multiple" showCheckBoxesMode="always" />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                {/* <Scrolling mode="infinite" /> */}
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                <Column
                  caption="TT"
                  width={50}
                  alignment={"center"}
                  cellRender={(cell) => {
                    return <span>{cell.rowIndex + 1}</span>
                  }}
                ></Column>
                <Column
                  dataField="trang_thai"
                  caption="Trạng thái"
                  width={120}
                ></Column>
                <Column
                  cellRender={cellRender}
                  caption="Thao tác"
                  width={80}
                ></Column>
                <Column dataField="ma_tn" caption="Mã TN" width={100}></Column>
                <Column
                  dataField="ma_hs"
                  caption="Mã học sinh"
                  width={120}
                ></Column>
                <Column
                  dataField="ho_ten"
                  caption="Họ tên"
                  width={150}
                ></Column>
                <Column
                  dataField="ngay_sinh"
                  caption="Ngày sinh"
                  dataType="date"
                  format="dd/MM/yyyy"
                  width={100}
                ></Column>
                <Column
                  dataField="gioi_tinh"
                  caption="Giới tính"
                  width={100}
                ></Column>

                <Column
                  dataField="ten_truong"
                  caption="Trường"
                  width={200}
                ></Column>
                <Column
                  dataField="ten_khoi"
                  caption="Khối"
                  width={100}
                ></Column>
                <Column dataField="ten_he" caption="Hệ" width={180}></Column>
                {/* <Column key={8} dataField="nam_hoc" caption="Năm học" width={150}></Column> */}
                <Column
                  dataField="ten_tinh"
                  caption="Tỉnh/Thành phố"
                  width={150}
                ></Column>
                <Column
                  dataField="ten_huyen"
                  caption="Quận/Huyện"
                  width={150}
                ></Column>
                <Column
                  dataField="ten_xa"
                  caption="Phường/Xã"
                  width={150}
                ></Column>
                <Column
                  dataField="dia_chi_tt"
                  caption="Số nhà(Thôn/xóm)"
                  width={150}
                ></Column>
                <Column
                  dataField="dia_chi_noi_sinh"
                  caption="Nơi sinh"
                  width={150}
                ></Column>
                <Column
                  dataField="truong_hoc_cu"
                  caption="Trường cũ"
                  width={200}
                ></Column>
                <Column
                  dataField="quoc_tich"
                  caption="Quốc tịch"
                  width={100}
                ></Column>
                <Column
                  dataField="ton_giao"
                  caption="Tôn giáo"
                  width={120}
                ></Column>
                <Column
                  dataField="tt_suc_khoe"
                  caption="Tình trạng sức khỏe"
                  width={200}
                ></Column>
                <Column
                  dataField="tt_benh_tat"
                  caption="Tiểu sử bệnh tật"
                  width={200}
                ></Column>
                <Column
                  dataField="ho_ten_lien_he"
                  caption="Họ tên người liên hệ"
                  width={150}
                ></Column>
                <Column
                  dataField="dien_thoai_lien_he"
                  caption="Điện thoại"
                  width={150}
                ></Column>
                <Column
                  dataField="quan_he"
                  caption="Quan hệ"
                  width={150}
                ></Column>
                <Column
                  dataField="email_lien_he"
                  caption="Email"
                  width={300}
                ></Column>
                <Column
                  dataField="nguon_biet_toi"
                  caption="Nguồn biết tới"
                  width={200}
                ></Column>
                <Column
                  dataField="ngay_dang_ky"
                  caption="Ngày đămg ký"
                  dataType="date"
                  displayFormat={'dd/MM/yyyy'}
                  width={200}
                ></Column>
              </DataGrid>
            </div>
            {is_show_modal && (
              <DetailModal
                is_show_model={is_show_modal}
                onCancel={() => {
                  set_is_show_modal(false);
                }}
                onSuccess={() => {
                  // set_is_show_modal(false);
                  reload();
                }}
                id={state.selected_hocsinh.id}
              ></DetailModal>
            )}
          </div>
        </div>
      </Transition>
      <Confirm
        open={is_show_confirm_delete}
        // dimmer={"blurring"}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn xóa thông tin học sinh ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_delete(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                confirmDelete();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Xóa
            </button>
          );
        }}
      />
      <Modal
        open={is_show_modal_trangthai}
        size="mini"
        dimmer={'blurring'}
        onClose={() => {
          set_is_show_modal_trangthai(false);
        }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <div className="label">Đổi trạng thái</div>
        </Modal.Header>
        <Modal.Content>
          <p>Chọn trạng thái</p>
          <p>Khi đổi sang trạng thái <b>"Thi dự tuyển"</b>, hệ thống sẽ tạo mã tự động cho học sinh</p>
          <SelectBox
            stylingMode="outlined"
            dataSource={dm_trangthaihocsinhs}
            displayExpr="trang_thai"
            valueExpr="id"
            noDataText="Không có dữ liệu"
            placeholder="Chọn trạng thái"
            onValueChanged={(e) => {
              set_dm_trangthaihocsinh_id(e.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <div className="text-align-center">
            <button
              onClick={() => {
                set_is_show_modal_trangthai(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
            <button
              onClick={handleSaveChangeTrangThais}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              Cập nhật
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default ThongTinHocSinh;
