import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { TextBox } from 'devextreme-react';
import TextArea from 'devextreme-react/text-area';

import { Label, Modal, Loader } from 'semantic-ui-react';
import {
  campaign_insert,
  campaign_select_by_id,
  campaign_update,
  check_existed_by_phone,
} from '../../api/ts_chiendich_phuhuynh_api';
import { select_by_id } from '../../api/hocsinh_api';
import ComboboxChienDich from '../../components/editor/cmb_chiendich';
import CbxKhoi from '../../components/editor/cmb_khoi';
import { StoreContext } from '../../contexts/store';
import ComboboxChienDichTrangThai from '../../components/editor/cmb_chiendich_trangthai';
import SearchStudent from '../hocsinh_thoihoc/hoc_sinh_search';
import { useQuery } from 'react-query';

const DetailModal = ({ is_show_model, id, onSuccess, onCancel, namhoc }) => {
  const [is_saving, set_is_saving] = useState(false);
  const { set_notification } = useContext(StoreContext);
  const [campaign, set_campaign] = useState({});
  const { register, trigger, errors } = useForm();

  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    if (id > 0) {
      const res = await campaign_select_by_id(id);
      if (res.is_success) {
        set_campaign(res.data);
      }
    } else {
      set_campaign({ dm_chiendich_trangthai_id: 1 });
    }
  };
  const {
    dm_chiendich_id,
    ten_ph,
    dien_thoai_ph,
    email_ph,
    dm_khoi_id,
    ghi_chu,
    dien_thoai_khac,
    ten_hs,
    dm_chiendich_trangthai_id,
    ts_hocsinh_id,
  } = campaign;
  const { data: defaultStudentData } = useQuery(
    ['get-hoc-sinh', ts_hocsinh_id],
    () => select_by_id(ts_hocsinh_id),
    {
      enabled: Boolean(ts_hocsinh_id),
    }
  );
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (id > 0) {
          set_is_saving(true);
          const res = await campaign_update(campaign);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: 'Cập nhật thông tin thành công',
              type: 'success',
            });
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await campaign_insert(Object.assign(campaign));
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: 'Thêm mới thông tin thành công',
              type: 'success',
            });
          } else {
            set_notification({ text: res.message, type: 'error' });
          }
        }
      }
    });
  };
  const onCancelModal = () => {
    onCancel();
    set_campaign({});
  };

  const checkPhoneIsUsed = async (phoneNumber) => {
    const res = await check_existed_by_phone(phoneNumber);
    const { is_success, data } = res || {};
    return is_success && !data;
  };
  return (
    <div>
      <Modal
        open={is_show_model}
        size="small"
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={id}></input>
            <div className="row">
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Trạng thái</div>
                <br></br>
                <ComboboxChienDichTrangThai
                  value={dm_chiendich_trangthai_id}
                  onValueChanged={(value) => {
                    set_campaign(
                      Object.assign({}, campaign, {
                        dm_chiendich_trangthai_id: value,
                      })
                    );
                  }}
                />
                <input
                  name="dm_chiendich_trangthai_id"
                  className="hide"
                  defaultValue={dm_chiendich_trangthai_id}
                  value={dm_chiendich_trangthai_id}
                  ref={register({
                    required: true,
                  })}
                ></input>
              </div>

              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Chiến dịch</div>
                <br></br>
                <ComboboxChienDich
                  value={dm_chiendich_id}
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { dm_chiendich_id: e.value })
                    );
                  }}
                  namhoc={namhoc}
                />
                <input
                  name="dm_chiendich_id"
                  className="hide"
                  defaultValue={dm_chiendich_id}
                  value={dm_chiendich_id}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.dm_chiendich_id && (
                  <Label basic color="red" pointing>
                    Chiến dịch không được để trống
                  </Label>
                )}
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Tên phụ huynh</div>
                <br></br>
                <TextBox
                  value={ten_ph}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { ten_ph: e.value })
                    );
                  }}
                />
                <input
                  name="ten_ph"
                  className="hide"
                  defaultValue={ten_ph}
                  value={ten_ph}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.ten_ph && (
                  <Label basic color="red" pointing>
                    Tên phụ huynh không được để trống
                  </Label>
                )}
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">SĐT phụ huynh</div>
                <br></br>
                <TextBox
                  value={dien_thoai_ph}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { dien_thoai_ph: e.value })
                    );
                  }}
                />
                <input
                  name="dien_thoai_ph"
                  className="hide"
                  defaultValue={dien_thoai_ph}
                  value={dien_thoai_ph}
                  ref={register({
                    required: true,
                    pattern:
                      /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
                    validate: checkPhoneIsUsed,
                  })}
                ></input>
                {(errors.dien_thoai_ph?.type === 'required' ||
                  errors.dien_thoai_ph?.type === 'pattern') && (
                  <Label basic color="red" pointing>
                    Điện thoại phụ huynh không hợp lệ
                  </Label>
                )}
                {errors.dien_thoai_ph?.type === 'validate' && (
                  <Label basic color="red" pointing>
                    Điện thoại phụ huynh đã tồn tại
                  </Label>
                )}
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">SĐT khác</div>
                <br></br>
                <TextBox
                  value={dien_thoai_khac}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { dien_thoai_khac: e.value })
                    );
                  }}
                />
                <input
                  name="dien_thoai_khac"
                  className="hide"
                  defaultValue={dien_thoai_khac}
                  value={dien_thoai_khac}
                  ref={register({
                    pattern:
                      /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
                  })}
                ></input>
                {errors.dien_thoai_khac && (
                  <Label basic color="red" pointing>
                    Điện thoại khác không hợp lệ
                  </Label>
                )}
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Email phụ huynh</div>
                <br></br>
                <TextBox
                  value={email_ph}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { email_ph: e.value })
                    );
                  }}
                />
                <input
                  name="email_ph"
                  className="hide"
                  defaultValue={email_ph}
                  value={email_ph}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.email_ph && (
                  <Label basic color="red" pointing>
                    Email phụ huynh không hợp lệ
                  </Label>
                )}
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Tên học sinh</div>
                <br></br>
                <TextBox
                  value={ten_hs}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { ten_hs: e.value })
                    );
                  }}
                />
                <input
                  name="ten_hs"
                  className="hide"
                  defaultValue={ten_hs}
                  value={ten_hs}
                ></input>
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Khối</div>
                <br></br>
                <CbxKhoi
                  showClearButton={true}
                  value={dm_khoi_id}
                  onValueChanged={(value) => {
                    set_campaign(
                      Object.assign({}, campaign, { dm_khoi_id: value })
                    );
                  }}
                />
                <input
                  name="dm_khoi_id"
                  className="hide"
                  defaultValue={dm_khoi_id}
                  value={dm_khoi_id}
                  ref={register({
                    required: true,
                  })}
                ></input>
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Học sinh</div>
                <br></br>
                <SearchStudent
                  nam_hoc={namhoc}
                  onResultChanged={({ id }) => {
                    set_campaign(
                      Object.assign({}, campaign, { ts_hocsinh_id: id })
                    );
                  }}
                  defaultValue={defaultStudentData?.data}
                ></SearchStudent>
                <input
                  name="ts_hocsinh_id"
                  className="hide"
                  defaultValue={ts_hocsinh_id}
                  value={ts_hocsinh_id}
                ></input>
              </div>
              <div className="col-md-6 padding-top-1rem">
                <div className="dx-field-label">Ghi chú</div>
                <br></br>
                <TextArea
                  autoResizeEnabled={true}
                  value={ghi_chu}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_campaign(
                      Object.assign({}, campaign, { ghi_chu: e.value })
                    );
                  }}
                />
                <input
                  name="ghi_chu"
                  className="hide"
                  defaultValue={ghi_chu}
                  value={ghi_chu}
                ></input>
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={onCancelModal} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? 'Cập nhật' : 'Thêm mới'}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DetailModal;
