import React, { useContext } from 'react';

import { DataGrid } from 'devextreme-react';
import {
  FilterPanel,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';

import { StoreContext } from '../../contexts/store';
import getColumnsDataGrid from './columnDataGrid';
import DetailCampaignTemplate from './detail/DetailCampaignTemplate';

const CampaignsDataGrid = ({
  grid,
  campaigns,
  list_campaign_selected,
  set_list_campaign_selected,
  set_selected_campaign,
  set_is_show_modal,
  set_is_show_confirm_delete,
  set_is_show_confirm_delete_tu_van_vien,
}) => {
  const { can_accessed } = useContext(StoreContext);

  const btnEdit_click = (data) => {
    set_is_show_modal(true);
    set_selected_campaign(data);
  };
  const btnDelete_click = (data) => {
    set_is_show_confirm_delete(true);
    set_selected_campaign(data);
  };

  const btnDeleteUserTuVanVien_click = (data) => {
    set_is_show_confirm_delete_tu_van_vien(true);
    set_selected_campaign(data);
  };

  const columns = getColumnsDataGrid({
    btnEdit_click,
    btnDelete_click,
    btnDeleteUserTuVanVien_click,
    can_accessed,
    grid,
  });

  return (
    <DataGrid
      columnHidingEnabled={false}
      showColumnLines={false}
      showRowLines={false}
      rowAlternationEnabled={true}
      focusedRowEnabled={false}
      ref={grid}
      keyExpr={'id'}
      dataSource={campaigns}
      showBorders={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      columnAutoWidth={false}
      hoverStateEnabled={true}
      height={window.innerHeight - 168}
      className={'table-noborder-left'}
      wordWrapEnabled={true}
      selectedRowKeys={list_campaign_selected}
      onSelectionChanged={({ selectedRowKeys }) => {
        set_list_campaign_selected(selectedRowKeys);
      }}
      // columns={columns}
    >
      <Sorting mode="multiple" />
      <Paging defaultPageSize={10} />
      <Selection mode="multiple" showCheckBoxesMode="always" />

      <FilterRow visible={true} />
      <FilterPanel visible={true} />
      <HeaderFilter visible={true} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20, 50, 200, 500]}
        infoText="Trang {0} /{1} ({2} dòng)"
        showInfo={true}
      />
      {columns}
      <MasterDetail enabled={false} component={DetailCampaignTemplate} />
    </DataGrid>
  );
};

export default CampaignsDataGrid;
