import React, { useReducer, useContext } from 'react'
import { action_type, ini_state, reducer, status_enum } from './reducer'
import { PrimaryButton, DefaultButton } from '../../../components/editor/button'
import { InValidMessage } from '../../../components/editor/invalid_message'
import ComboboxTruong from '../../../components/editor/cmb_truong'
import ComboboxKhoi from '../../../components/editor/cmb_khoi'
import ComboboxHe from '../../../components/editor/cmb_he'
import ComboboxLop from '../../../components/editor/cmb_lop'
import SearchStudent from '../../../components/editor/ts_hocsinh_search'
import { Modal } from 'semantic-ui-react';
import { StoreContext } from '../../../contexts/store'
import { useForm } from "react-hook-form";
import { themhsvaolop } from "../../../api/hocsinh_lop";
export default ({ nam_hoc, onCancel, onSuccess }) => {
    const [state, dispatch] = useReducer(reducer, ini_state);
    const { set_notification, can_accessed } = useContext(StoreContext);
    const { register, trigger, errors } = useForm();
    const {
        status,
        ts_hocsinh_chuyencap
    } = state;
    const handleSaveChanges = () => {
        trigger().then(async (isValid) => {
            if (isValid) {
                dispatch({ type: action_type.BEGIN_SAVING });
                const res = await themhsvaolop({
                    nam_hoc: nam_hoc,
                    id_he: ts_hocsinh_chuyencap.dm_he_id,
                    id_truong: ts_hocsinh_chuyencap.dm_truong_id,
                    id_khoi: ts_hocsinh_chuyencap.dm_khoi_id,
                    id_lop: ts_hocsinh_chuyencap.dm_lop_id,
                    id_hocsinh: ts_hocsinh_chuyencap.ts_hocsinh_id
                });
                dispatch({ type: action_type.END_SAVING });
                if (res.is_success) {
                    set_notification({ text: "Thêm thành công ", type: "success" })
                    onSuccess();
                } else {
                    set_notification({ text: res.message, type: "error" });
                }
            }
        })
    }
    return (
        <React.Fragment>
            <Modal open={true} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    Phân lớp cho học sinh
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <div className="row">

                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Chọn trường</div>
                                <br></br>
                                <ComboboxTruong onValueChanged={(value) => {
                                    dispatch({
                                        type: action_type.CHANGE_DATA,
                                        payload: {
                                            ...ts_hocsinh_chuyencap,
                                            dm_truong_id: value,
                                            dm_khoi_id: 0,
                                            dm_he_id: 0,
                                            dm_lop_id: 0
                                        }
                                    });
                                }} />
                                <InValidMessage name="dm_truong_id" defaultValue={ts_hocsinh_chuyencap.dm_truong_id}
                                    register={register({ required: true, min: 1 })}
                                    errors={errors}
                                    message="Vui lòng chọn trường"
                                />

                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Chọn khối</div>
                                <br></br>
                                <ComboboxKhoi dm_truong_id={ts_hocsinh_chuyencap.dm_truong_id} onValueChanged={(value) => {
                                    dispatch({
                                        type: action_type.CHANGE_DATA,
                                        payload: {
                                            ...ts_hocsinh_chuyencap,
                                            dm_khoi_id: value,
                                            dm_he_id: 0,
                                            dm_lop_id: 0
                                        }
                                    });
                                }} />
                                <InValidMessage name="dm_khoi_id" defaultValue={ts_hocsinh_chuyencap.dm_khoi_id}
                                    register={register({ required: true, min: 1 })}
                                    errors={errors}
                                    message="Vui lòng chọn khối"
                                />

                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Chọn khối</div>
                                <br></br>
                                <ComboboxHe dm_khoi_id={ts_hocsinh_chuyencap.dm_khoi_id} onValueChanged={(value) => {
                                    dispatch({
                                        type: action_type.CHANGE_DATA,
                                        payload: {
                                            ...ts_hocsinh_chuyencap,
                                            dm_he_id: value,
                                            dm_lop_id: 0
                                        }
                                    });
                                }} />
                                <InValidMessage name="dm_he_id" defaultValue={ts_hocsinh_chuyencap.dm_he_id}
                                    register={register({ required: true, min: 1 })}
                                    errors={errors}
                                    message="Vui lòng chọn hệ"
                                />

                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label-full">Chọn lớp</div>
                                <br></br>
                                <ComboboxLop
                                    nam_hoc={nam_hoc}
                                    dm_khoi_id={ts_hocsinh_chuyencap.dm_khoi_id}
                                    dm_he_id={ts_hocsinh_chuyencap.dm_he_id}
                                    dm_truong_id={ts_hocsinh_chuyencap.dm_truong_id}
                                    onValueChanged={(value) => {
                                        dispatch({
                                            type: action_type.CHANGE_DATA,
                                            payload: {
                                                ...ts_hocsinh_chuyencap,
                                                dm_lop_id: value
                                            }
                                        });
                                    }} />
                                <InValidMessage name="dm_lop_id" defaultValue={ts_hocsinh_chuyencap.dm_lop_id}
                                    register={register({ required: true, min: 1 })}
                                    errors={errors}
                                    message="Vui lòng chọn lớp"
                                />
                            </div>
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label">Chọn học sinh</div>
                                <br></br>
                                <SearchStudent
                                    nam_hoc={nam_hoc}
                                    dm_he_id={ts_hocsinh_chuyencap.dm_he_id}
                                    dm_khoi_id={ts_hocsinh_chuyencap.dm_khoi_id}
                                    dm_truong_id={ts_hocsinh_chuyencap.dm_truong_id} onResultChanged={(data) => {
                                        console.log(data);
                                        dispatch({
                                            type: action_type.CHANGE_DATA,
                                            payload: {
                                                ...ts_hocsinh_chuyencap,
                                                ts_hocsinh_id: data.id
                                            }
                                        });
                                    }}></SearchStudent>
                                <InValidMessage name="ts_hocsinh_id" defaultValue={ts_hocsinh_chuyencap.ts_hocsinh_id}
                                    register={register({ required: true, min: 1 })}
                                    errors={errors}
                                    message="Vui lòng chọn học sinh"
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <DefaultButton onClick={onCancel} text="Đóng" />
                        <PrimaryButton onClick={handleSaveChanges} text="Thêm học sinh"
                            disabled={status == status_enum.is_saving}
                            isLoading={status == status_enum.is_saving}
                            icon="fas fa-check"
                        />


                    </div>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    );
}