import DataGrid, { Column, FilterRow, HeaderFilter, Lookup, Pager, Paging, Selection, Sorting } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Confirm, Icon, Transition } from 'semantic-ui-react';
import { select_by_lops as ts_hocsinh_select_by_lops } from "../../api/common_api_combo";
import { xoahskhoilop } from "../../api/hocsinh_lop";
import { TreeViewLop } from '../../components/editor/treeview_lop';
import { StoreContext } from "../../contexts/store";
import DetailModal from './add_hocsinh_modal/add_hocsinh_modal';
import ChuyenLopModal from './chuyen_lop_modal';
import "./hocsinh_theo_lop.css";
import LenLopModal from './len_lop_modal';


const ini_state = {
    hocsinh_lop: [],
    selected_lop: {},
}
const action_type = {
    SET_HOC_SINH_LOP: "SET_HOC_SINH_LOP",
    SET_SELECTED_HOC_SINH_LOP: "SET_SELECTED_HOC_SINH_LOP"
}
const gioiTinhDataSource = [
    { 'Name': 'Nam', 'Id': 1 },
    { 'Name': 'Nữ', 'Id': 2 },
];

export default () => {

    const { set_notification, can_accessed } = useContext(StoreContext)
    const [set_visible, setVisible] = useState("none");
    const [is_show_modal_chuyen_lop, set_is_show_modal_chuyen_lop] = useState(false);
    const [is_show_modal_len_lop, set_is_show_modal_len_lop] = useState(false);

    const [dataCacheLop, setCacheLop] = useState();
    const [is_loading, set_is_loading] = useState(false);
    const [is_show_modal, set_is_show_modal] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
    const [totalHocSinh, setTotalHocSinh] = useState();
    const [dataTreeLop, setDataTreeLop] = useState([]);
    const [dataXoaAll, setDataXoaAll] = React.useState(null);
    const [id_hocsinhs, set_id_hocsinhs] = useState([]);
    const [nam_hoc, set_nam_hoc] = useState(localStorage.nam_hoc ? localStorage.nam_hoc : "");
    const [dm_lop_ids, set_dm_lop_ids] = useState([]);
    const treeViewRef = React.createRef();
    const grid = useRef(null);
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.SET_HOC_SINH_LOP:
                return {
                    ...state,
                    hocsinh_lop: action.payload,
                };
            case action_type.SET_SELECTED_HOC_SINH_LOP:
                return {
                    ...state,
                    selected_lop: action.payload,
                };
            default:
                return state
        }
    }, ini_state);

    useEffect(() => {
        if (is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [is_loading]);
    useEffect(() => {
        if (nam_hoc && dm_lop_ids) {
            reload_hocsinh();
            localStorage.nam_hoc = nam_hoc;
        }
    }, [nam_hoc, dm_lop_ids]);
    const reload_hocsinh = async () => {
        const res = await ts_hocsinh_select_by_lops({
            nam_hoc: nam_hoc,
            dm_lop_ids: dm_lop_ids
        });
        if (res.is_success) {
            dispatch({ type: action_type.SET_HOC_SINH_LOP, payload: res.data });
        } else {
            dispatch({ type: action_type.END_SELECT_HOCSINHS, payload: [] })
            set_notification({ type: "error", text: "Có lỗi: " + res.message })
        }
    }

    const btnDell_All = (data) => {
        let result = data.selectedRowsData.map(a => a.id_hocsinh);
        set_id_hocsinhs(result);
        setDataXoaAll(result.toString());
        setTotalHocSinh(result.length)
    }
    const confirmDeleteAll = async () => {
        let val = { list_id_hocsinh: dataXoaAll.toString(), nam_hoc: nam_hoc };
        let obj2 = Object.assign({}, val);

        const res = await xoahskhoilop(obj2);
        if (res.is_success) {
            set_is_show_confirm_delete(false);
            reload_hocsinh();
        } else {
            set_notification({ text: "res.message", type: "error" });
        }
    }
    const btnDeleteAll_click = () => {
        if (dataXoaAll == null || dataXoaAll == undefined) {

            set_notification({ text: "Vui lòng chọn học sinh cần xóa!", type: "warning" });
            return false;
        }
        else {
            set_is_show_confirm_delete(true);
        }

    }


    const handleChuyenLop = () => {
        set_is_show_modal_chuyen_lop(true);

    }



    const column = [
        <Column key={0} dataField="trang_thai" width={100} caption="Trạng thái"></Column>,
        <Column key={1} dataField="ma_hs" width={120} caption="Mã học sinh"></Column>,
        <Column key={2} dataField="ho_ten" minWidth={200} caption="Họ tên"></Column>,
        <Column key={3} dataField="ngay_sinh" width={120} dataType="date" format="dd/MM/yyyy" caption="Ngày sinh"></Column>,
        <Column key={4} dataField="dm_gioitinh_id" width={100} caption="Giới tính">
            <Lookup key={41} dataSource={gioiTinhDataSource} displayExpr="Name" valueExpr="Id" />
        </Column>,
        <Column key={5} width={200} dataField="ten_truong" caption="Tên trường"></Column>,
        <Column key={6} width={100} dataField="ten_khoi" caption="Tên khối"></Column>,
        <Column key={7} width={150} dataField="ten_he" caption="Tên hệ"></Column>,
        <Column key={8} width={100} dataField="ten_lop" caption="Tên lớp"></Column>,
    ];

    const renderTreeViewItem = (item) => {
        return (
            <div className={"node-level-" + item.level}>
                {item.name}
            </div>
        );
    }
    return (
        <React.Fragment>
            <Transition animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}>
                <div>
                    <div className="row">

                        <div className="col-md-2 padding-2rem left-panel">
                            <TreeViewLop onValueChanged={({ nam_hoc, dm_lop_ids }) => {
                                set_nam_hoc(nam_hoc);
                                set_dm_lop_ids(dm_lop_ids);
                            }} nam_hoc={nam_hoc} />
                            {/* <h5>Chọn lớp</h5>
                            <SelectBox dataSource={selectNamHoc}
                                displayExpr="nam_hoc"
                                valueExpr="nam_hoc"
                                placeholder="Chọn năm học"
                                stylingMode="outlined"
                                value={dataNamHoc}
                                onValueChanged={(e) => {
                                    handleChangeNamhoc(e.value)
                                }}
                            />
                            <TreeView
                                className="dx-item-content dx-treeview-item-content"
                                noDataText="Không có dữ liệu"
                                ref={treeViewRef}
                                items={dataTreeLop}
                                height={window.innerHeight - 200}
                                itemsExpr="Children"
                                keyExpr={'key'}
                                displayExpr='name'
                                selectionMode={'multiple'}
                                focusStateEnabled={false}
                                expandEvent={'click'}
                                onSelectionChanged={selectedItemChanged}
                                selectNodesRecursive={true}
                                selectByClick={false}
                                className="treeview"
                                showCheckBoxesMode={'normal'}
                                itemRender={renderTreeViewItem}

                            /> */}
                        </div>
                        <div className="col-md-10">

                            <Toolbar className="bg-background">
                                {can_accessed("api/lop/automaticclassifier") &&
                                    <Item location="after"
                                        render={() => {
                                            return (
                                                // <Button disabled={is_check} onClick={ ()=>{  set_is_show_modal(true);}}     text="Thêm" type="default"/>
                                                <a className="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => {
                                                    set_is_show_modal(true);
                                                }}  >
                                                    <Icon name="plus"></Icon>
                                                    Thêm học sinh
                                                </a>
                                            );
                                        }}
                                    />
                                }
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <button disabled={(dataXoaAll == null || dataXoaAll == "")} className="p-btn p-btn-sm" onClick={handleChuyenLop}>
                                                <Icon name="undo"></Icon>
                                                Chuyển lớp {(dataXoaAll != null && dataXoaAll != "") ? "(" + dataXoaAll.split(',').length + ")" : ""}
                                            </button>
                                            // <Button disabled={is_check} onClick={ ()=>{  set_is_show_modal(true);}}    text="Chuyển lớp" type="success"/>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <button disabled={(dataXoaAll == null || dataXoaAll == "")} className="p-btn p-btn-sm" onClick={() => {
                                                set_is_show_modal_len_lop(true);
                                            }}  >
                                                <Icon name="redo"></Icon>
                                                Lên lớp {(dataXoaAll != null && dataXoaAll != "") ? "(" + dataXoaAll.split(',').length + ")" : ""}
                                            </button>
                                            // <Button disabled={is_check} onClick={ ()=>{  set_is_show_modal(true);}}     text="Lên lớp" type="default"/>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <button disabled={(dataXoaAll == null || dataXoaAll == "")} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color" onClick={() => { btnDeleteAll_click() }}   >
                                                <Icon name="cancel"></Icon>
                                                Xóa {(dataXoaAll != null && dataXoaAll != "") ? "(" + dataXoaAll.split(',').length + ")" : ""}
                                            </button>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a className="p-btn  p-btn-sm" onClick={() => { grid.current.instance.exportToExcel(); }}  >
                                                <Icon name="file excel outline"></Icon>
                                                Xuất excel
                                            </a>
                                        );
                                    }}
                                />
                            </Toolbar>


                            <DataGrid
                                noDataText="Không có dữ liệu học sinh"
                                ref={grid}
                                keyExpr="id"
                                dataSource={state.hocsinh_lop}
                                columnHidingEnabled={false}
                                columnAutoWidth={false}
                                showBorders={true}
                                showColumnLines={false}
                                showRowLines={false}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={true}
                                focusedRowEnabled={false}
                                height={window.innerHeight - 120}
                                onSelectionChanged={btnDell_All}

                            >
                                {column}
                                <Selection mode="multiple"
                                    selectAllMode="allPages"
                                    showCheckBoxesMode="always"
                                ></Selection>
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={50} />
                                <FilterRow visible={true} showOperationChooser={true} resetOperationText={false} operationDescriptions={'contains'} />
                                {/* <FilterPanel visible={true} /> */}
                                <HeaderFilter visible={true} />
                                {/* <SearchPanel visible={true}
                                width={250}
                                placeholder="Tìm kiếm..." /> */}
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20, 50, 200, 500]}
                                    infoText="Trang {0} /{1} ({2} dòng)"
                                    showInfo={true} />
                            </DataGrid>
                        </div>
                        {is_show_modal &&
                            <DetailModal is_show_model={is_show_modal}
                                onCancel={() => { set_is_show_modal(false) }}
                                onSuccess={() => { set_is_show_modal(false); reload_hocsinh(); }}
                                nam_hoc={nam_hoc}
                            ></DetailModal>
                        }

                        <ChuyenLopModal is_show_model={is_show_modal_chuyen_lop}
                            onCancel={() => { set_is_show_modal_chuyen_lop(false) }}
                            onSuccess={() => { set_is_show_modal_chuyen_lop(false); reload_hocsinh(); }}
                            dataChuyenLop={dataXoaAll}
                            dataNamHocChuyenLop={nam_hoc}
                            totalHocSinh={totalHocSinh}
                            id_hocsinhs={id_hocsinhs}

                        ></ChuyenLopModal>

                        <LenLopModal is_show_model={is_show_modal_len_lop}
                            onCancel={() => { set_is_show_modal_len_lop(false) }}
                            onSuccess={() => { set_is_show_modal_len_lop(false); reload_hocsinh(); }}
                            id_hocsinhs={id_hocsinhs}
                        ></LenLopModal>
                    </div>

                </div>
            </Transition>
            <Confirm
                open={is_show_confirm_delete}
                dimmer={"blurring"}
                header='Lưu ý'
                content='Bạn có chắc chắn muốn xóa học sinh ?'
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm_delete(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { confirmDeleteAll() }} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Xóa
                        </button>
                    );
                }}
            />
        </React.Fragment>
    );
}