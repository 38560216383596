import React from 'react';

import { Search } from 'semantic-ui-react';
import { search_hs } from '../../api/common_api_combo';
import './hocsinh_search.css';
const initialState = {
  loading: false,
  results: [],
  value: '',
};

const search_reducer = (state, action) => {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
};

export default ({ nam_hoc, onResultChanged, defaultValue }) => {
  const composeTitle = (hocsinh) => {
    return (
      (hocsinh.ma_hs !== '' || hocsinh.ma_tn !== ''
        ? '[' + (hocsinh.ma_hs !== '' ? hocsinh.ma_hs : hocsinh.ma_tn) + '] - '
        : '') + hocsinh.ho_ten
    );
  };

  const [state, dispatch] = React.useReducer(search_reducer, initialState);
  const { loading, results, value } = state;
  const timeoutRef = React.useRef();

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: 'START_SEARCH', query: data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: 'CLEAN_QUERY' });
          return;
        }
        (async () => {
          const res = await search_hs({
            nam_hoc: nam_hoc,
            key: data.value,
          });
          if (res.is_success) {
            dispatch({
              type: 'FINISH_SEARCH',
              results: res.data.map((x) => {
                return {
                  title: composeTitle(x),
                  ho_ten: x.ho_ten,
                  ma_hs: x.ma_hs,
                  id: x.id,
                };
              }),
            });
          } else {
            return state;
          }
        })();
      }, 500);
    },
    [nam_hoc]
  );
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  React.useEffect(() => {
    dispatch({
      type: 'FINISH_SEARCH',
      results: defaultValue ? [defaultValue] : [],
    });
    dispatch({
      type: 'UPDATE_SELECTION',
      selection: defaultValue ? composeTitle(defaultValue) : '',
    });
  }, [defaultValue]);
  React.useEffect(() => {
    dispatch({ type: 'CLEAN_QUERY' });
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [nam_hoc]);
  const resultRenderer = (hocsinh) => {
    return (
      <React.Fragment>
        <div className="content">
          <div className="title">{hocsinh.ho_ten}</div>
          <div className="description">{hocsinh.ma_tn}</div>
          <div className="description">{hocsinh.ma_hs}</div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Search
      loading={loading}
      onResultSelect={(e, data) => {
        dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
        onResultChanged(data.result);
      }}
      onSearchChange={handleSearchChange}
      results={results}
      resultRenderer={resultRenderer}
      value={value}
      input={{ icon: 'search', placeholder: 'Tìm theo mã hoặc họ tên' }}
      fluid
      className="student_search"
    ></Search>
  );
};
