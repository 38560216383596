import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function select_all(id) {
    return call_post_api(appInfo.api_url + '/hocsinh_qhgd/select_all',id);
};
export async function select_by_id(id) {
    return call_post_api(appInfo.api_url + '/hocsinh_qhgd/select_by_id',id);
};
export async function insert(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_qhgd/insert',data);
}

export async function update(data) {
    return call_post_api(appInfo.api_url + '/hocsinh_qhgd/update',data);
}

export async function remove(id) {
    return call_post_api(appInfo.api_url + '/hocsinh_qhgd/delete',id);
}
