import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import app_info from './app-info';
import Content from './Content';
import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import StoreProvider from "./contexts/store";
import './dx-styles.scss';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import { useScreenSizeClass } from './utils/media-query';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
function App() {
    const { user, loading, signOut } = useAuth();

    const query = useQuery();
    const hash = query.get("hash");
    const user_id = query.get("user");
    const ticket_key = query.get("key");
    if (loading) {
        return <LoadPanel visible={true} />;
    }
    if (user) {
        if (user_id != null && user_id != user.id) {
            signOut();
        } else {
            return <Content />;
        }

    }
    else {
        if (hash != null && ticket_key != null) {
            return <NotAuthenticatedContent />
        } else {
            window.location.href = app_info.home_url + "/login?service=" + window.location.origin + "&system_id=" + app_info.sub_system_id;
            return <LoadPanel visible={true} />;
        }
    }

}


export default function () {
    const screenSizeClass = useScreenSizeClass();
    const queryClient = new QueryClient();
    return (
        <Router>
            <AuthProvider>
                <StoreProvider>
                    <NavigationProvider>
                    <QueryClientProvider client={queryClient}>
                        <div className={`app ${screenSizeClass}`}>
                            <App />
                        </div>
                        </QueryClientProvider>
                    </NavigationProvider>
                </StoreProvider>
            </AuthProvider>
        </Router>
    );
}
