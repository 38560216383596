import appInfo from "../app-info";
import { call_post_api } from "./api_helper";
export async function dm_he_select_by_khoi(id) {
  return call_post_api(appInfo.api_url + "/he/select_by_khoi", id);
}
export async function select_by_khoi(id) {
  return call_post_api(appInfo.api_url + "/he/select_by_khoi", id);
}
export async function select_all(id) {
  return call_post_api(appInfo.api_url + "/he/select_all", id);
}
