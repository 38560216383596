import React from 'react';
import DataGrid, {
    Sorting, Pager, Paging, Selection, FilterRow,
    HeaderFilter,
    FilterPanel
} from 'devextreme-react/data-grid';


export default ({ keyColumn, dataSource, columns, height, grid, isNoborderLeft }) => {

    return (
        <div>
            <div className='padding-top-5'>
                <DataGrid
                    ref={grid}
                    keyExpr={keyColumn}
                    dataSource={dataSource}
                    columnHidingEnabled={true}
                    //defaultColumns={columns}
                    columnAutoWidth={true}
                    showBorders={true}
                    showColumnLines={false}
                    showRowLines={false}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    columnResizingMode={"nextColumn"}
                    hoverStateEnabled={true}
                    focusedRowEnabled={false}
                    height={height}
                    className={isNoborderLeft != true ? "table-noborder-left" : ""}
                >
                    {columns}
                    <Sorting mode="multiple" />
                    <Paging defaultPageSize={10} />
                    <Selection mode="single"></Selection>
                    {/* <Selection mode="multiple" /> */}
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} />
                    {/* <Scrolling mode="infinite" /> */}
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 50, 200, 500]}
                        infoText="Trang {0} /{1} ({2} dòng)"
                        showInfo={true} />
                </DataGrid>
            </div>
        </div>
    );
};





