import axios from 'axios';
import app_config from '../app-info';
import jwt_decode from 'jwt-decode'

const refreshToken = () => {
    return new Promise(resolve => {
        setTimeout(async () => {
            const res = await axios.post(`${app_config.home_url}/api/login/refresh_new_token`, {
                access_token: localStorage.access_token,
                refresh_token: localStorage.refresh_token
            }).catch(() => {
                resolve("");
            });

            if (res.data.is_success) {
                resolve(res.data.message)
            } else {
                resolve("");
            }

        }, 1000);
    });
};


let refreshTokenRequest = null;
const check_token_expired = () => {
    const data = jwt_decode(localStorage.access_token)
    if (localStorage.access_token && data) {
        const exp = data.exp;
        if (parseInt(exp) > Math.floor(Date.now() / 1000)) {
            return false;
        }
    }
    return true;
}
export async function call_post_api(url, post_data) {
    try {
        const isTokenExpired = check_token_expired();
        if (isTokenExpired) {
            refreshTokenRequest = refreshTokenRequest
                ? refreshTokenRequest
                : refreshToken(url);

            const newTokens = await refreshTokenRequest;
            refreshTokenRequest = null;
            if (newTokens == "") {
                window.location.reload();
            }
            const new_access_token = newTokens.split(' ')[0];
            const new_refresh_token = newTokens.split(' ')[1];
            localStorage.access_token = new_access_token;
            localStorage.refresh_token = new_refresh_token;

        }

        const config = {

            headers: { Authorization: `Bearer ${localStorage.access_token}` }
        }
        const res = await axios.post(url, post_data, config).catch((error) => {
            if (error.response.status == 401 && !url.includes('api/login/detail')) {
                window.location.reload();
            }
        });
        if (res.status === 200) return res.data;
        return {
            is_success: false,
            message: res.status
        }
    } catch (error) {
        return {
            is_success: false,
            message: error
        }
    }
}




export async function call_get_api(url) {
    try {
        const isTokenExpired = check_token_expired();
        if (isTokenExpired) {
            refreshTokenRequest = refreshTokenRequest
                ? refreshTokenRequest
                : refreshToken(url);

            const newTokens = await refreshTokenRequest;
            refreshTokenRequest = null;
            if (newTokens == "") {
                return {
                    is_success: false,
                    message: "Token expired."
                }
            }
            const new_access_token = newTokens.split(' ')[0];
            const new_refresh_token = newTokens.split(' ')[1];
            localStorage.access_token = new_access_token;
            localStorage.refresh_token = new_refresh_token;

        }

        const config = {
            headers: { Authorization: `Bearer ${localStorage.access_token}` }
        }
        const res = await axios.get(url, config).catch((error) => {
            if (error.response.status == 401 && !url.includes('api/login/detail')) {
                window.location.reload();
            }
        });
        if (res.status === 200) {
            let data = res.data;
            return data;
        }
        else {

            return {
                is_success: false,
                message: res.status
            }
        }
    } catch (error) {
        return {
            is_success: false,
            message: error
        }
    }
}
