import React, { useEffect, useState } from "react";
import { Label, Modal, Loader } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/hocsinh_qhgd_api";
import { dm_quanhe_select_all } from "../../api/dm_quanhe_api";
import { dm_gioitinh_select_all } from "../../api/dm_gioitinh_api";
import { Switch } from "devextreme-react/switch";
export default ({ is_show_model, id, onSuccess, onCancel, hocsinh_id }) => {
  const [hocsinh_qhgd, set_hocsinh_qhgd] = useState();
  const [is_saving, set_is_saving] = useState(false);
  const [list_dm_quanhe, set_list_dm_quanhe] = useState();
  const [list_dm_gioitinh, set_list_dm_gioitinh] = useState();
  const { register, trigger, errors, clearErrors } = useForm();
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (hocsinh_qhgd.id > 0) {
          set_is_saving(true);
          const res = await update(
            Object.assign({}, hocsinh_qhgd, { ts_hocsinh_id: hocsinh_id, dm_gioitinh_id: 1 })
          );
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await insert(
            Object.assign({}, hocsinh_qhgd, { ts_hocsinh_id: hocsinh_id, dmdm_gioitinh_id: 1 })
          );
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        }
      }
    });
  };

  useEffect(() => {
    get_list_dm_quanhe();
    get_list_dm_gioitinh();
    clearErrors();
  }, []);

  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    if (id > 0 ) {
      const res = await select_by_id(id);
      if (res.is_success) {
        set_hocsinh_qhgd(res.data);
      }
    } else {
      set_hocsinh_qhgd({});
    }
  };

  const onCancelModal = () => {
    onCancel();
    set_hocsinh_qhgd({});
  };

  const get_list_dm_quanhe = async () => {
    const res = await dm_quanhe_select_all();
    if (res.is_success) {
      set_list_dm_quanhe(res.data);
    }
  };

  const get_list_dm_gioitinh = async () => {
    const res = await dm_gioitinh_select_all();
    if (res.is_success) {
      set_list_dm_gioitinh(res.data);
    }
  };
  return (
    <div>
      <Modal
        open={is_show_model}
        size="small"
        // dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {hocsinh_qhgd?.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input
              className="hide"
              name="id"
              defaultValue={hocsinh_qhgd?.id}
            ></input>
            <div className="row">
              <div className="col-md-6">
                <div className="dx-field-label">*Họ tên</div>
                <br></br>
                <TextBox
                  placeholder="Nhập họ tên"
                  value={hocsinh_qhgd?.ho_ten}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { ho_ten: e.value })
                    );
                    trigger("ho_ten");
                  }}
                />
                <input
                  name="ho_ten"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.ho_ten}
                  value={hocsinh_qhgd?.ho_ten}
                  ref={register({ required: true })}
                ></input>
                {errors.ho_ten && (
                  <Label basic color="red" pointing>
                    Vui lòng điền họ tên
                  </Label>
                )}
              </div>
              <div className="col-md-6">
                <div className="dx-field-label">*Quan hệ</div>
                <br></br>
                <SelectBox
                  stylingMode="outlined"
                  placeholder="Chọn quan hệ"
                  defaultValue={hocsinh_qhgd?.dm_quanhe_id}
                  value={hocsinh_qhgd?.dm_quanhe_id}
                  dataSource={list_dm_quanhe}
                  displayExpr="quan_he"
                  valueExpr="id"
                  noDataText="Không có dữ liệu"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { dm_quanhe_id: e.value })
                    );
                    trigger("dm_quanhe_id");
                  }}
                />
                <input
                  name="dm_quanhe_id"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.dm_quanhe_id}
                  ref={register({ required: true })}
                ></input>
                {errors.dm_quanhe_id && (
                  <Label basic color="red" pointing>
                    Vui lòng chọn quan hệ với học sinh
                  </Label>
                )}
              </div>
            </div>

            {/* row 2 */}
            <div className="row padding-top-1rem" >
              <div className="col-md-6">
                <div className="dx-field-label">*Nơi ở</div>
                <br></br>
                <TextBox
                  placeholder="Nhập nơi ở"
                  value={hocsinh_qhgd?.noi_o}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { noi_o: e.value })
                    );
                    trigger("noi_o");
                  }}
                />
                <input
                  name="noi_o"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.noi_o}
                  ref={register({ required: true })}
                ></input>
                {errors.noi_o && (
                  <Label basic color="red" pointing>
                    Vui lòng điền nơi ở
                  </Label>
                )}
              </div>
              <div className="col-md-6">
                <div className="dx-field-label">*Nghề nghiệp</div>
                <br></br>
                <TextBox
                  placeholder="Nhập nghề nghiệp"
                  value={hocsinh_qhgd?.nghe_nghiep}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { nghe_nghiep: e.value })
                    );
                    trigger("nghe_nghiep");
                  }}
                />
                <input
                  name="nghe_nghiep"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.nghe_nghiep}
                  ref={register({ required: true })}
                ></input>
                {errors.nghe_nghiep && (
                  <Label basic color="red" pointing>
                    Vui lòng điền nghề nghiệp
                  </Label>
                )}
              </div>
            </div>

            {/* row 3 */}
            <div className="row padding-top-1rem">
              <div className="col-md-6">
                <div className="dx-field-label">*Nơi làm việc</div>
                <br></br>
                <TextBox
                  placeholder="Nhập nơi làm việc"
                  value={hocsinh_qhgd?.noi_lam_viec}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { noi_lam_viec: e.value })
                    );
                    trigger("noi_lam_viec");
                  }}
                />
                <input
                  name="noi_lam_viec"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.noi_lam_viec}
                  ref={register({ required: true })}
                ></input>
                {errors.noi_lam_viec && (
                  <Label basic color="red" pointing>
                    Vui lòng điền nơi làm việc
                  </Label>
                )}
              </div>
              <div className="col-md-6">
                <div className="dx-field-label">*Ngày sinh</div>
                <br></br>
                <DateBox
                  type="date"
                  displayFormat={"dd/MM/yyyy"}
                  showClearButton={true}
                  useMaskBehavior={true}
                  placeholder="Nhập hoặc chọn ngày sinh"
                  value={hocsinh_qhgd?.ngay_sinh}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { ngay_sinh: e.value })
                    );
                    trigger("ngay_sinh");
                  }}
                />
                <input
                  name="ngay_sinh"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.ngay_sinh}
                  ref={register({ required: true })}
                ></input>
                {errors.ngay_sinh && (
                  <Label basic color="red" pointing>
                    Vui lòng chọn ngày sinh
                  </Label>
                )}
              </div>
            </div>
            {/* row 4 */}
            <div className="row padding-top-1rem">
              {/* <div className="col-md-6">
                <div className="dx-field-label">*Giới tính</div>
                <br></br>
                <SelectBox
                  placeholder="Chọn giới tính"
                  stylingMode="outlined"
                  defaultValue={hocsinh_qhgd?.dm_gioitinh_id}
                  value={hocsinh_qhgd?.dm_gioitinh_id}
                  dataSource={list_dm_gioitinh}
                  displayExpr="gioi_tinh"
                  valueExpr="id"
                  noDataText="Không có dữ liệu"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, {
                        dm_gioitinh_id: e.value,
                      })
                    );
                    trigger("dm_gioitinh_id");
                  }}
                />
                <input
                  name="dm_gioitinh_id"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.dm_gioitinh_id}
                  ref={register({ required: true })}
                ></input>
                {errors.dm_gioitinh_id && (
                  <Label basic color="red" pointing>
                    Vui lòng chọn giới tính
                  </Label>
                )}
              </div> */}
              <div className="col-md-6">
                <div className="dx-field-label">Email</div>
                <br></br>
                <TextBox
                  placeholder="Nhập email"
                  value={hocsinh_qhgd?.email}
                  stylingMode="outlined"
                  placeholder="Nhập email"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { email: e.value })
                    );
                    trigger("email");
                  }}
                />
                <input
                  name="email"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.email}
                  value={hocsinh_qhgd?.email}
                  ref={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })}
                ></input>
                {errors.email && (
                  <Label basic color="red" pointing>
                    Email không hợp lệ
                  </Label>
                )}
              </div>
              <div className="col-md-6">
                <div className="dx-field-label">Điện thoại</div>
                <br></br>
                <TextBox
                  placeholder="Nhập SĐT"
                  value={hocsinh_qhgd?.dien_thoai}
                  stylingMode="outlined"
                  placeholder="Nhập SĐT"
                  onValueChanged={(e) => {
                    set_hocsinh_qhgd(
                      Object.assign({}, hocsinh_qhgd, { dien_thoai: e.value })
                    );
                    trigger("dien_thoai");
                  }}
                />
                <input
                  name="dien_thoai"
                  className="hide"
                  defaultValue={hocsinh_qhgd?.dien_thoai}
                  value={hocsinh_qhgd?.dien_thoai}
                  ref={register({ required: true, pattern: /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/})}
                ></input>
                {errors.dien_thoai && (
                  <Label basic color="red" pointing>
                    SĐT không hợp lệ
                  </Label>
                )}
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={() => onCancelModal()} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? "Cập nhật" : "Thêm mới"}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
