import React, { useEffect, useState } from "react";
import { Label, Modal, Loader } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/pnl";
import { Switch } from "devextreme-react/switch";
export default ({ is_show_model, id, onSuccess, onCancel }) => {
  const [pnl, set_pnl] = useState({});
  const [is_saving, set_is_saving] = useState(false);
  const { register, trigger, errors } = useForm();
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (pnl.id > 0) {
          set_is_saving(true);
          const res = await update(pnl);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await insert(pnl);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        }
      }
    });
  };
  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    if (id > 0) {
      const res = await select_by_id(id);
      if (res.is_success) {
        console.log(res.data);
        set_pnl(res.data);
      }
    } else {
      set_pnl({});
    }
  };
  return (
    <div>
      <Modal
        open={is_show_model}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {pnl.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={pnl.id}></input>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Mã PnL</div>
                <br></br>
                <TextBox
                  value={pnl.ma_pnl}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_pnl(Object.assign({}, pnl, { ma_pnl: e.value }));
                    trigger("ma_pnl");
                  }}
                />
                <input
                  name="ma_pnl"
                  className="hide"
                  defaultValue={pnl.ma_pnl}
                  ref={register({ required: true })}
                ></input>
                {errors.ma_pnl && (
                  <Label basic color="red" pointing>
                    Vui lòng điền mã PnL
                  </Label>
                )}
              </div>

              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Tên PnL</div>
                <br></br>
                <TextBox
                  value={pnl.ten_pnl}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_pnl(Object.assign({}, pnl, { ten_pnl: e.value }));
                    trigger("ten_pnl");
                  }}
                />
                <input
                  name="ten_pnl"
                  className="hide"
                  defaultValue={pnl.ten_pnl}
                  ref={register({ required: true })}
                ></input>
                {errors.ten_pnl && (
                  <Label basic color="red" pointing>
                    Vui lòng điền tên PnL
                  </Label>
                )}
              </div>

              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Là Phenikaa School</div>
                <br></br>
                <Switch
                  value={pnl.is_phenikaa}
                  onValueChanged={(e) => {
                    set_pnl(Object.assign({}, pnl, { is_phenikaa: e.value }));
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={onCancel} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? "Cập nhật" : "Thêm mới"}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
