import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function chamsockhachhang_select_all(dk) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/select_all',dk);
};

export async function chamsockhachhang_get_info_by_phone(phone) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/get_info_by_phone',phone);
};

export async function chamsockhachhang_select_by_id(id) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/select_by_id',id);
};
export async function chamsockhachhang_insert(data) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/insert',data);
}

export async function chamsockhachhang_update(data) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/update',data);
}

export async function chamsockhachhang_remove(id) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/delete',id);
}

export async function chamsockhachhang_change_status(data) {
    return call_post_api(appInfo.api_url + '/chamsockhachhang/change_status',data);
}

