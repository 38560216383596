import React, { useState, useEffect } from 'react';
import { dm_chiendich_select_by_nam_hoc } from '../../api/dm_chiendich_api';
import { TagBox } from 'devextreme-react';
const ComboboxChienDichMultiple = ({
  value,
  onValueChanged,
  cmb,
  namhoc,
  showClearButton,
}) => {
  const [dm_chiendichs, set_dm_chiendichs] = useState([]);
  useEffect(() => {
    (async () => {
      onValueChanged([]);
      const res = await dm_chiendich_select_by_nam_hoc({ namhoc });
      if (res.is_success) {
        set_dm_chiendichs(res.data);
      } else {
        set_dm_chiendichs([]);
      }
    })();
  }, [namhoc]);
  return (
    <TagBox
      items={dm_chiendichs}
      showSelectionControls={true}
      searchEnabled
      displayExpr={'ten_chien_dich'}
      valueExpr={'id'}
      value={value}
      multiline={false}
      stylingMode="outlined"
      ref={cmb}
      placeholder="Chọn chiến dịch"
      width="350px"
      showClearButton={showClearButton}
      onValueChanged={({ value }) => {
        onValueChanged(value);
      }}
    />
  );
};

export default ComboboxChienDichMultiple;
