import React, { useEffect, useState ,useContext} from 'react';
import { Label, Modal } from 'semantic-ui-react';
import {SelectBox} from 'devextreme-react/select-box';
import { useForm } from "react-hook-form";
import { tu_dong_phan_lop,huy_phan_lop } from "../../api/lop_api";
import { StoreContext } from "../../contexts/store";
import { select_lop} from "../../api/common_api_combo";
import {TagBox}  from 'devextreme-react/tag-box';

const tieuChiSource = [
    { 'Name': 'Họ Tên', 'Id': 1 },
    { 'Name': 'Giới tính', 'Id': 2 },
  ];

export default ({ is_show_model, id, onSuccess, onCancel, dataPhanLop, dataNamHoc, isCheck, totalHocSinh, dataHocSinh}) => {

    const { set_notification } = useContext(StoreContext)
    const [dataLop, setLop] = useState({});
    const [dataSelectLop, setSelectLop] = useState({});
    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
               
                    set_is_saving(true);
                    const res = await tu_dong_phan_lop(dataLop);
                    set_is_saving(false);
                    if (res.is_success) {
                        set_notification({ text: "Phân lớp thành công " , type: "success" })
                        onSuccess();
                    } else {
                        set_notification({ text: res.message, type: "error" });
                    }

                // else
                // {
                //     set_is_saving(true);
                //     const res = await huy_phan_lop(dataLop);
                //     set_is_saving(false);
                //     if (res.is_success) {
                //         set_notification({ text: "Hủy phân lớp thành công " , type: "success" })
                //         onSuccess();
                //     } else {
                //         set_notification({ text: res.message, type: "error" });
                //     }
                // }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    const reload = async () => {
        const res = await select_lop(dataPhanLop);
        if(res.is_success){
            // let result = res.data.map(a => a.ten_lop);
            setSelectLop(res.data)
        }

        setLop(Object.assign({}, dataLop, { nam_hoc: dataNamHoc, id_he: dataPhanLop.id_he, id_truong: dataPhanLop.id_truong, id_khoi: dataPhanLop.id_khoi, list_id_hs: dataHocSinh }))

    }



    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                       <p>Phân lớp</p>
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <div className="row">
                             <div className="col-md-12 padding-left-2rem " style={{ paddingTop: 25 }}>
                                <p>Số lượng học sinh: {totalHocSinh} </p>
                            </div>

                            <div className="col-md-12 padding-left-2rem " style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Lớp học</div>
                                <br></br>
                                <TagBox items={dataSelectLop}
                                            showSelectionControls={true}
                                            applyValueMode="useButtons" 
                                            onValueChanged={(e) => {
                                                setLop(Object.assign({}, dataLop, { list_id_lop: (e.value).toString() })); 
                                            }}
                                            placeholder="Chọn lớp..."
                                            displayExpr="ten_lop"
                                            valueExpr="id"
                                            stylingMode="outlined"
                                            noDataText ="Không có dữ liệu lớp để thao tác"
                                            />


                                <input name="list_id_lop" type="string"
                                    style={{ display: "none" }} defaultValue={dataLop.list_id_lop ? dataLop.list_id_lop : 0}
                                    ref={register({ validate: value => dataLop.list_id_lop? true : false })} />
                                {
                                    errors.list_id_lop &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn lớp
                          </Label>
                                }
                            </div>
                            {/* <div className="col-md-12 padding-left-2rem " style={{ paddingTop: 25 }}>
                                <div className="dx-field-label">Tiêu chí phân lớp</div>
                                <br></br>
                                <SelectBox dataSource={tieuChiSource}
                                    placeholder="Chọn tiêu chí"
                                    displayExpr="Name"
                                    valueExpr="Id"
                                    onValueChanged={(e) => {
                                        setLop(Object.assign({}, dataLop, { sort: e.value })); trigger("dm_he_id")
                                    }}
                                />
                                <input name="sort" type="number"
                                    style={{ display: "none" }} defaultValue={dataLop.sort ? dataLop.sort : 0}
                                    ref={register({ validate: value => dataLop.sort > 0 })} />
                                {
                                    errors.sort &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn tiêu chí
                                    </Label>
                                }
                            </div> */}
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                               Lưu lại
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
