import React, { useReducer, useEffect, useState, useRef, useContext } from "react";
import { Transition, Icon, Confirm } from 'semantic-ui-react'
import { select_all, remove } from "../../api/lop_api";
import { select_namhoc,select_truong,select_khoi,select_he, } from "../../api/common_api_combo";
import { Column, Lookup } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
// import DataGrid from "../../components/datagrid/datagrid";
import DetailModal from './detail_modal';
import { StoreContext } from "../../contexts/store";
  import DataGrid, {
    Sorting, Pager, Paging, Selection, FilterRow,
    HeaderFilter,
    FilterPanel
} from 'devextreme-react/data-grid';

const ini_state = {
    lops: [],
    selected_lop: {},
}
const action_type = {
    SET_LOP: "SET_LOP",
    SET_SELECTED_LOP: "SET_SELECTED_LOP"
}
export default () => {

    const [is_loading, set_is_loading] = useState(false);
    const [is_show_modal, set_is_show_modal] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
    const { set_notification, can_accessed } = useContext(StoreContext);
    const [dataSelect, setDataSelect] = React.useState({});
    const [dataXoaAll, setDataXoaAll] = React.useState(null);
    const grid = useRef(null);
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.SET_LOP:
                return {
                    ...state,
                   lops: action.payload,
                };
            case action_type.SET_SELECTED_LOP:
                return {
                    ...state,
                    selected_lop: action.payload,
                };
            default:
                return state
        }
    }, ini_state);
    useEffect(() => {
        reload();
    }, []);
    useEffect(() => {
        if (is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [is_loading]);
    const reload = async () => {
        if (can_accessed('api/lop/select_all')) {
            set_is_loading(true);
            const res = await select_all();
            set_is_loading(false);
            if (res.is_success) {
                dispatch({ type: action_type.SET_LOP, payload: res.data });
            }
        }
        // Promise.all([
        //     await select_namhoc(),
        //     await select_truong(null),
        //     await select_khoi(null),
        //     await select_he(null)
        //   ])
        //     .then(([namhocs, truongs, khois, hes]) => {
        //         setDataSelect(
        //             Object.assign({}, dataSelect,
        //             {
        //                 namhoc: namhocs,
        //                 truong: truongs,
        //                 khoi: khois,
        //                 he: hes
        //             })
        //         );
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     });
     

        

        const namhocs = await reloadNamHoc();
        const truongs = await reloadTruong();
        const khois = await reloadKhoi();
        const hes = await reloadHe();
        setDataSelect(
          Object.assign({}, dataSelect,
            {
              namhoc: namhocs,
              truong: truongs,
              khoi: khois,
              he: hes
            })

        );
  }
  const reloadNamHoc = async () => {
    const res = await select_namhoc();
    if (res.is_success) {
      return res.data;
    } else {
      set_notification({ text: "Không load năm học", type: "error" })
      return [];
    }
  }
  const reloadTruong = async () => {
    const res = await select_truong();
    if (res.is_success) {
      return res.data;
    } else {
      set_notification({ text: "Không load trường học", type: "error" })
      return [];
    }
  }
  const reloadKhoi = async () => {
    const res = await select_khoi();
    if (res.is_success) {
      return res.data;
    } else {
      set_notification({ text: "Không load khối", type: "error" })
      return [];
    }
  }
  const reloadHe = async () => {
    const res = await select_he();
    if (res.is_success) {
      return res.data;
    } else {
      set_notification({ text: "Không load hệ", type: "error" })
      return [];
    }
  }

    const btnEdit_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_LOP, payload: data });
        set_is_show_modal(true);
    }
    const btnDelete_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_LOP, payload: data });
        set_is_show_confirm_delete(true);
    }
    const confirmDelete = async () => {
        debugger
        const res = await remove(state.selected_lop.id);
        if (res.is_success) {
            reload();
            set_is_show_confirm_delete(false);
        } else {
            set_notification({ text: res.message, type: "error" });
           
        }
    }
    const btnDell_All = (data) => {
      
        let result = data.selectedRowsData.map(a => a.id);
        setDataXoaAll(result.toString());
    }

    const confirmDeleteAll = async () => {
        debugger
        const res = await remove(dataXoaAll);
        if (res.is_success) {
            reload();
            set_is_show_confirm_delete(false);
        } else {
            set_notification({ text: "res.message", type: "error" });          
        }
    }
    const btnDeleteAll_click = () => {
        if (dataXoaAll == null || dataXoaAll == undefined) {
                 
            set_notification({ text:"Vui lòng chọn học sinh cần xóa!", type: "warning" });
            return false;
        }
        else {
            set_is_show_confirm_delete(true);
        }
        
    }
    const cellRender = (data) => {
        return <div>
            {can_accessed('api/lop/update') &&
                <span style={{ cursor: "pointer" }} onClick={() => { btnEdit_click(data.data) }}><i aria-hidden="true" className="icon edit"></i> </span>
            }
            {can_accessed('api/lop/delete') &&
                <span style={{ cursor: "pointer" }} onClick={() => { btnDelete_click(data.data) }}><i aria-hidden="true" className="icon trash"></i> </span>
            }
        </div>;
    }
    const column = [
        <Column key={0} cellRender={cellRender} caption="Thao tác" width={100}></Column>,
        <Column key={1} dataField="ten_lop" caption="Tên lớp"></Column>,
        <Column key={2} dataField="nam_hoc" caption="Năm học">
            <Lookup key={21} dataSource={dataSelect.namhoc} displayExpr="nam_hoc" valueExpr="nam_hoc" />
        </Column>,
         <Column key={3} dataField="dm_truong_id" caption="Trường">
            <Lookup key={31} dataSource={dataSelect.truong} displayExpr="ten_truong" valueExpr="id" />
        </Column>,
        <Column key={4} dataField="dm_khoi_id" caption="Khối">
            <Lookup key={41} dataSource={dataSelect.khoi} displayExpr="ten_khoi" valueExpr="id" />
        </Column>,
        <Column key={5} dataField="dm_he_id" caption="Hệ">
            <Lookup key={51} dataSource={dataSelect.he} displayExpr="ten_he" valueExpr="id" />
        </Column>
    ];
    return (
        <React.Fragment>
            <Transition animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}>
                <div>
                    <div className="row">
                        <div className="col-md-12 padding-top-1rem padding-left-2rem">

                            <Toolbar className="bg-background">
                                <Item location="before"
                                    render={() => {
                                        return (
                                            <h3>Danh sách lớp</h3>
                                        );
                                    }}
                                />
                                {/* <Item location="before"
                                    render={() => {
                                        return (
                                            <a    className="p-btn  p-btn-sm" onClick={() => { btnDeleteAll_click() }}   >
                                                <Icon name="cancel"></Icon>
                                        Xóa tất cả
                                            </a>
                                        );
                                    }}
                                /> */}
                                {can_accessed("api/lop/insert") &&
                                    <Item location="after"
                                        render={() => {
                                            return (
                                                <a className="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => {
                                                    set_is_show_modal(true);
                                                    dispatch({ type: action_type.SET_SELECTED_LOP, payload: { id: 0 } });
                                                }}  >
                                                    <Icon name="plus"></Icon>
                                        Thêm lớp
                                                </a>
                                            );
                                        }}
                                    />
                                }
                                 {can_accessed("api/lop/delete") &&
                                    <Item location="after"
                                    render={() => {
                                        return (
                                            <a    className="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => { btnDeleteAll_click() }}   >
                                                <Icon name="cancel"></Icon>
                                        Xóa tất cả
                                            </a>
                                        );
                                    }}
                            />
                                }
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a className="p-btn  p-btn-sm" onClick={() => { grid.current.instance.exportToExcel(); }}  >
                                                <Icon name="file excel outline"></Icon>
                                        Xuất excel
                                            </a>
                                        );
                                    }}
                                />
                            </Toolbar>
                        </div>
                        <div className="col-md-12 padding-top-5">
                            <DataGrid
                                ref={grid}
                                keyExpr="id"
                                dataSource={state.lops}
                                columnHidingEnabled={true}
                                columnAutoWidth={true}
                                showBorders={true}
                                showColumnLines={false}
                                showRowLines={false}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={true}
                                focusedRowEnabled={false}
                                height={window.innerHeight - 150}
                                onSelectionChanged={btnDell_All}
                                
                            >
                                {column}
                                <Selection mode="multiple"
                                selectAllMode="allPages"
                                ></Selection>
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={10} />
                                <Selection mode="multiple" />
                                <FilterRow visible={true} />
                                <FilterPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20, 50, 200, 500]}
                                    infoText="Trang {0} /{1} ({2} dòng)"
                                    showInfo={true} />
                            </DataGrid>

                            {/* <DataGrid columns={column}
                                dataSource={state.lops}
                                height={window.innerHeight - 150}
                                keyColumn="id"
                                grid={grid}
                            >   
                                <Selection mode="multiple"
                                selectAllMode="allPages"
                                 showCheckBoxesMode={btnDell_All}
                                ></Selection>
                            </DataGrid> */}
                        </div>
                        <DetailModal is_show_model={is_show_modal}
                            onCancel={() => { set_is_show_modal(false) }}
                            onSuccess={() => { set_is_show_modal(false); reload(); }}
                            id={state.selected_lop.id}
                            dataNamhoc={dataSelect.namhoc}
                            dataTruong={dataSelect.truong}
                            dataKhoi={dataSelect.khoi}
                            dataHe={dataSelect.he}
                        ></DetailModal>
                    </div>

                </div>
            </Transition>
            <Confirm
                open={is_show_confirm_delete}
                dimmer={"blurring"}
                header='Lưu ý'
                content='Bạn có chắc chắn muốn xóa lớp ?'
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm_delete(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { state.selected_lop.id?confirmDelete() :confirmDeleteAll()}} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Xóa lớp
                        </button>
                    );
                }}
            />
        </React.Fragment>
    );
}