import React, { useState, useEffect } from 'react';
import { TagBox } from 'devextreme-react';
import { dm_chiendich_trangthai_select_all } from '../../api/dm_chiendich_trangthai_api';

const ComboboxChienDichTrangThaiMultiple = ({
  value,
  onValueChanged,
  cmb,
  showClearButton,
}) => {
  const [dm_chiendichs, set_dm_chiendichs] = useState([]);
  useEffect(() => {
    (async () => {
      onValueChanged([]);
      const res = await dm_chiendich_trangthai_select_all();
      if (res.is_success) {
        set_dm_chiendichs(res.data);
      } else {
        set_dm_chiendichs([]);
      }
    })();
  }, []);
  return (
    <TagBox
      items={dm_chiendichs}
      showSelectionControls={true}
      searchEnabled
      displayExpr={'trang_thai'}
      valueExpr={'id'}
      value={value}
      multiline={false}
      stylingMode="outlined"
      ref={cmb}
      placeholder="Chọn trạng thái"
      width={150}
      showClearButton={showClearButton}
      onValueChanged={({ value }) => {
        onValueChanged(value);
      }}
    />
  );
};

export default ComboboxChienDichTrangThaiMultiple;
