import React, { useEffect, useState, useContext } from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/hocsinh_thoihoc";
import DropDownBox from 'devextreme-react/drop-down-box';
import { StoreContext } from "../../contexts/store";
import { select_namhoc, select_hocsinh_by_ids } from "../../api/common_api_combo";
import DataGrid, { Selection, Paging, HeaderFilter, Scrolling, Column } from 'devextreme-react/data-grid';
import TextArea from 'devextreme-react/text-area';
import SearchStudent from './hoc_sinh_search'
import DateBox from 'devextreme-react/date-box';
// import { Search} from 'semantic-ui-react'


export default ({ is_show_model, id, onSuccess, onCancel, dataTrangThai_ThoiHoc }) => {

    const { set_notification } = useContext(StoreContext)
    const [dataHocSinh, setHocSinh] = useState({});
    const [isGridBoxOpened, setGridBoxOpened] = useState(false);
    const [gridBoxValue, setGridBoxValue] = useState();
    const [selectNamHoc, setNamHoc] = useState();
    const [dataHocSinh_ThoiHoc, setHocSinh_ThoiHoc] = useState({});
    const [dataNamHoc, setdataNamHoc] = useState();

    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                if (dataHocSinh.id > 0) {
                    set_is_saving(true);
                    const res = await update(dataHocSinh);
                    set_is_saving(false);
                    if (res.is_success) {
                        set_notification({ text: "Cập nhật thành công ", type: "success" })
                        onSuccess();
                    } else {
                        set_notification({ text: res.message, type: "error" });
                    }
                } 
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    const reload = async () => {
        if (id > 0) {
            const res = await select_by_id(id);
            if (res.is_success) {
                setHocSinh(res.data)
            }
        } else {
            setHocSinh({});
            let obj1 = { id_lop: 'THOIHOC' };
            let obj2 = Object.assign({}, obj1);
            const resHS = await select_hocsinh_by_ids(obj2);
            if (resHS.is_success) {
                setHocSinh_ThoiHoc(resHS.data)
            }
        }


        const res = await select_namhoc();
        if (res.is_success) {
            setNamHoc(res.data);
            const temp = res.data.filter(x => x.is_default);
            if (temp.length > 0) {
                setdataNamHoc(temp[0].nam_hoc);
            }
        }

    }
    const handleChangeNamhoc = async (value) => {
        setHocSinh(Object.assign({}, dataHocSinh, { nam_hoc: value })); trigger("nam_hoc")
        setdataNamHoc(value)

    }

    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    {dataHocSinh.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <input className="hide" name="id" defaultValue={dataHocSinh.id}></input>
                        {dataHocSinh.id > 0 ? <input className="hide" name="ts_hocsinh_id" defaultValue={dataHocSinh.ts_hocsinh_id}></input> : <p></p>}

                        <div className="row">
                            <div className="col-md-6" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Năm học</div>
                                <br></br>

                                <SelectBox dataSource={selectNamHoc}
                                    disabled={dataHocSinh.id ? true : false}
                                    displayExpr="nam_hoc"
                                    valueExpr="nam_hoc"
                                    stylingMode="outlined"
                                    value={dataNamHoc}
                                    placeholder="Chọn năm học"
                                    onValueChanged={(e) => {
                                        handleChangeNamhoc(e.value)
                                    }}
                                />
                                <input name="nam_hoc"
                                    className="hide"
                                    defaultValue={dataNamHoc}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.nam_hoc &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn năm học
                                    </Label>
                                }
                            </div>
                            <div className="col-md-6" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Học sinh</div>
                                <br></br>

                                <TextBox value={dataHocSinh.ho_ten}
                                    visible={dataHocSinh.id ? true : false}
                                    disabled={dataHocSinh.id ? true : false}
                                    stylingMode="outlined"
                                />
                                <input name="id_hs_thoi_hoc" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.ts_hocsinh_id ? dataHocSinh.ts_hocsinh_id : 0}
                                    ref={register({ validate: value => dataHocSinh.ts_hocsinh_id > 0 })} />
                                {
                                    errors.id_hs_thoi_hoc &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn học sinh
                                    </Label>
                                }
                            </div>

                            <div className="col-md-6" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Ngày nộp đơn</div>
                                <br></br>
                                <DateBox value={dataHocSinh.ngay_nop_don}
                                    placeholder="Chọn ngày"
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    stylingMode="outlined"
                                    type="date"
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({}, dataHocSinh, { ngay_nop_don: e.value })); trigger("ngay_nop_don")
                                    }}
                                    displayFormat="dd/MM/yyyy" />
                                <input name="ngay_nop_don"
                                    className="hide"
                                    defaultValue={dataHocSinh.ngay_nop_don}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.ngay_nop_don &&
                                    <Label basic color='red' pointing>
                                        Vui chọn ngày nộp đơn
                                    </Label>
                                }
                            </div>

                            <div className="col-md-6" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Ngày chính thức</div>
                                <br></br>
                                <DateBox value={dataHocSinh.ngay_chinh_thuc}
                                    placeholder="Chọn ngày"
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({}, dataHocSinh, { ngay_chinh_thuc: e.value })); trigger("ngay_chinh_thuc")
                                    }}
                                    type="date"
                                    displayFormat="dd/MM/yyyy" />
                                <input name="ngay_chinh_thuc" type="date"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.ngay_chinh_thuc ? dataHocSinh.ngay_chinh_thuc : 0}
                                    ref={register({ validate: value => new Date(dataHocSinh.ngay_chinh_thuc) > new Date(dataHocSinh.ngay_nop_don) })} />
                                {
                                    errors.ngay_chinh_thuc &&
                                    <Label basic color='red' pointing>
                                        Ngày chính thức không được nhỏ hơn ngày nộp đơn
                                    </Label>
                                }
                            </div>
                            <div className="col-md-12 " style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Trạng thái</div>
                                <br></br>
                                <SelectBox dataSource={dataTrangThai_ThoiHoc}
                                    disabled={dataHocSinh.id ? true : false}
                                    value={dataHocSinh.dm_trangthaihocsinh_id}
                                    placeholder="Chọn trạng thái"
                                    displayExpr="trang_thai"
                                    stylingMode="outlined"
                                    valueExpr="id"
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({}, dataHocSinh, { dm_trangthaihocsinh_id: e.value })); trigger("dm_trangthaihocsinh_id")
                                    }}
                                />
                                <input name="dm_trangthaihocsinh_id" type="number"
                                    style={{ display: "none" }} defaultValue={dataHocSinh.dm_trangthaihocsinh_id ? dataHocSinh.dm_trangthaihocsinh_id : 0}
                                    ref={register({ validate: value => dataHocSinh.dm_trangthaihocsinh_id > 0 })} />
                                {
                                    errors.dm_trangthaihocsinh_id &&
                                    <Label basic color='red' pointing>
                                        Vui lòng chọn trạng thái
                                    </Label>
                                }
                            </div>
                            <div className="col-md-12" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Trường chuyển đến</div>
                                <br></br>
                                <TextBox stylingMode="outlined" value={dataHocSinh.truong_chuyen_den}
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({},
                                            dataHocSinh,
                                            { truong_chuyen_den: e.value }));
                                    }}
                                />
                            </div>

                            <div className="col-md-12" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Lý do</div>
                                <br></br>

                                <TextBox stylingMode="outlined" value={dataHocSinh.ly_do}
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({},
                                            dataHocSinh,
                                            { ly_do: e.value }));
                                        trigger("ly_do");
                                    }}
                                />
                                <input name="ly_do"
                                    className="hide"
                                    defaultValue={dataHocSinh.ly_do}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.ly_do &&
                                    <Label basic color='red' pointing>
                                        Vui lòng điền lý do
                                    </Label>
                                }
                            </div>

                            <div className="col-md-12" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Chi tiết</div>
                                <br></br>
                                <TextArea
                                    stylingMode="outlined"
                                    height={50}
                                    value={dataHocSinh.chi_tiet}
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({},
                                            dataHocSinh,
                                            { chi_tiet: e.value }));
                                    }}
                                />
                            </div>

                            <div className="col-md-12" style={{ paddingTop: 10 }}>
                                <div className="dx-field-label">Ghi chú</div>
                                <br></br>
                                <TextArea
                                    stylingMode="outlined"
                                    height={50}
                                    value={dataHocSinh.ghi_chu}
                                    onValueChanged={(e) => {
                                        setHocSinh(Object.assign({},
                                            dataHocSinh,
                                            { ghi_chu: e.value }));
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            Cập nhật
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
