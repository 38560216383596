import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function selectAll() {
  return call_post_api(appInfo.api_url + '/user/all');
}
export async function selectBy(userID) {
  return call_post_api(appInfo.api_url + '/user/detail?id=' + userID);
}
export async function remove(userID) {
  return call_post_api(appInfo.api_url + '/user/delete?id=' + userID);
}
export async function saveChanges(datas, userID) {
  const url = appInfo.api_url + '/user/' + (userID > 0 ? 'update' : 'insert');
  return call_post_api(url, Object.assign({}, datas, { id: userID }));
}
export async function search(key) {
  return call_post_api(appInfo.api_url + '/user/search', key);
}
export async function selectApproveUserList() {
  return call_post_api(appInfo.api_url + '/user/approve_user_list');
}
export async function tu_van_vien_select_all() {
  return call_post_api(appInfo.api_url + '/user/select_tu_van_vien');
}
