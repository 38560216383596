import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { Transition, Icon, Confirm } from "semantic-ui-react";
import { Column } from "devextreme-react/data-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { TextBox } from "devextreme-react/text-box";
import { Card } from "antd";
import { StoreContext } from "../../contexts/store";
import { select_all, remove } from "../../api/hocsinh_qhgd_api";
import { hoc_sinh_select_by_id } from "../../api/hocsinh_api";
// import DataGrid from "../../components/datagrid/datagrid";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
import DetailModal from "./detail_qhgd_tab";
const ini_state = {
  list_hocsinh_qhgd: [],
  selected_hocsinh_qhgd: {},
};

const action_type = {
  GET_LIST_HOCSINH_QHGD: "GET_LIST_HOCSINH_QHGD",
  SET_SELECTED_HOCSINH_QHGD: "SET_SELECTED_CO_SO",
};
export default ({ onSuccess, onCancel, id, id_insert_new_hocsinh }) => {
  const [is_loading, set_is_loading] = useState(false);
  const [hoc_sinh_info, set_hocsinh_info] = useState({});
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const grid = useRef(null);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case action_type.GET_LIST_HOCSINH_QHGD:
        return {
          ...state,
          list_hocsinh_qhgd: action.payload,
        };
      case action_type.SET_SELECTED_HOCSINH_QHGD:
        return {
          ...state,
          selected_hocsinh_qhgd: action.payload,
        };
      default:
        return state;
    }
  }, ini_state);
  useEffect(() => {
    get_hoc_sinh_info();
    reload();
  }, []);
  useEffect(() => {
    if (is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [is_loading]);
  const reload = async () => {
    set_is_loading(true);
    const res = await select_all(id > 0 ? id : id_insert_new_hocsinh);
    set_is_loading(false);
    if (res.is_success) {
      dispatch({ type: action_type.GET_LIST_HOCSINH_QHGD, payload: res.data });
    }
  };

  const get_hoc_sinh_info = async () => {
    const res = await hoc_sinh_select_by_id(
      id > 0 ? id : id_insert_new_hocsinh
    );
    if (res.is_success) {
      set_hocsinh_info(res.data);
    }
  };

  const btnEdit_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_HOCSINH_QHGD, payload: data });
    set_is_show_modal(true);
  };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_HOCSINH_QHGD, payload: data });
    set_is_show_confirm_delete(true);
  };

  const confirmDelete = async () => {
    const res = await remove(state.selected_hocsinh_qhgd.id);
    if (res.is_success) {
      reload();
      set_is_show_confirm_delete(false);
    } else {
      set_notification({ text: res.message, type: "error" });
    }
  };

  const cellRender = (data) => {
    return (
      <div>
        {can_accessed("api/hocsinh_qhgd/update") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{" "}
          </span>
        )}
        {can_accessed("api/hocsinh_qhgd/delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{" "}
          </span>
        )}
      </div>
    );
  };

  const column = [
    <Column
      key={0}
      cellRender={cellRender}
      caption="Thao tác"
      width={100}
    ></Column>,
    <Column key={1} dataField="ho_ten" caption="Họ tên" width={300}></Column>,
    <Column key={2} dataField="quan_he" caption="Quan hệ" width={100}></Column>,
    <Column key={3} dataField="noi_o" caption="Nơi ở" width={300}></Column>,
    <Column
      key={3}
      dataField="nghe_nghiep"
      caption="Nghề nghiệp"
      width={300}
    ></Column>,
    <Column
      key={3}
      dataField="noi_lam_viec"
      caption="Nơi làm việc"
      width={300}
    ></Column>,
    <Column
      key={3}
      dataField="ngay_sinh"
      caption="Ngày sinh"
      dataType="date"
      format="dd/MM/yyyy"
      width={200}
    ></Column>,
    // <Column
    //   key={3}
    //   dataField="gioi_tinh"
    //   caption="Giới tính"
    //   width={100}
    // ></Column>,
    <Column key={3} dataField="email" caption="Email" width={300}></Column>,
    <Column
      key={3}
      dataField="dien_thoai"
      caption="Điện thoại"
      width={200}
    ></Column>,

    // <Column key={3} dataField="ho_ten_hoc_sinh" caption="Học sinh"></Column>,
  ];
  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <Card title="Thông tin học sinh" size="small">
            <div className="row">
              <div className="col-md-3">
                <div className="dx-field-label">Mã hồ sơ</div>
                <br></br>
                <TextBox
                  value={hoc_sinh_info.ma_tn}
                  stylingMode="outlined"
                  disabled
                />
                <input
                  name="ma_tn"
                  className="hide"
                  defaultValue={hoc_sinh_info.ma_tn}
                  value={hoc_sinh_info.ma_tn}
                ></input>
              </div>
              <div className="col-md-3">
                <div className="dx-field-label">Họ tên học sinh</div>
                <br></br>
                <TextBox
                  value={hoc_sinh_info.ho_ten}
                  stylingMode="outlined"
                  disabled
                />
                <input
                  name="ho_ten"
                  className="hide"
                  defaultValue={hoc_sinh_info.ho_ten}
                  value={hoc_sinh_info.ho_ten}
                ></input>
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-md-12 padding-top-1rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  render={() => {
                    return <h3>Thông tin quan hệ gia đình</h3>;
                  }}
                />
                {can_accessed("api/hocsinh_qhgd/insert") && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <a
                          className="p-btn p-prim-col  p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal(true);
                            dispatch({
                              type: action_type.SET_SELECTED_HOCSINH_QHGD,
                              payload: { id: 0 },
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          Thêm mới
                        </a>
                      );
                    }}
                  />
                )}
                {/* <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn  p-btn-sm"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      >
                        <Icon name="file excel outline"></Icon>
                        Xuất excel
                      </a>
                    );
                  }}
                /> */}
              </Toolbar>
            </div>
            <div className="col-md-12">
              {/* <DataGrid
                key="hocsinh_qhgd_id"
                dataSource={state.list_hocsinh_qhgd}
                height={window.innerHeight - 500}
                columns={column}
                grid={grid}
              ></DataGrid> */}
              <DataGrid
                ref={grid}
                keyExpr={"id"}
                dataSource={state.list_hocsinh_qhgd}
                columnHidingEnabled={false}
                //defaultColumns={columns}
                columnAutoWidth={false}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={"nextColumn"}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={window.innerHeight - 500}
                className={"table-noborder-left"}
              >
                <Sorting mode="multiple" />
                <Paging defaultPageSize={10} />
                <Selection mode="single"></Selection>
                {/* <Selection mode="multiple" /> */}
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                {/* <Scrolling mode="infinite" /> */}
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                {column}
              </DataGrid>
            </div>
            <DetailModal
              is_show_model={is_show_modal}
              onCancel={() => {
                set_is_show_modal(false);
              }}
              onSuccess={() => {
                set_is_show_modal(false);
                reload();
              }}
              id={state.selected_hocsinh_qhgd.id}
              hocsinh_id ={ id > 0 ? id : id_insert_new_hocsinh}
            ></DetailModal>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <button onClick={() => onCancel()} className="p-btn p-btn-sm">
              Đóng
            </button>
          </div>
        </div>
      </Transition>
      <Confirm
        open={is_show_confirm_delete}
        // dimmer={"blurring"}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn xóa thông tin gia đình học sinh ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_delete(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                confirmDelete();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Xóa
            </button>
          );
        }}
      />
    </React.Fragment>
  );
};
