import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function select_all() {
    return call_post_api(appInfo.api_url + '/lop/select_all');
};
export async function select_by_id(id) {
    return call_post_api(appInfo.api_url + '/lop/select_by_id', id);
};
export async function insert(data) {
    return call_post_api(appInfo.api_url + '/lop/insert', data);
}

export async function update(data) {
    return call_post_api(appInfo.api_url + '/lop/update', data);
}

export async function remove(id) {
    return call_post_api(appInfo.api_url + '/lop/delete', id);
}

export async function tu_dong_phan_lop(data) {
    return call_post_api(appInfo.api_url + '/lop/automaticclassifier', data);
}
export async function huy_phan_lop(data) {
    return call_post_api(appInfo.api_url + '/lop/xoaphanlop', data);
}
export async function select_lop_treeview(nam_hoc) {
    return call_post_api(appInfo.api_url + '/dm_lop/select_lop_treeview?nam_hoc=' + nam_hoc);
}


