import React, { useReducer, useEffect, useState, useRef, useContext } from "react";
import { Transition, Icon, Confirm } from 'semantic-ui-react'
import { remove } from "../../api/lop_api";
import { select_namhoc, select_khoi, select_he, select_coso, select_hocsinh_by_ids } from "../../api/common_api_combo";
import { select_all as select_all_truong } from "../../api/dm_truong_api";
import { Column, Lookup } from 'devextreme-react/data-grid';
import { SelectBox } from 'devextreme-react/select-box';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid, {
    Sorting, Pager, Paging, Selection,
    HeaderFilter, FilterRow,
    SearchPanel
} from 'devextreme-react/data-grid';
import { StoreContext } from "../../contexts/store";
import DetailModal from './detail_modal';
import { Button } from 'devextreme-react/button';
import HuyPhanLopModal from './huylop_modal';

const ini_state = {
    phanLops: [],
    selected_lop: {},
}
const action_type = {
    SET_LOP: "SET_LOP",
    SET_SELECTED_LOP: "SET_SELECTED_LOP"
}
const gioiTinhDataSource = [
    { 'Name': 'Nam', 'Id': 1 },
    { 'Name': 'Nữ', 'Id': 2 },
];

export default () => {

    const { set_notification, can_accessed } = useContext(StoreContext)
    const [dataPhanLop, setPhanLop] = useState({});
    const [selectCoSo, setCoSo] = useState({});
    const [selectTruong, setTruong] = useState({});
    const [selectKhoi, setKhoi] = useState();
    const [selectHe, setHe] = useState();
    const [selectNamHoc, setNamHoc] = useState();
    const [totalHocSinh, setCount] = useState();
    const [is_checklop, set_is_checklop] = useState(true);
    const [is_loading, set_is_loading] = useState(false);
    const [is_show_modal, set_is_show_modal] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
    const [is_show_huy_lop_modal, set_is_show_huy_lop_modal] = useState(false);
    const [dataCacheLop, setCacheLop] = useState();
    const [dataNamHoc, setdataNamHoc] = useState();
    const [dataAll, setDataAll] = React.useState(null);

    const grid = useRef(null);
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.SET_LOP:
                return {
                    ...state,
                    phanLops: action.payload,
                };
            case action_type.SET_SELECTED_LOP:
                return {
                    ...state,
                    selected_lop: action.payload,
                };
            default:
                return state
        }
    }, ini_state);
    useEffect(() => {
        reload();
    }, []);
    useEffect(() => {
        if (is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [is_loading]);
    useEffect(() => {
        reloadTruong();
    }, []);
    useEffect(() => {
        if (dataPhanLop) {
            setPhanLop(Object.assign({}, dataPhanLop, { id_khoi: 0 }));
        }
    }, [dataPhanLop.id_truong]);
    useEffect(() => {
        if (dataPhanLop) {
            setPhanLop(Object.assign({}, dataPhanLop, { id_he: 0 }));
        }
    }, [dataPhanLop.id_khoi]);
    //useEffect(() => {
    //    if (dataPhanLop.id_he > 0) {
    //        getHocSinh();
    //    }
    //}, [dataPhanLop.id_he]);
    const reload = async () => {
        if (can_accessed('api/lop/select_lop_treeview')) {
            if (dataCacheLop != null || dataCacheLop != undefined) {

                const res = await select_hocsinh_by_ids(dataCacheLop);
                let temp = res.data.length;
                //setCount(temp)
                if (res.is_success) {
                    dispatch({ type: action_type.SET_LOP, payload: res.data });
                }
            }
            

            const res = await select_namhoc();
            if (res.is_success) {
                setNamHoc(res.data);
                const temp = res.data.filter(x => x.is_default);
                if (temp.length > 0) {
                    setdataNamHoc(temp[0].nam_hoc);
                    setPhanLop(Object.assign({}, dataPhanLop, { nam_hoc: temp[0].nam_hoc }));
                }
            }

            const resCoSo = await select_coso(1);
            if (resCoSo.is_success) {
                setCoSo(resCoSo.data)
            }
        }

    }

    const reloadTruong = async () => {

        const res = await select_all_truong();
        if (res.is_success) {
            //return res.data;
            setTruong(res.data);
        } else {
            setTruong([]);
        }
    }


    const reloadKhoi = async (id_truong) => {
        const res = await select_khoi(id_truong);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeTruong = async (id_truong) => {
        setPhanLop(Object.assign({}, dataPhanLop, { id_truong: id_truong }));
        const khoi = await reloadKhoi(id_truong);
        setKhoi(khoi)

    }
    const reloadHe = async (id_khoi) => {

        const res = await select_he(id_khoi);
        if (res.is_success) {
            return res.data;
        } else {
            return [];
        }
    }
    const handleChangeKhoi = async (id_khoi) => {
        setPhanLop(Object.assign({}, dataPhanLop, { id_khoi: id_khoi }));
        const khoi = await reloadHe(id_khoi);
        setHe(khoi)
    }
    const handleChangeHe = async (id_he) => {

        setPhanLop(Object.assign({}, dataPhanLop, { id_he: id_he }));
        let obj = {
            nam_hoc: dataNamHoc, id_he: id_he, id_truong: dataPhanLop.id_truong,
            id_khoi: dataPhanLop.id_khoi
        };
        if (obj) {
            setCacheLop(obj);
            const res = await select_hocsinh_by_ids(obj);
            let temp = res.data.length;
            //setCount(temp);
            set_is_checklop(false);
            if (res.is_success) {
                dispatch({ type: action_type.SET_LOP, payload: res.data });
            }
        }
    }
    const getHocSinh = async () => {
        if (dataPhanLop) {
            setCacheLop(dataPhanLop);
            const res = await select_hocsinh_by_ids(dataPhanLop);
            let temp = res.data.length;
            //setCount(temp);
            set_is_checklop(false);
            if (res.is_success) {
                dispatch({ type: action_type.SET_LOP, payload: res.data });
            }
        }


    }
    const btnData_All = (data) => {

        let result = data.selectedRowsData.map(a => a.id_hocsinh);
        setCount(result.length);
        setDataAll(result.toString());
    }
    const handleChangeNamhoc = async (value) => {
        setdataNamHoc(value);
        setPhanLop(Object.assign({}, dataPhanLop, { nam_hoc: dataNamHoc }));

    }
    const column = [
        <Column key={0} dataField="ten_trangthai_hs" caption="Trạng thái"></Column>,
        <Column key={1} dataField="ma_hs" caption="Mã học sinh"></Column>,
        <Column key={2} dataField="ho_ten" caption="Họ tên"></Column>,
        //<Column key={3} dataField="ten_hocsinh" caption="Tên học sinh"></Column>,
        <Column key={3} dataField="ngay_sinh" dataType="date" format="dd/MM/yyyy" caption="Ngày sinh"></Column>,
        <Column key={4} dataField="dm_gioitinh_id" caption="Giới tính">
            <Lookup key={41} dataSource={gioiTinhDataSource} displayExpr="Name" valueExpr="Id" />
        </Column>
    ];
    return (
        <React.Fragment>
            <Transition animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}>
                <div>
                    <div className="row">
                        <div className="col-md-12 padding-top-1rem padding-left-2rem">

                            <Toolbar className="bg-background">
                                {/* <Item location="before"
                                    render={() => {
                                        return (
                                            <h3>Phân lớp tự động</h3>
                                        );
                                    }}
                                /> */}
                                <Item
                                    location="before"
                                    render={() => {
                                        return (
                                            <React.Fragment>
                                                <div className="dx-field-label">Năm học</div>
                                                <br></br>
                                                <SelectBox dataSource={selectNamHoc}
                                                    displayExpr="nam_hoc"
                                                    valueExpr="nam_hoc"
                                                    placeholder="Chọn năm học"
                                                    noDataText="Không có dữ liệu"
                                                    value={dataNamHoc}
                                                    stylingMode="outlined"
                                                    onValueChanged={(e) => {
                                                        handleChangeNamhoc(e.value)
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                    }}
                                />
                                <Item
                                    location="before"
                                    render={() => {
                                        return (
                                            <div style={{ minWidth: 250 }}>
                                                <div className="dx-field-label">Trường học</div>
                                                <br></br>
                                                <SelectBox dataSource={selectTruong}
                                                    placeholder="Chọn trường học"
                                                    noDataText="Vui lòng chọn cơ sở trước"
                                                    displayExpr="ten_truong"
                                                    valueExpr="id"
                                                    stylingMode="outlined"
                                                    width="100%"
                                                    value={dataPhanLop ? dataPhanLop.id_truong : 0}
                                                    onValueChanged={(e) => {
                                                        handleChangeTruong(e.value)
                                                    }}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                <Item
                                    location="before"
                                    render={() => {
                                        return (
                                            <React.Fragment>
                                                <div className="dx-field-label">Chọn khối</div>
                                                <br></br>
                                                <SelectBox dataSource={selectKhoi}
                                                    noDataText="Vui lòng chọn trường học trước"
                                                    placeholder="Chọn khối"
                                                    displayExpr="ten_khoi"
                                                    valueExpr="id"
                                                    stylingMode="outlined"
                                                    value={dataPhanLop ? dataPhanLop.id_khoi : 0}
                                                    onValueChanged={(e) => {
                                                        handleChangeKhoi(e.value)
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                    }}
                                />
                                <Item
                                    location="before"
                                    render={() => {
                                        return (
                                            <div style={{ minWidth: 150 }}>
                                                <div className="dx-field-label">Chọn hệ</div>
                                                <br></br>
                                                <SelectBox dataSource={selectHe}
                                                    noDataText="Vui lòng chọn khối học trước"
                                                    placeholder="Chọn hệ học"
                                                    displayExpr="ten_he"
                                                    valueExpr="id"
                                                    stylingMode="outlined"
                                                    width="100%"
                                                    value={dataPhanLop ? dataPhanLop.id_he : 0}
                                                    onValueChanged={(e) => {
                                                        handleChangeHe(e.value)
                                                    }}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {can_accessed("api/lop/automaticclassifier") &&
                                    <Item location="after"
                                        render={() => {
                                            return (
                                                <a disabled={(dataAll == null || dataAll == "")} className="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => { set_is_show_modal(true) }}   >
                                                    <Icon name="plus"></Icon>
                                                     Phân lớp {(dataAll != null && dataAll != "") ? "(" + dataAll.split(',').length + ")" : ""}
                                                </a>
                                            );
                                        }}
                                    />
                                }
                                {/*<Item location="after"*/}
                                {/*    render={() => {*/}
                                {/*        return (*/}
                                {/*            <a className="p-btn p-btn-sm" onClick={() => {*/}
                                {/*                set_is_show_huy_lop_modal(true);*/}
                                {/*                dispatch({ type: action_type.SET_SELECTED_LOP, payload: { id: 1 } });*/}
                                {/*            }}  >*/}
                                {/*                <Icon name="cancel"></Icon>*/}
                                {/*                Hủy phân lớp*/}
                                {/*            </a>*/}
                                {/*        );*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </Toolbar>
                        </div>



                        {/* <div className="col-md-2" style={{ paddingTop: 20 }}>
                            <Button disabled={is_check} onClick={getHocSinh} text="Danh sách học sinh" type="success" />
                        </div> */}

                    </div>
                    <DataGrid
                        noDataText="Không có dữ liệu học sinh"
                        ref={grid}
                        keyExpr="id_hocsinh"
                        dataSource={state.phanLops}
                        columnHidingEnabled={true}
                        columnAutoWidth={true}
                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={false}
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        columnResizingMode={"nextColumn"}
                        hoverStateEnabled={true}
                        focusedRowEnabled={false}
                        height={window.innerHeight - 120}
                        onSelectionChanged={btnData_All}

                    >
                        {column}
                        <Selection mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="always"
                        ></Selection>
                        <Sorting mode="multiple" />
                        <Paging defaultPageSize={50} />
                        <FilterRow visible={true} showOperationChooser={true} resetOperationText={false} operationDescriptions={'contains'} />
                        <HeaderFilter visible={true} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50, 200, 500]}
                            infoText="Trang {0} /{1} ({2} dòng)"
                            showInfo={true} />
                    </DataGrid>

                    {/*<DataGrid columns={column}*/}
                    {/*    dataSource={state.phanLops}*/}
                    {/*    height={window.innerHeight - 150}*/}
                    {/*    keyColumn="id_hocsinh"*/}
                    {/*    grid={grid}*/}
                    {/*/>*/}
                    <DetailModal is_show_model={is_show_modal}
                        onCancel={() => { set_is_show_modal(false) }}
                        onSuccess={() => { set_is_show_modal(false); reload(); }}
                        dataPhanLop={dataPhanLop}
                        dataNamHoc={dataNamHoc}
                        isCheck={state.selected_lop.id}
                        totalHocSinh={totalHocSinh}
                        dataHocSinh={dataAll}
                    ></DetailModal>
                    {/*<HuyPhanLopModal is_show_model={is_show_huy_lop_modal}*/}
                    {/*    onCancel={() => { set_is_show_huy_lop_modal(false) }}*/}
                    {/*    onSuccess={() => { set_is_show_huy_lop_modal(false); reload(); }}*/}
                    {/*></HuyPhanLopModal>*/}
                </div>

            </Transition>
        </React.Fragment >
    );
}