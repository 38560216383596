import React, { useEffect, useState } from 'react';
import { Label, Modal, Loader } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/loai_giay_to";
import { Switch } from 'devextreme-react/switch';
export default ({ is_show_model, id, onSuccess, onCancel }) => {
    const [loai_giay_to, set_loai_giay_to] = useState({});
    const [is_saving, set_is_saving] = useState(false);
    const { register, trigger, errors } = useForm();
    const handleSaveChanges = async datas => {
        trigger().then(async (isValid) => {
            if (isValid) {
                if (loai_giay_to.id > 0) {
                    set_is_saving(true);
                    const res = await update(loai_giay_to);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    } else {
                        // notify(res.message, 'error', 1000);
                    }
                } else {
                    set_is_saving(true);
                    const res = await insert(loai_giay_to);
                    set_is_saving(false);
                    if (res.is_success) {
                        onSuccess();
                    } else {
                        // notify(res.message, 'error', 1000);
                    }
                }
            }
        })
    }
    useEffect(() => {
        if (is_show_model) {
            reload();
        }
    }, [id, is_show_model]);
    const reload = async () => {
        if (id > 0) {
            const res = await select_by_id(id);
            if (res.is_success) {
                set_loai_giay_to(res.data)
            }
        } else {
            set_loai_giay_to({});
        }
    }
    return (
        <div>
            <Modal open={is_show_model} size="mini" dimmer={"blurring"} onClose={onCancel}
                closeOnEscape={false}
                closeOnDimmerClick={false}>
                <Modal.Header>
                    {loai_giay_to.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới</p>}
                </Modal.Header>
                <Modal.Content>
                    <form className="ui">
                        <input className="hide" name="id" defaultValue={loai_giay_to.id}></input>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dx-field-label">Tên giấy tờ</div>
                                <br></br>
                                <TextBox value={loai_giay_to.loai_giay_to}
                                    stylingMode="outlined"
                                    onValueChanged={(e) => {
                                        set_loai_giay_to(Object.assign({},
                                            loai_giay_to,
                                            { loai_giay_to: e.value }));
                                        trigger("loai_giay_to");
                                    }}
                                />
                                <input name="loai_giay_to"
                                    className="hide"
                                    defaultValue={loai_giay_to.loai_giay_to}
                                    ref={register({ required: true })}></input>
                                {
                                    errors.loai_giay_to &&
                                    <Label basic color='red' pointing>
                                        Vui lòng điền loại giấy tờ
                          </Label>
                                }
                            </div>
                            
                            <div className="col-md-12 padding-top-1rem">
                                <div className="dx-field-label">Áp dụng</div>
                                <br></br>
                                <Switch value={loai_giay_to.is_active} onValueChanged={(e) => {
                                    set_loai_giay_to(Object.assign({}, loai_giay_to, { is_active: e.value }));
                                }} />
                                <input name="is_active"
                                    className="hide"
                                    defaultValue={loai_giay_to.is_active}></input>
                            </div>
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>

                    <div>
                        <button onClick={onCancel} className="p-btn p-btn-sm">
                            Đóng
</button>
                        <button disabled={is_saving} onClick={handleSaveChanges} className="p-btn p-prim-col p-btn-sm margin-left-10">
                            {id > 0 ? "Cập nhật" : "Thêm mới"}
                            {is_saving && <Loader active inline size="mini" />}
                        </button>

                    </div>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
