import React from "react";
import { Confirm } from "semantic-ui-react";

const DeleteModal = ({is_show_confirm_delete, set_is_show_confirm_delete, confirmDelete}) => {
  return (
    <Confirm
      open={is_show_confirm_delete}
      // dimmer={"blurring"}
      header="Lưu ý"
      content="Bạn có chắc chắn muốn xóa thông tin này ?"
      size="mini"
      cancelButton={() => {
        return (
          <button
            onClick={() => {
              set_is_show_confirm_delete(false);
            }}
            className="p-btn p-btn-sm"
          >
            Đóng
          </button>
        );
      }}
      confirmButton={() => {
        return (
          <button
            onClick={() => {
              confirmDelete();
            }}
            className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
          >
            Xóa
          </button>
        );
      }}
    />
  );
};

export default DeleteModal;
