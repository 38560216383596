import React, { useEffect, useState, useContext } from "react";
import { Label, Modal, Loader } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { useForm } from "react-hook-form";
import { StoreContext } from "../../contexts/store";
import {
  chamsockhachhang_select_by_id,
  chamsockhachhang_insert,
  chamsockhachhang_update,
  chamsockhachhang_get_info_by_phone,
} from "../../api/chamsockhachhang_api";
export default ({ is_show_model, id, onSuccess, onCancel }) => {
  const [cskh, set_cskh] = useState({});
  const [list_hocsinh_by_phone, set_list_hocsinh_by_phone] = useState([]);
  const [is_saving, set_is_saving] = useState(false);
  const { register, trigger, errors, clearErrors } = useForm();
  const { set_notification, can_accessed } = useContext(StoreContext);
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (cskh.id > 0) {
          set_is_saving(true);
          const res = await chamsockhachhang_update(cskh);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await chamsockhachhang_insert(Object.assign(cskh));
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            set_notification({ text: res.message, type: "error" });
          }
        }
      }
    });
  };

  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    debugger;
    if (id > 0) {
      const res = await chamsockhachhang_select_by_id(id);
      if (res.is_success) {
        await getInFoByPhone(res?.data?.dien_thoai);
        set_cskh(res.data);
      }
    } else {
      set_cskh({});
    }
  };

  const onCancelModal = () => {
    onCancel();
    set_cskh({});
    set_list_hocsinh_by_phone([]);
  };

  const getInFoByPhone = async (phone) => {
    const res = await chamsockhachhang_get_info_by_phone(phone);
    if (res.is_success) {
      set_list_hocsinh_by_phone(res.data);
    }
  };

  const onChangeSDT = async (phone) => {
    if (id > 0) {
      set_cskh(
        Object.assign({}, cskh, {
          dien_thoai: phone,
        })
      );
    } else {
      const res = await chamsockhachhang_get_info_by_phone(phone);
      if (res.is_success) {
        set_list_hocsinh_by_phone(res.data);
        set_cskh(
          Object.assign({}, cskh, {
            dien_thoai: phone,
            ten_khach_hang: res?.data[0]?.ho_ten_lien_he,
          })
        );
      }
    }
  };

  return (
    <div>
      <Modal
        open={is_show_model}
        size="mini"
        // dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {cskh.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={cskh.id}></input>
            <div className="row">
              <div className="col-md-12">
                <div className="dx-field-label">*Điện thoại</div>
                <br></br>
                <TextBox
                  defaultValue={cskh.dien_thoai}
                  value={cskh?.dien_thoai}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_cskh(
                      Object.assign({}, cskh, {
                        dien_thoai: e.value,
                      })
                    );
                    trigger("dien_thoai");
                    onChangeSDT(e.value);
                  }}
                />
                <input
                  name="dien_thoai"
                  className="hide"
                  defaultValue={cskh.dien_thoai}
                  value={cskh.dien_thoai}
                  ref={register({
                    required: true,
                    pattern:
                      /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
                  })}
                ></input>
                {errors.dien_thoai && (
                  <Label basic color="red" pointing>
                    SĐT không hợp lệ
                  </Label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Tên KH</div>
                <br></br>
                <TextBox
                  value={cskh.ten_khach_hang}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_cskh(
                      Object.assign({}, cskh, { ten_khach_hang: e.value })
                    );
                    trigger("ten_khach_hang");
                  }}
                />
                <input
                  name="ten_khach_hang"
                  className="hide"
                  defaultValue={cskh.ten_khach_hang}
                  value={cskh.ten_khach_hang}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Học sinh</div>
                <br></br>
                <SelectBox
                  placeholder="Nhập SĐT trước để chọn"
                  stylingMode="outlined"
                  defaultValue={
                    cskh?.ts_hocsinh_id > 0
                      ? cskh?.ts_hocsinh_id
                      : list_hocsinh_by_phone[0]?.id
                  }
                  value={
                    cskh.ts_hocsinh_id > 0
                      ? cskh?.ts_hocsinh_id
                      : list_hocsinh_by_phone[0]?.id
                  }
                  dataSource={list_hocsinh_by_phone}
                  displayExpr="ho_ten"
                  valueExpr="id"
                  noDataText="Không có dữ liệu"
                  // searchEnabled={true}
                  // searchExpr="ho_ten"
                  // searchMode="contains"
                  // searchTimeout={500}
                  // showClearButton={true}
                  onValueChanged={(e) => {
                    set_cskh(
                      Object.assign({}, cskh, {
                        ts_hocsinh_id: e.value,
                      })
                    );
                    trigger("ts_hocsinh_id");
                  }}
                />
                <input
                  name="ts_hocsinh_id"
                  className="hide"
                  defaultValue={cskh.ts_hocsinh_id}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">*Nội dung</div>
                <br></br>
                <TextArea
                  autoResizeEnabled={true}
                  value={cskh.noi_dung}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_cskh(Object.assign({}, cskh, { noi_dung: e.value }));
                    trigger("noi_dung");
                  }}
                />
                <input
                  name="noi_dung"
                  className="hide"
                  defaultValue={cskh.noi_dung}
                  value={cskh.noi_dung}
                  ref={register({ required: true })}
                ></input>
                {errors.noi_dung && (
                  <Label basic color="red" pointing>
                    Vui lòng điền nội dung
                  </Label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Ghi chú</div>
                <br></br>
                <TextArea
                  autoResizeEnabled={true}
                  value={cskh.ghi_chu}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_cskh(Object.assign({}, cskh, { ghi_chu: e.value }));
                    trigger("ghi_chu");
                  }}
                />
                <input
                  name="ghi_chu"
                  className="hide"
                  defaultValue={cskh.ghi_chu}
                  value={cskh.ghi_chu}
                ></input>
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={() => onCancelModal()} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? "Cập nhật" : "Thêm mới"}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
