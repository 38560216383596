import React, { useContext } from 'react';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';

import { Icon } from 'semantic-ui-react';
import { StoreContext } from '../../../contexts/store';

const ToolbarDetailCampaignDataGrid = ({ set_is_show_modal, ten_ph }) => {
  const { can_accessed } = useContext(StoreContext);

  return (
    <Toolbar className="bg-background">
      <Item
        location="before"
        render={() => {
          return <h5><b>Nội dung chăm sóc của khách hàng: {ten_ph}</b></h5>;
        }}
      />
      {can_accessed('api/chiendich_phuhuynh_chitiet/insert') && (
        <Item
          location="after"
          render={() => {
            return (
              <Button
                stylingMode="text"
                // className="p-btn p-prim-col  p-white-color p-btn-sm"
                onClick={() => {
                  set_is_show_modal(true);
                }}
                text="Thêm mới"
                icon="plus"
                type="default"
              />
            );
          }}
        />
      )}
    </Toolbar>
  );
};

export default ToolbarDetailCampaignDataGrid;
