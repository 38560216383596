import React, { useContext, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Label, Loader, Modal } from 'semantic-ui-react';

import { assign_tu_van_vien } from '../../api/ts_chiendich_phuhuynh_api';
import ComboboxTuVanVien from '../../components/editor/cmb_tu_van_vien';
import { StoreContext } from '../../contexts/store';

const AssignCSKH = ({
  selectedChienDichPhuHuynhs,
  is_show_model,
  set_is_show_model,
  onSuccess,
}) => {
  const { register, trigger, errors } = useForm();
  const [is_saving, set_is_saving] = useState(false);
  const { set_notification } = useContext(StoreContext);
  const [user_id_tuvan, setUser_id_tuvan] = useState();
  const handleSaveChanges = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        set_is_saving(true);
        const res = await assign_tu_van_vien({
          tuvanvien_id: user_id_tuvan,
          data: selectedChienDichPhuHuynhs,
        });
        set_is_saving(false);
        if (res.is_success) {
          onSuccess();
          set_notification({
            text: 'Cập nhật thông tin thành công!',
            type: 'success',
          });
        } else {
          set_notification({ text: res.message, type: 'error' });
        }
      }
    });
  };
  const onCancelModal = () => {
    set_is_show_model(false);
    setUser_id_tuvan();
  };
  return (
    <Modal
      open={is_show_model}
      size="mini"
      onClose={onCancelModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <p>Gán CSKH</p>
      </Modal.Header>
      <Modal.Content>
        <form className="ui">
          <div className="row">
            <div className="col-md-12">
              <div className="dx-field-label">Tư vấn viên</div>
              <br></br>
              <ComboboxTuVanVien
                value={user_id_tuvan}
                onValueChanged={(e) => {
                  setUser_id_tuvan(e.value);
                }}
              />
              <input
                name="user_id_tuvan"
                className="hide"
                defaultValue={user_id_tuvan}
                value={user_id_tuvan}
              ></input>
              {errors.user_id_tuvan && (
                <Label basic color="red" pointing>
                  Tư vấn viên không được để trống
                </Label>
              )}
            </div>
          </div>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <button onClick={onCancelModal} className="p-btn p-btn-sm">
            Đóng
          </button>
          <button
            disabled={is_saving}
            onClick={handleSaveChanges}
            className="p-btn p-prim-col p-btn-sm margin-left-10"
          >
            Cập nhật
            {is_saving && <Loader active inline size="mini" />}
          </button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default AssignCSKH;
