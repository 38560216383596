import appInfo from '../app-info';
import { call_post_api,call_get_api } from './api_helper';
export async function select_namhoc() {
    return call_get_api(appInfo.api_url + '/common_hethong/select_namhoc');
};
export async function select_coso(id) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_coso',id);
};
export async function select_truong(id) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_truong',id);
};
export async function select_khoi(id) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_khoi',id);
};
export async function select_he(id) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_he',id);
};
export async function select_lop(data) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_lop',data);
};
export async function select_hocsinh(id) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_hocsinh',id);
};
export async function select_by_lops(data) {
    return call_post_api(appInfo.api_url + '/ts_hocsinh/select_by_lops',data);
}
export async function select_hocsinh_by_ids(data) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_hocsinh_by_ids',data);
}
export async function select_lop_by_role_user(data) {
    return call_post_api(appInfo.api_url + '/common_hethong/select_lop_by_role_user',data);
};
export async function select_next_year() {
    return call_post_api(appInfo.api_url + '/lop/select_next_year');
};
export async function search_hs(data) {
    return call_post_api(appInfo.api_url + '/common_hethong/search_hs',data);
};