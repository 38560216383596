import appInfo from '../app-info';
import { call_post_api } from './api_helper';
export async function dm_khoi_select_by_truong(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_by_truong_id',id);
};
export async function select_by_truong(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_by_truong_id',id);
};
export async function select_all() {
    return call_post_api(appInfo.api_url + '/khoi/select_all');
};