import { SelectBox } from 'devextreme-react/select-box';
import TreeView from 'devextreme-react/tree-view';
import React, { useEffect, useState } from 'react';
import { select_namhoc as dm_namhoc_select_all } from '../../api/common_api_combo';
import { select_lop_treeview as dm_lop_select_lop_treeview } from '../../api/lop_api';
import './treeview_lop.css';
const TreeViewLop = ({ nam_hoc, onValueChanged }) => {
    const [dm_namhocs, set_dm_namhocs] = useState([]);
    const [dm_namhoc_selected, set_dm_namhoc_selected] = useState(nam_hoc);
    const [data_source, set_data_source] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await dm_namhoc_select_all();
            if (res.is_success) {
                set_dm_namhocs(res.data);
            } else {
                set_dm_namhocs([]);
            }
        })();
    }, []);
    useEffect(() => {
        if (dm_namhoc_selected) {
            (async () => {
                const res = await dm_lop_select_lop_treeview(dm_namhoc_selected);
                if (res.is_success) {
                    set_data_source(res.data);
                }
            })();
        }
    }, [dm_namhoc_selected]);

    const selectedItemChanged = async (e) => {
        const treeView = e.component;
        const selectedLop = treeView.getSelectedNodes().map((node) => node.itemData);
        const result = selectedLop.filter(x => x.level == 3).map(a => a.id);
        onValueChanged({
            nam_hoc: dm_namhoc_selected,
            dm_lop_ids: result
        })

    }
    const itemRender = (item) => {
        return (
            <span className={"node node-level-" + item.level}>
                {item.name}
            </span>
        );
    }
    return (
        <React.Fragment>

            <SelectBox dataSource={dm_namhocs}
                displayExpr="nam_hoc"
                valueExpr="nam_hoc"
                placeholder={"Chọn năm học"}
                stylingMode="outlined"
                className="treeview-combobox-namhoc"
                value={dm_namhoc_selected}
                width={150}
                onValueChanged={(e) => {
                    set_dm_namhoc_selected(e.value)
                }}
            />
            <h5 className="select-class-label"><b>{"Chọn lớp"}</b></h5>
            <TreeView
                className="treeview-lop"
                noDataText={"Không có dữ liệu"}
                // ref={treeViewRef}
                items={data_source}
                height={window.innerHeight - 200}
                itemsExpr="Children"
                keyExpr={'key'}
                displayExpr='name'
                selectionMode={'multiple'}
                showCheckBoxesMode="none"
                focusStateEnabled={false}
                // expandEvent={'click'}
                onSelectionChanged={selectedItemChanged}
                selectNodesRecursive={true}
                selectByClick={true}
                itemRender={itemRender}
                hoverStateEnabled={true}

            />
        </React.Fragment>
    );
}
export { TreeViewLop };
