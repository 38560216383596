import React from 'react';
import { Loader } from 'semantic-ui-react'
import './button.css'
const PrimaryButton = ({ disabled, onClick, text, isLoading, icon }) => {
    return (
        <button disabled={disabled || isLoading} onClick={onClick} className="p-btn p-prim-col  p-white-color p-btn-sm">
            {icon && <i className={icon}></i>}
            {text}
            {isLoading && <Loader active inline inverted size="mini" />}
        </button>
    );
}
const DefaultButton = ({ disabled, onClick, text, isLoading, icon }) => {
    return (
        <button disabled={disabled || isLoading} onClick={onClick} className="p-btn  p-btn-sm">
            {icon && <i className={icon}></i>}
            {text}
            {isLoading && <Loader active inline  size="mini" />}
        </button>
    );
}
const DangerButton = ({ disabled, onClick, text, isLoading, icon }) => {
    return (
        <button disabled={disabled || isLoading} onClick={onClick} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
            {icon && <i className={icon}></i>}
            {text}
            {isLoading && <Loader active inline inverted size="mini" />}
        </button>
    );
}
export { PrimaryButton, DefaultButton, DangerButton };