import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { dm_namhoc_select_all } from '../../api/dm_namhoc_api';

export default ({ value, onValueChanged, cmb }) => {
  const [dm_namhocs, set_dm_namhocs] = useState([]);
  const [namhoc_default, set_namhoc_default] = useState('');
  useEffect(() => {
    (async () => {
      const res = await dm_namhoc_select_all();
      if (res.is_success) {
        set_dm_namhocs(res.data);
        const temp = res.data.filter((x) => x.is_default);
        if (temp.length > 0) {
          set_namhoc_default(temp[0].nam_hoc);
          if (value === undefined) {
            onValueChanged(temp[0].nam_hoc);
          }
        }
      } else {
        set_dm_namhocs([]);
        set_namhoc_default('');
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_namhocs}
      displayExpr={'nam_hoc'}
      valueExpr={'nam_hoc'}
      placeholder="Chọn năm học"
      value={value !== undefined ? value : namhoc_default}
      stylingMode="outlined"
      width={150}
      ref={cmb}
      onValueChanged={(e) => {
        onValueChanged(e.value);
      }}
    />
  );
};
