import React, { useEffect, useState, useRef, useContext } from 'react';

import { useQuery } from 'react-query';

import { Transition } from 'semantic-ui-react';

import { StoreContext } from '../../contexts/store';
import {
  campaign_select_all,
  campaign_remove,
  campaign_remove_tu_van_vien,
} from '../../api/ts_chiendich_phuhuynh_api';
import ToolbarDataGrid from './ToolbarDataGrid';
import DeleteModal from './DeleteModal';
import CampaignsDataGrid from './CampaignsDataGrid';
import DetailModal from './DetailModal';
import AssignCSKH from './AssignCSKH';
import ImportModal from './import_modal/import_modal';

const Campaigns = () => {
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const [is_show_confirm_change_status, set_is_show_confirm_change_status] =
    useState(false);
  const [
    is_show_confirm_delete_tu_van_vien,
    set_is_show_confirm_delete_tu_van_vien,
  ] = useState(false);
  const [list_campaign_selected, set_list_campaign_selected] = useState([]);
  const [selected_campaign, set_selected_campaign] = useState({});
  const { set_notification } = useContext(StoreContext);
  const [chiendich, setChienDich] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);
  const [is_show_import_modal, set_is_show_import_modal] = useState(false);
  const [namhoc, setNamhoc] = useState();

  const grid = useRef(null);

  const {
    data: campaigns,
    isLoading: isLoadingFetchData,
    refetch,
  } = useQuery(
    ['get-all-campaign', namhoc, chiendich, trangThai, displayAll],
    () => campaign_select_all({ namhoc, chiendich, trangThai, displayAll })
  );

  useEffect(() => {
    if (isLoadingFetchData) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [isLoadingFetchData]);

  const confirmDelete = async () => {
    const res = await campaign_remove(selected_campaign.id);
    if (res.is_success) {
      refetch();
      set_is_show_confirm_delete(false);
      set_notification({
        text: 'Xóa thông tin thành công',
        type: 'success',
      });
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };
  const onImportSuccess = () => {
    set_is_show_import_modal(false);
    refetch();
  };
  const btnCancelImport_click = () => {
    set_is_show_import_modal(false);
  };
  const confirmDeleteTuVanVien = async () => {
    const res = await campaign_remove_tu_van_vien(selected_campaign.id);
    if (res.is_success) {
      refetch();
      set_is_show_confirm_delete_tu_van_vien(false);
      set_notification({
        text: 'Xóa thông tin thành công',
        type: 'success',
      });
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };

  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visiblecampaign
        duration={500}
        transitionOnMountcampaign
      >
        <div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem padding-left-2rem">
              <ToolbarDataGrid
                set_is_show_confirm_change_status={
                  set_is_show_confirm_change_status
                }
                grid={grid}
                set_is_show_modal={set_is_show_modal}
                list_campaign_selected={list_campaign_selected}
                chiendich={chiendich}
                setChienDich={setChienDich}
                namhoc={namhoc}
                setNamhoc={setNamhoc}
                trangThai={trangThai}
                setTrangThai={setTrangThai}
                displayAll={displayAll}
                setDisplayAll={setDisplayAll}
                set_is_show_import_modal={set_is_show_import_modal}
                set_selected_campaign={set_selected_campaign}
              />
            </div>
            <div className="col-md-12">
              <CampaignsDataGrid
                grid={grid}
                campaigns={campaigns?.data}
                list_campaign_selected={list_campaign_selected}
                set_list_campaign_selected={set_list_campaign_selected}
                set_selected_campaign={set_selected_campaign}
                set_is_show_modal={set_is_show_modal}
                set_is_show_confirm_delete={set_is_show_confirm_delete}
                set_is_show_confirm_delete_tu_van_vien={
                  set_is_show_confirm_delete_tu_van_vien
                }
              />
            </div>
          </div>
        </div>
      </Transition>

      <DetailModal
        is_show_model={is_show_modal}
        onCancel={() => {
          set_is_show_modal(false);
        }}
        onSuccess={() => {
          set_is_show_modal(false);
          refetch();
        }}
        id={selected_campaign?.id}
        namhoc={namhoc}
      />
      <DeleteModal
        is_show_confirm_delete={is_show_confirm_delete}
        set_is_show_confirm_delete={set_is_show_confirm_delete}
        confirmDelete={confirmDelete}
      />
      <DeleteModal
        is_show_confirm_delete={is_show_confirm_delete_tu_van_vien}
        set_is_show_confirm_delete={set_is_show_confirm_delete_tu_van_vien}
        confirmDelete={confirmDeleteTuVanVien}
      />
      <AssignCSKH
        selectedChienDichPhuHuynhs={list_campaign_selected}
        is_show_model={is_show_confirm_change_status}
        set_is_show_model={set_is_show_confirm_change_status}
        onSuccess={() => {
          set_is_show_confirm_change_status(false);
          refetch();
        }}
      />
      {is_show_import_modal && (
        <ImportModal
          onCancel={btnCancelImport_click}
          onSuccess={onImportSuccess}
          namhoc={namhoc}
        />
      )}
    </React.Fragment>
  );
};
export default Campaigns;
