import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { dm_chiendich_trangthai_select_all } from '../../api/dm_chiendich_trangthai_api';
const ComboboxChienDichTrangThai = ({
  value,
  onValueChanged,
  cmb,
  showClearButton,
  is_readonly,
}) => {
  const [dm_chiendichs, set_dm_chiendichs] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await dm_chiendich_trangthai_select_all();
      if (res.is_success) {
        set_dm_chiendichs(res.data);
      } else {
        set_dm_chiendichs([]);
      }
    })();
  }, []);
  return (
    <SelectBox
      dataSource={dm_chiendichs}
      displayExpr={'trang_thai'}
      valueExpr={'id'}
      value={value}
      readOnly={is_readonly === true ? is_readonly : false}
      stylingMode="outlined"
      ref={cmb}
      searchEnabled
      placeholder="Chọn trạng thái"
      width="100%"
      showClearButton={showClearButton}
      onValueChanged={({ value }) => {
        onValueChanged(value);
      }}
    />
  );
};

export default ComboboxChienDichTrangThai;
