import appInfo from '../app-info';
import { call_post_api } from './api_helper';

export async function campaign_select_all({
  namhoc,
  chiendich,
  trangThai,
  displayAll,
}) {
  return call_post_api(appInfo.api_url + '/ts_chiendich_phuhuynh/select_all', {
    nam_hoc: namhoc,
    dm_chiendich_ids: chiendich,
    dm_chiendich_trangThai_ids: trangThai,
    is_display_all: displayAll,
  });
}
export async function campaign_select_by_id(id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/select_by_id',
    id
  );
}
export async function campaign_insert(data) {
  return call_post_api(appInfo.api_url + '/ts_chiendich_phuhuynh/insert', data);
}

export async function campaign_update(data) {
  return call_post_api(appInfo.api_url + '/ts_chiendich_phuhuynh/update', data);
}

export async function campaign_remove(id) {
  return call_post_api(appInfo.api_url + '/ts_chiendich_phuhuynh/delete', id);
}
export async function campaign_remove_tu_van_vien(id) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/deleteTuVanVien',
    id
  );
}

export async function campaign_change_status(data) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/change_status',
    data
  );
}
export async function assign_tu_van_vien({ tuvanvien_id, data }) {
  return call_post_api(
    appInfo.api_url +
      '/ts_chiendich_phuhuynh/assign_tu_van_vien?tuvanvien_id=' +
      tuvanvien_id,
    data
  );
}
export async function check_existed_by_phone(dien_thoai_ph) {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/check_existed_by_phone',
    dien_thoai_ph
  );
}
export const read_import_file = async (data) => {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/read_import_file',
    data
  );
};
export const import_data = async (data) => {
  return call_post_api(
    appInfo.api_url + '/ts_chiendich_phuhuynh/import_data',
    data
  );
};
