import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';

import { tu_van_vien_select_all } from '../../api/users';

const ComboboxTuVanVien = ({
  value,
  onValueChanged,
  cmb,
  dm_truong_id,
  showClearButton,
  is_readonly,
}) => {
  const [dm_tu_van_viens, set_dm_tu_van_viens] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await tu_van_vien_select_all();
      if (res.is_success) {
        set_dm_tu_van_viens(res.data);
      } else {
        set_dm_tu_van_viens([]);
      }
    })();
  }, [dm_truong_id]);
  return (
    <SelectBox
      dataSource={dm_tu_van_viens}
      displayExpr={(data) => {
        if (!data) {
          return;
        }
        const { full_name, username } = data || {};
        return `${full_name} (${username})`;
      }}
      valueExpr={'id'}
      value={value}
      readOnly={is_readonly === true ? is_readonly : false}
      stylingMode="outlined"
      ref={cmb}
      searchEnabled
      placeholder="Chọn tư vấn viên"
      width="100%"
      showClearButton={showClearButton}
      onValueChanged={(value) => {
        onValueChanged(value);
      }}
    />
  );
};

export default ComboboxTuVanVien;
