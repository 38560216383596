import React from 'react';
import { Confirm } from 'semantic-ui-react';

const ChangeStatusModal = ({
  is_show_confirm_change_status,
  set_is_show_confirm_change_status,
  handleSubmitChangeStatus,
}) => {
  return (
    <Confirm
      open={is_show_confirm_change_status}
      header="Lưu ý"
      content="Bạn có chắc chắn muốn thay đổi trạng thái xử lý?"
      size="mini"
      cancelButton={() => {
        return (
          <button
            onClick={() => {
              set_is_show_confirm_change_status(false);
            }}
            className="p-btn p-btn-sm"
          >
            Đóng
          </button>
        );
      }}
      confirmButton={() => {
        return (
          <button
            onClick={() => {
              handleSubmitChangeStatus();
            }}
            className="p-btn p-btn-sm p-prim-col margin-left-10 p-white-color p-white-color"
          >
            Lưu
          </button>
        );
      }}
    />
  );
};

export default ChangeStatusModal;
