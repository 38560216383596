import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { select_all, select_by_khoi } from '../../api/dm_he_api';
import { useAuth } from '../../contexts/auth';
export default ({ value, onValueChanged, cmb, dm_khoi_id ,showClearButton, is_readonly}) => {
    const [dm_hes, set_dm_hes] = useState([]);
    // useEffect(() => {
    //     (async () => {
    //         const res = await select_all();
    //         if (res.is_success) {
    //             set_dm_truongs(res.data);
    //         } else {
    //             set_dm_truongs([]);
    //         }
    //     })();

    // }, []);
    useEffect(() => {
        (async () => {
            let res = null;
            if (dm_khoi_id > 0) {
                res = await select_by_khoi(dm_khoi_id);
            } else {
                res = await select_all();
            }
            if (res.is_success) {
                set_dm_hes(res.data);
            } else {
                set_dm_hes([]);
            }
        })();

    }, [dm_khoi_id]);
    return (
        <SelectBox dataSource={dm_hes}
            displayExpr={"ten_he"}
            valueExpr={"id"}
            value={value}
            stylingMode="outlined"
            ref={cmb}
            readOnly={is_readonly === true ? is_readonly : false}
            placeholder="Chọn hệ"
            width="100%"
            showClearButton={showClearButton}
            onValueChanged={(e) => {
                onValueChanged(e.value);
            }} />
    );
};





