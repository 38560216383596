import React, { useEffect, useState } from "react";
import { Label, Modal, Loader } from "semantic-ui-react";
import { TextBox } from "devextreme-react/text-box";
import { useForm } from "react-hook-form";
import { select_by_id, insert, update } from "../../api/co_so_api";
import { Switch } from "devextreme-react/switch";
export default ({ is_show_model, id, onSuccess, onCancel }) => {
  const [co_so, set_co_so] = useState({});
  const [is_saving, set_is_saving] = useState(false);
  const { register, trigger, errors } = useForm();
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (co_so.id > 0) {
          set_is_saving(true);
          const res = await update(co_so);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        } else {
          set_is_saving(true);
          const res = await insert(co_so);
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
          } else {
            console.log(res.message);
          }
        }
      }
    });
  };
  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    if (id > 0) {
      const res = await select_by_id(id);
      if (res.is_success) {
        console.log(res.data);
        set_co_so(res.data);
      }
    } else {
      set_co_so({});
    }
  };
  return (
    <div>
      <Modal
        open={is_show_model}
        size="mini"
        dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {co_so.id > 0 ? <p>Cập nhật</p> : <p>Thêm mới </p>}
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={co_so.id}></input>
            <div className="row">
              <div className="col-md-12">
                <div className="dx-field-label">Mã cơ sở</div>
                <br></br>
                <TextBox
                  value={co_so.ma_co_so}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_co_so(Object.assign({}, co_so, { ma_co_so: e.value }));
                    trigger("ma_co_so");
                  }}
                />
                <input
                  name="ma_co_so"
                  className="hide"
                  defaultValue={co_so.ma_co_so}
                  ref={register({ required: true })}
                ></input>
                {errors.ma_co_so && (
                  <Label basic color="red" pointing>
                    Vui lòng điền mã cơ sở
                  </Label>
                )}
              </div>

              <div className="col-md-12">
                <div className="dx-field-label">Tên cơ sở</div>
                <br></br>
                <TextBox
                  value={co_so.ten_co_so}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_co_so(Object.assign({}, co_so, { ten_co_so: e.value }));
                    trigger("ten_co_so");
                  }}
                />
                <input
                  name="ten_co_so"
                  className="hide"
                  defaultValue={co_so.ten_co_so}
                  ref={register({ required: true })}
                ></input>
                {errors.ten_co_so && (
                  <Label basic color="red" pointing>
                    Vui lòng điền cơ sở
                  </Label>
                )}
              </div>

              <div className="col-md-12">
                <div className="dx-field-label">Tên viết tắt</div>
                <br></br>
                <TextBox
                  value={co_so.viet_tat}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    set_co_so(Object.assign({}, co_so, { viet_tat: e.value }));
                    trigger("viet_tat");
                  }}
                />
                <input
                  name="viet_tat"
                  className="hide"
                  defaultValue={co_so.viet_tat}
                  ref={register({ required: true })}
                ></input>
                {errors.viet_tat && (
                  <Label basic color="red" pointing>
                    Vui lòng điền tên viết tắt
                  </Label>
                )}
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button onClick={onCancel} className="p-btn p-btn-sm">
              Đóng
            </button>
            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              {id > 0 ? "Cập nhật" : "Thêm mới"}
              {is_saving && <Loader active inline size="mini" />}
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
