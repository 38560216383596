import React, { useEffect, useState, useContext } from 'react';
import { Label, Modal } from 'semantic-ui-react';
import { TextBox } from 'devextreme-react/text-box';
import { useForm } from 'react-hook-form';
import { StoreContext } from '../../contexts/store';
import {
  chiendich_insert,
  chiendich_select_by_id,
  chiendich_update,
} from '../../api/dm_chiendich_api';
import CbxNamHoc from '../../components/editor/cmb_namhoc';

const DetailModal = ({ is_show_model, id, onSuccess, onCancel }) => {
  const { set_notification } = useContext(StoreContext);
  const [chiendich, setChiendich] = useState({});
  const [is_saving, set_is_saving] = useState(false);
  const { register, trigger, errors } = useForm();
  const handleSaveChanges = async (datas) => {
    trigger().then(async (isValid) => {
      if (isValid) {
        if (id > 0) {
          set_is_saving(true);
          const res = await chiendich_update(chiendich);
          set_is_saving(false);
          if (res.is_success) {
            set_notification({
              text: 'Cập nhật chiến dịch thành công.',
              type: 'success',
            });
            onSuccess();
          } else {
            set_notification({ text: res.message, type: 'error' });
          }
        } else {
          set_is_saving(true);
          const res = await chiendich_insert(Object.assign(chiendich));
          set_is_saving(false);
          if (res.is_success) {
            onSuccess();
            set_notification({
              text: 'Thêm mới thông tin thành công',
              type: 'success',
            });
          } else {
            set_notification({ text: res.message, type: 'error' });
          }
        }
      }
    });
  };
  useEffect(() => {
    if (is_show_model) {
      reload();
    }
  }, [id, is_show_model]);
  const reload = async () => {
    if (id > 0) {
      const res = await chiendich_select_by_id(id);
      if (res.is_success) {
        setChiendich(res.data);
      }
    } else {
      setChiendich({});
    }
  };

  const { nam_hoc, ten_chien_dich, ngay_chien_dich, dia_diem } = chiendich;

  return (
    <div>
      <Modal
        open={is_show_model}
        size="mini"
        dimmer={'blurring'}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <p>Chiến dịch</p>
        </Modal.Header>
        <Modal.Content>
          <form className="ui">
            <input className="hide" name="id" defaultValue={id}></input>

            <div className="row">
              <div className="col-md-12">
                <div className="dx-field-label">Năm học</div>
                <br></br>
                <CbxNamHoc
                  value={nam_hoc}
                  onValueChanged={(value) => {
                    setChiendich(
                      Object.assign({}, chiendich, {
                        nam_hoc: value,
                      })
                    );
                  }}
                />
                <input
                  name="nam_hoc"
                  className="hide"
                  defaultValue={nam_hoc}
                  ref={register({ required: true })}
                ></input>
                {errors.nam_hoc && (
                  <Label basic color="red" pointing>
                    Vui lòng chọn năm học
                  </Label>
                )}
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Tên chiến dịch</div>
                <br></br>
                <TextBox
                  value={ten_chien_dich}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    setChiendich(
                      Object.assign({}, chiendich, { ten_chien_dich: e.value })
                    );
                  }}
                />
                <input
                  name="ten_chien_dich"
                  className="hide"
                  defaultValue={ten_chien_dich}
                  value={ten_chien_dich}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.ten_chien_dich && (
                  <Label basic color="red" pointing>
                    Tên chiến dịch không được để trống
                  </Label>
                )}
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Ngày chiến dịch</div>
                <br></br>
                <TextBox
                  value={ngay_chien_dich}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    setChiendich(
                      Object.assign({}, chiendich, { ngay_chien_dich: e.value })
                    );
                  }}
                />
                <input
                  name="ngay_chien_dich"
                  className="hide"
                  defaultValue={ngay_chien_dich}
                  value={ngay_chien_dich}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.ngay_chien_dich && (
                  <Label basic color="red" pointing>
                    Ngày chiến dịch không được để trống
                  </Label>
                )}
              </div>
              <div className="col-md-12 padding-top-1rem">
                <div className="dx-field-label">Địa điểm</div>
                <br></br>
                <TextBox
                  value={dia_diem}
                  stylingMode="outlined"
                  onValueChanged={(e) => {
                    setChiendich(
                      Object.assign({}, chiendich, { dia_diem: e.value })
                    );
                  }}
                />
                <input
                  name="dia_diem"
                  className="hide"
                  defaultValue={dia_diem}
                  value={dia_diem}
                  ref={register({
                    required: true,
                  })}
                ></input>
                {errors.dia_diem && (
                  <Label basic color="red" pointing>
                    Địa điểm không được để trống
                  </Label>
                )}
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <button
              onClick={() => {
                onCancel();
                setChiendich({});
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>

            <button
              disabled={is_saving}
              onClick={handleSaveChanges}
              className="p-btn p-prim-col p-btn-sm margin-left-10"
            >
              Lưu lại
            </button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default DetailModal;
