export const status_enum = {
    is_loading: "is_loading",
    is_completed: "is_completed",
    is_saving: "is_saving",

}
export const ini_state = {
    status: status_enum,
    ts_hocsinh_chuyencap: {},
}
export const action_type = {

    CHANGE_DATA: "CHANGE_DATA",

    BEGIN_SAVING: "BEGIN_SAVING",
    END_SAVING: "END_SAVING"

}

export const reducer = (state, action) => {
    switch (action.type) {

       


        case action_type.CHANGE_DATA:
            return {
                ...state,
                ts_hocsinh_chuyencap: action.payload,
            };

        case action_type.BEGIN_SAVING:
            return {
                ...state,
                status: status_enum.is_saving
            };

        case action_type.END_SAVING:
            return {
                ...state,
                status: status_enum.is_completed
            };


        default:
            return state
    }
}