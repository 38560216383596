import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import app_info from '../../app-info';
import './login-form.scss';
import { Image } from 'semantic-ui-react';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export default function () {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const query = useQuery();
  const hash = query.get("hash");
  const ticket_key = query.get("key");
  
  const { signInSSO } = useAuth();
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { username, password } = formData.current;
    setLoading(true);
    const result = await signIn(username, password);
    if (!result.is_success) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signIn]);


  useEffect(() => {
    (async function () {
      const res = await signInSSO(ticket_key, hash);
      if (!res) {
        window.location.href = app_info.home_url;
      }
    })();
  }, [ticket_key]);
  return (
    <React.Fragment>
      <Image src="./images/3s.png" className="loading-login"></Image>
    </React.Fragment>

  );
}

const usernameEditorOptions = { stylingMode: 'outlined', placeholder: 'Username', mode: 'text' };
const emailEditorOptions = { stylingMode: 'outlined', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'outlined', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
