import React, { useReducer, useEffect, useState, useRef, useContext } from "react";
import { Transition, Icon, Confirm } from 'semantic-ui-react'
import { select_all, remove, select_all_student_status } from "../../api/hocsinh_thoihoc";
import { select_namhoc, select_truong, select_khoi, select_he, } from "../../api/common_api_combo";
import { Column, Lookup } from 'devextreme-react/data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DetailModal from './detail_modal';
import AddThoiHocModal from './add_thoi_hoc_modal';
import { StoreContext } from "../../contexts/store";
import DataGrid, {
    Sorting, Pager, Paging, Selection,
    HeaderFilter, FilterRow,
    SearchPanel
} from 'devextreme-react/data-grid';



const init_state = {
    lops: [],
    selected_lop: {},
}
const action_type = {
    SET_HS_THOI_HOC: "SET_HS_THOI_HOC",
    SET_SELECTED_THOI_HOC: "SET_SELECTED_THOI_HOC"
}
export default () => {

    const [is_loading, set_is_loading] = useState(false);
    const [is_show_modal, set_is_show_modal] = useState(false);
    const [is_show_modal_add, set_is_show_modal_add] = useState(false);
    const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
    const { set_notification, can_accessed } = useContext(StoreContext);
    const [dataXoaAll, setDataXoaAll] = React.useState(null);
    const [isXoaAll, setCheckXoaAll] = React.useState(true);
    const [dataTrangThai, setTrangthai] = useState({});
    const grid = useRef(null);
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case action_type.SET_HS_THOI_HOC:
                return {
                    ...state,
                    hoc_sinh_thoi_hoc: action.payload,
                };
            case action_type.SET_SELECTED_THOI_HOC:
                return {
                    ...state,
                    selected_lop: action.payload,
                };
            default:
                return state
        }
    }, init_state);
    useEffect(() => {
        reload();
    }, []);
    useEffect(() => {
        if (is_loading) {
            grid.current.instance.beginCustomLoading();
        } else {
            grid.current.instance.endCustomLoading();
        }
    }, [is_loading]);
    const reload = async () => {
        if (can_accessed('api/hocsinh_thoihoc/select_all')) {
            set_is_loading(true);
            const res = await select_all();
            set_is_loading(false);
            if (res.is_success) {
                dispatch({ type: action_type.SET_HS_THOI_HOC, payload: res.data });
            }
        }

        const resTrangthai = await select_all_student_status();
        if (resTrangthai.is_success) {
            setTrangthai(resTrangthai.data)
        }

    }


    const btnEdit_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_THOI_HOC, payload: data });
        set_is_show_modal(true);
    }
    const btnDelete_click = (data) => {
        dispatch({ type: action_type.SET_SELECTED_THOI_HOC, payload: data });
        set_is_show_confirm_delete(true);
    }
    const confirmDelete = async () => {
        const res = await remove(state.selected_lop.id);
        if (res.is_success) {
            reload();
            set_is_show_confirm_delete(false);
        } else {
            set_notification({ text: res.message, type: "error" });
        }
    }
    const btnDell_All = (data) => {

        let result = data.selectedRowsData.map(a => a.id);
        setDataXoaAll(result.toString());
    }

    const confirmDeleteAll = async () => {
        debugger
        const res = await remove(dataXoaAll);
        if (res.is_success) {
            reload();
            set_is_show_confirm_delete(false);
        } else {
            set_notification({ text: "res.message", type: "error" });
        }
    }
    const btnDeleteAll_click = () => {
        if (dataXoaAll == null || dataXoaAll == undefined) {

            set_notification({ text: "Vui lòng chọn học sinh cần xóa!", type: "warning" });
            return false;
        }
        else {
            set_is_show_confirm_delete(true);
        }

    }
    const cellRender = (data) => {
        return <div>
            {can_accessed('api/lop/update') &&
                <span style={{ cursor: "pointer" }} onClick={() => { btnEdit_click(data.data) }}><i aria-hidden="true" className="icon edit"></i> </span>
            }
            {/* {can_accessed('api/lop/delete') &&
                <span style={{ cursor: "pointer" }} onClick={() => { btnDelete_click(data.data) }}><i aria-hidden="true" className="icon trash"></i> </span>
            } */}
        </div>;
    }
    const column = [
        <Column key={0} cellRender={cellRender} caption="Thao tác" width={100}></Column>,
        <Column key={1} dataField="dm_trangthaihocsinh_id" caption="Trạng thái">
            <Lookup key={11} dataSource={dataTrangThai} displayExpr="trang_thai" valueExpr="id" />
        </Column>,
        <Column key={2} dataField="ma_hs" caption="Mã học sinh"></Column>,
        <Column key={3} dataField="ho_ten" caption="Tên học sinh"></Column>,
        <Column key={4} dataField="ngay_nop_don" dataType="date" format="dd/MM/yyyy" caption="Ngày nộp đơn"></Column>,
        <Column key={5} dataField="ngay_chinh_thuc" dataType="date" format="dd/MM/yyyy" caption="Ngày chính thức"></Column>,
        <Column key={6} dataField="ly_do" caption="Lý do">
        </Column>,
        <Column key={7} dataField="chi_tiet" caption="Chi tiết">
        </Column>,
        <Column key={8} dataField="truong_chuyen_den" caption="Trường chuyển đến">
        </Column>,
        <Column key={9} dataField="ghi_chu" caption="Ghi chú">
        </Column>
    ];
    return (
        <React.Fragment>
            <Transition animation="scale"
                visible={true}
                duration={500}
                transitionOnMount={true}>
                <div>
                    <div className="row">
                        <div className="col-md-12 padding-top-1rem padding-left-2rem">
                            <Toolbar className="bg-background">
                                <Item location="before"
                                    render={() => {
                                        return (
                                            <h3>Danh sách học sinh thôi học bảo lưu</h3>
                                        );
                                    }}
                                />
                                {can_accessed("api/lop/insert") &&
                                    <Item location="after"
                                        render={() => {
                                            return (
                                                <a className="p-btn p-prim-col  p-white-color p-btn-sm" onClick={() => {
                                                    set_is_show_modal_add(true);
                                                    dispatch({ type: action_type.SET_SELECTED_THOI_HOC, payload: { id: 0 } });
                                                }}  >
                                                    <Icon name="plus"></Icon>
                                                    Thêm mới
                                                </a>
                                            );
                                        }}
                                    />
                                }
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a disabled={(dataXoaAll == null || dataXoaAll == "")} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color" onClick={() => { btnDeleteAll_click() }}   >
                                                <Icon name="cancel"></Icon>
                                                Xóa {(dataXoaAll != null && dataXoaAll != "") ? "(" + dataXoaAll.split(',').length + ")" : ""}
                                            </a>
                                        );
                                    }}
                                />
                                <Item location="after"
                                    render={() => {
                                        return (
                                            <a className="p-btn  p-btn-sm" onClick={() => { grid.current.instance.exportToExcel(); }}  >
                                                <Icon name="file excel outline"></Icon>
                                                Xuất excel
                                            </a>
                                        );
                                    }}
                                />
                            </Toolbar>
                        </div>


                        <DetailModal is_show_model={is_show_modal}
                            onCancel={() => { set_is_show_modal(false) }}
                            onSuccess={() => { set_is_show_modal(false); reload(); }}
                            id={state.selected_lop.id}
                            dataTrangThai_ThoiHoc={dataTrangThai}
                        ></DetailModal>
                        
                        <AddThoiHocModal is_show_model={is_show_modal_add}
                            onCancel={() => { set_is_show_modal_add(false) }}
                            onSuccess={() => { set_is_show_modal_add(false); reload(); }}
                            id={state.selected_lop.id}
                            dataTrangThai_ThoiHoc={dataTrangThai}
                        ></AddThoiHocModal>
                    </div>
                    <DataGrid
                        ref={grid}
                        keyExpr="id"
                        dataSource={state.hoc_sinh_thoi_hoc}
                        columnHidingEnabled={false}
                        columnAutoWidth={true}
                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={false}
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        columnResizingMode={"nextColumn"}
                        hoverStateEnabled={true}
                        focusedRowEnabled={false}
                        height={window.innerHeight - 150}
                        onSelectionChanged={btnDell_All}

                    >
                        {column}
                        <Selection mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="always"
                        ></Selection>
                        <Sorting mode="multiple" />
                        <Paging defaultPageSize={10} />
                        {/*<Selection mode="multiple" />*/}
                        <FilterRow visible={true} />
                        {/* <FilterPanel visible={true} /> */}
                        <HeaderFilter visible={true} />
                        {/* <SearchPanel visible={true}
                                width={240}
                                placeholder="Search..." /> */}
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50, 200, 500]}
                            infoText="Trang {0} /{1} ({2} dòng)"
                            showInfo={true} />
                    </DataGrid>
                </div>
            </Transition>
            <Confirm
                open={is_show_confirm_delete}
                dimmer={"blurring"}
                header='Lưu ý'
                content='Bạn có chắc chắn muốn xóa học sinh ?'
                size='mini'
                cancelButton={() => {
                    return (
                        <button onClick={() => { set_is_show_confirm_delete(false) }} className="p-btn p-btn-sm">
                            Đóng
                        </button>
                    );
                }}
                confirmButton={() => {
                    return (
                        <button onClick={() => { state.selected_lop.id ? confirmDelete() : confirmDeleteAll() }} className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color">
                            Xóa
                        </button>
                    );
                }}
            />
        </React.Fragment>
    );
}