export const status_enum = {
  is_loading: 'is_loading',
  is_completed: 'is_completed',
  is_saving: 'is_saving',
};
export const ini_state = {
  status: status_enum,
  file_name: "",
  data_source: []

};
export const action_type = {

  CHANGE_FILENAME: 'CHANGE_FILENAME',

  BEGIN_SELECT_DATA: 'BEGIN_SELECT_DATA',
  END_SELECT_DATA: 'END_SELECT_DATA',

  BEGIN_SAVING: 'BEGIN_SAVING',
  END_SAVING: 'END_SAVING',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case action_type.CHANGE_FILENAME:
      return {
        ...state,
        file_name: action.payload,
      };
    case action_type.BEGIN_SELECT_DATA:
      return {
        ...state,
        status: status_enum.is_loading
      };

    case action_type.END_SELECT_DATA:
      return {
        ...state,
        status: status_enum.is_completed,
        data_source: action.payload
      };

    case action_type.BEGIN_SAVING:
      return {
        ...state,
        status: status_enum.is_saving,
      };

    case action_type.END_SAVING:
      return {
        ...state,
        status: status_enum.is_completed,
      };

    default:
      return state;
  }
};
