import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { Transition, Icon, Confirm } from "semantic-ui-react";
import { Column } from "devextreme-react/data-grid";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { TextBox } from "devextreme-react/text-box";
import { Card, Switch, Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { StoreContext } from "../../contexts/store";
import {
  hoc_sinh_select_by_id,
  hoc_sinh_select_ace_by_id,
  select_not_in_ace_chi_tiet,
} from "../../api/hocsinh_api";
import { dm_namhoc_select_all } from "../../api/dm_namhoc_api";
import {
  set_is_huonguudai,
  remove_ts_hocsinh_ace_chitiet,
  insert_ace_chitiet,
} from "../../api/ts_hocsinh_ace_chitiet";
import DataGrid, {
  Sorting,
  Pager,
  Paging,
  Selection,
  FilterRow,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
const ini_state = {
  list_hocsinh_ace: [],
  selected_hocsinh_ace: {},
};

const action_type = {
  GET_LIST_HOCSINH_ACE: "GET_LIST_HOCSINH_ACE",
  SET_SELECTED_HOCSINH_ACE: "SET_SELECTED_HOCSINH_ACE",
};

const { Option } = Select;
let timeout;
export default ({ onSuccess, onCancel, id, id_insert_new_hocsinh }) => {
  const [is_loading, set_is_loading] = useState(false);
  const [list_dm_namhoc, set_list_dm_namhoc] = useState();
  const [default_nam_hoc, set_default_nam_hoc] = useState();
  const [hoc_sinh_info, set_hocsinh_info] = useState({});
  const [list_hocsinh_not_in_ace_chitiet, set_list_hocsinh_not_in_ace_chitiet] =
    useState([]);
  const [hocsinh_ace_info_insert_id, set_hocsinh_ace_info_insert_id] =
    useState();
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const { set_notification, can_accessed } = useContext(StoreContext);
  const grid = useRef(null);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case action_type.GET_LIST_HOCSINH_ACE:
        return {
          ...state,
          list_hocsinh_ace: action.payload,
        };
      case action_type.SET_SELECTED_HOCSINH_ACE:
        return {
          ...state,
          selected_hocsinh_ace: action.payload,
        };
      default:
        return state;
    }
  }, ini_state);

  useEffect(() => {
    reload();
  }, [default_nam_hoc]);
  useEffect(() => {
    get_list_dm_namhoc();
    get_hoc_sinh_info();
    reload();
  }, []);
  useEffect(() => {
    if (is_loading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [is_loading]);
  const reload = async () => {
    set_is_loading(true);
    const res = await hoc_sinh_select_ace_by_id({
      id: id > 0 ? id : id_insert_new_hocsinh,
      nam_hoc: default_nam_hoc,
    });
    set_is_loading(false);
    if (res.is_success) {
      dispatch({ type: action_type.GET_LIST_HOCSINH_ACE, payload: res.data });
    }
  };

  const get_list_dm_namhoc = async () => {
    const res = await dm_namhoc_select_all();
    if (res.is_success) {
      set_list_dm_namhoc(res.data);
      set_default_nam_hoc(res.data?.find((x) => x.is_default == true).nam_hoc);
    }
  };

  const get_hoc_sinh_info = async () => {
    const res = await hoc_sinh_select_by_id(
      id > 0 ? id : id_insert_new_hocsinh
    );
    if (res.is_success) {
      set_hocsinh_info(res.data);
    }
  };

  // const btnEdit_click = (data) => {
  //   dispatch({ type: action_type.SET_SELECTED_HOCSINH_ACE, payload: data });
  //   set_is_show_modal(true);
  // };
  const btnDelete_click = (data) => {
    dispatch({ type: action_type.SET_SELECTED_HOCSINH_ACE, payload: data });
    set_is_show_confirm_delete(true);
  };

  const confirmDelete = async () => {
    const res = await remove_ts_hocsinh_ace_chitiet(
      state.selected_hocsinh_ace?.ts_hocsinh_ace_chitiet_id
    );
    if (res.is_success) {
      reload();
      set_is_show_confirm_delete(false);
    } else {
      set_notification({ text: res.message, type: "error" });
    }
  };

  const cellRender = (data) => {
    return (
      <div>
        {/* {can_accessed("api/hocsinh/update") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{" "}
          </span>
        )} */}
        {can_accessed("api/hocsinh/delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{" "}
          </span>
        )}
      </div>
    );
  };

  const handleSearchHocSinhNotInACEChiTiet = async (value) => {
    set_list_hocsinh_not_in_ace_chitiet([]);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => callAPINotInACEChiTiet(value), 1000);
  };

  const callAPINotInACEChiTiet = async (value) => {
    if (value?.trim()?.length) {
      const res = await select_not_in_ace_chi_tiet({
        search: value,
        nam_hoc: default_nam_hoc,
      });
      if (res.is_success) {
        set_list_hocsinh_not_in_ace_chitiet(res.data);
      }
    }
  };

  const handleChangeSearchHocSinhNotInACEChiTiet = (value) => {
    set_hocsinh_ace_info_insert_id(value);
  };

  const handleChangeHuongUuDai = async (column) => {
    const res = await set_is_huonguudai({
      id: column.data.ts_hocsinh_ace_chitiet_id,
      is_huonguudai: column.value == 1 ? 0 : 1,
    });
    if (res.is_success) {
      reload();
      set_notification({
        text: "Thay đổi chế độ hưởng ưu đãi thành công.",
        type: "success",
      });
    }
  };

  const toggle_huonguudai = (data) => {
    return (
      <Switch
        checkedChildren="Có"
        unCheckedChildren="Không"
        checked={data.value == 1 ? true : false}
        onChange={() => handleChangeHuongUuDai(data)}
      />
    );
  };

  const handleAddACE = async () => {
    if (hocsinh_ace_info_insert_id > 0) {
      const res = await insert_ace_chitiet({
        hocsinh_id: id > 0 ? id : id_insert_new_hocsinh,
        id_ace_insert: hocsinh_ace_info_insert_id,
        nam_hoc: default_nam_hoc,
      });
      if (res.is_success) {
        reload();
        set_notification({
          text: "Thêm thành công.",
          type: "success",
        });
      }
    }
  };

  const column = [
    <Column
      key={0}
      cellRender={cellRender}
      caption="Thao tác"
      width={100}
    ></Column>,
    <Column key={1} dataField="ma_tn" caption="Mã TN" width={100}></Column>,
    <Column key={2} dataField="ma_hs" caption="Mã HS" width={100}></Column>,
    <Column key={3} dataField="ho_ten" caption="Họ tên"></Column>,
    <Column
      key={4}
      dataField="ngay_sinh"
      caption="Ngày sinh"
      dataType="date"
      format="dd/MM/yyyy"
    ></Column>,
    <Column key={3} dataField="gioi_tinh" caption="Giới tính"></Column>,
    <Column
      key={3}
      dataField="is_huonguudai"
      cellRender={toggle_huonguudai}
      caption="Hưởng ưu đãi"
    ></Column>,
  ];
  return (
    <React.Fragment>
      <Transition
        animation="scale"
        visible={true}
        duration={500}
        transitionOnMount={true}
      >
        <div>
          <Card title="Thông tin học sinh" size="small">
            <div className="row">
              <div className="col-md-3">
                <div className="dx-field-label">Mã hồ sơ</div>
                <br></br>
                <TextBox
                  value={hoc_sinh_info.ma_tn}
                  stylingMode="outlined"
                  disabled
                />
                <input
                  name="ma_tn"
                  className="hide"
                  defaultValue={hoc_sinh_info.ma_tn}
                  value={hoc_sinh_info.ma_tn}
                ></input>
              </div>
              <div className="col-md-3">
                <div className="dx-field-label">Họ tên học sinh</div>
                <br></br>
                <TextBox
                  value={hoc_sinh_info.ho_ten}
                  stylingMode="outlined"
                  disabled
                />
                <input
                  name="ho_ten"
                  className="hide"
                  defaultValue={hoc_sinh_info.ho_ten}
                  value={hoc_sinh_info.ho_ten}
                ></input>
              </div>
            </div>
          </Card>
          <div
            className="row padding-top-1rem"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-8">
              <Select
                showSearch
                style={{ width: "100%" }}
                // value={hocsinh_ace_info_insert}
                placeholder="Nhập mã hoặc họ và tên anh chị em"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchHocSinhNotInACEChiTiet}
                onChange={handleChangeSearchHocSinhNotInACEChiTiet}
                notFoundContent={null}
              >
                {list_hocsinh_not_in_ace_chitiet?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.ma_tn} -- {item.ho_ten}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="col-md-4">
              <a
                className="p-btn p-prim-col  p-white-color p-btn-sm"
                onClick={handleAddACE}
              >
                <Icon name="plus"></Icon>
                Thêm
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 padding-top-1rem">
              <Toolbar className="bg-background">
                <Item
                  location="before"
                  render={() => {
                    return <h3>Thông tin anh chị em học cùng</h3>;
                  }}
                />
                {/* {can_accessed("api/hocsinh/insert") && (
                  <Item
                    location="after"
                    render={() => {
                      return (
                        <a
                          className="p-btn p-prim-col  p-white-color p-btn-sm"
                          onClick={() => {
                            set_is_show_modal(true);
                            dispatch({
                              type: action_type.SET_SELECTED_HOCSINH_ACE,
                              payload: { id: 0 },
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          Thêm mới
                        </a>
                      );
                    }}
                  />
                )} */}
                {/* <Item
                  location="after"
                  render={() => {
                    return (
                      <a
                        className="p-btn  p-btn-sm"
                        onClick={() => {
                          grid.current.instance.exportToExcel();
                        }}
                      >
                        <Icon name="file excel outline"></Icon>
                        Xuất excel
                      </a>
                    );
                  }}
                /> */}
              </Toolbar>
            </div>
            <div className="col-md-12">
              <DataGrid
                ref={grid}
                keyExpr={"id"}
                dataSource={state.list_hocsinh_ace}
                columnHidingEnabled={false}
                //defaultColumns={columns}
                columnAutoWidth={false}
                showBorders={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                columnResizingMode={"nextColumn"}
                hoverStateEnabled={true}
                focusedRowEnabled={false}
                height={window.innerHeight - 500}
                className={"table-noborder-left"}
              >
                <Sorting mode="multiple" />
                <Paging defaultPageSize={10} />
                <Selection mode="single"></Selection>
                {/* <Selection mode="multiple" /> */}
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                {/* <Scrolling mode="infinite" /> */}
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 200, 500]}
                  infoText="Trang {0} /{1} ({2} dòng)"
                  showInfo={true}
                />
                {column}
              </DataGrid>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <button onClick={() => onCancel()} className="p-btn p-btn-sm">
              Đóng
            </button>
          </div>
        </div>
      </Transition>
      <Confirm
        open={is_show_confirm_delete}
        // dimmer={"blurring"}
        header="Lưu ý"
        content="Bạn có chắc chắn muốn hủy quan hệ anh chị em ?"
        size="mini"
        cancelButton={() => {
          return (
            <button
              onClick={() => {
                set_is_show_confirm_delete(false);
              }}
              className="p-btn p-btn-sm"
            >
              Đóng
            </button>
          );
        }}
        confirmButton={() => {
          return (
            <button
              onClick={() => {
                confirmDelete();
              }}
              className="p-btn p-btn-sm p-strawberry margin-left-10 p-white-color p-white-color"
            >
              Hủy quan hệ
            </button>
          );
        }}
      />
    </React.Fragment>
  );
};
