//DEV
// export default {
//  title: 'Quản lý tuyển sinh',
//  api_url: 'https://localhost:44392/api',
//  home_url: 'http://103.152.165.45',
//  sub_system_id: 1,
// };
//UAT
// export default {
//     title: 'Quản lý tuyển sinh',
//     api_url: 'http://demo-admission.3s.edu.vn/api',
//     home_url:'http://demo-portal.3s.edu.vn',
//     sub_system_id: 1
// };
// //PRODUCT

export default {
    title: 'Quản lý tuyển sinh',
    api_url: 'https://phenikaa-admission.3s.edu.vn/api',
    //'https://localhost:5001/api',
    home_url: 'https://portal.3s.edu.vn',
    sub_system_id: 1
};
