import React from 'react';

import { Column } from 'devextreme-react/data-grid';

const getColumnsDataGrid = ({
  btnEdit_click,
  btnDelete_click,
  btnDeleteUserTuVanVien_click,
  can_accessed,
  grid,
}) => {
  const cellRender = (data) => {
    return (
      <div>
        {can_accessed('api/ts_chiendich_phuhuynh/update') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnEdit_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{' '}
          </span>
        )}
        {can_accessed('api/ts_chiendich_phuhuynh/delete') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnDelete_click(data.data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{' '}
          </span>
        )}
        {can_accessed('api/ts_chiendich_phuhuynh/deleteTuVanVien') &&
          data.data.user_id_tuvan > 0 && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                btnDeleteUserTuVanVien_click(data.data);
              }}
            >
              <i aria-hidden="true" className="icon user times"></i>{' '}
            </span>
          )}
      </div>
    );
  };

  const cellExecutedRender = ({ data }) => {
    const { sl_da_xu_ly, sl_tong } = data;
    return (
      <p className={`text-${sl_da_xu_ly < sl_tong ? 'danger' : 'success'}`}>
        {sl_da_xu_ly}/{sl_tong}
      </p>
    );
  };
  return [
    <Column cellRender={cellRender} caption="Thao tác" width={100}></Column>,
    <Column
      headerCellRender={() => <p style={{ textAlign: 'center' }}>Trạng thái</p>}
      dataField="trang_thai"
      caption="Trạng thái"
      width={100}
    ></Column>,
    <Column
      dataField="ten_chien_dich"
      caption="Tên chiến dịch"
      width={200}
    ></Column>,
    <Column
      dataField="ten_ph"
      caption="Tên phụ huynh"
      width={150}
      cellRender={(cell) => {
        return (
          <a
            onClick={() => {
              if (grid.current.instance.isRowExpanded(cell.data.id)) {
                grid.current.instance.collapseAll(-1);
              } else {
                grid.current.instance.collapseAll(-1);
                grid.current.instance.expandRow(cell.data.id);
              }
            }}
          >
            {cell.data.ten_ph} <i className="fas fa-chevron-down"></i>
          </a>
        );
      }}
    ></Column>,
    <Column
      cellRender={cellExecutedRender}
      alignment="center"
      caption="SL đã xử lý"
      width={150}
    ></Column>,
    <Column
      dataField="dien_thoai_ph"
      caption="ĐT phụ huynh"
      width={150}
    ></Column>,
    <Column dataField="dien_thoai_khac" caption="ĐT khác" width={150}></Column>,
    <Column
      dataField="email_ph"
      caption="Email phụ huynh"
      width={250}
    ></Column>,
    <Column dataField="ten_khoi" caption="Khối" width={100}></Column>,
    <Column dataField="user_tuvan" caption="Người tư vấn" width={150}></Column>,
    <Column dataField="ghi_chu" caption="Ghi chú" width={200}></Column>,
    <Column dataField="ten_hs" caption="Tên HS" width={200}></Column>,
    <Column dataField="ma_tn" caption="Mã TN" width={200}></Column>,
    <Column dataField="ma_hs" caption="Mã HS" width={200}></Column>,
    <Column dataField="ho_ten" caption="Họ tên HS" width={200}></Column>,
  ];
};

export default getColumnsDataGrid;
