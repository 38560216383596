import appInfo from "../app-info";
import { call_post_api } from "./api_helper";
export async function set_is_huonguudai(data) {
  return call_post_api(appInfo.api_url + "/hocsinh_chitiet_ace/set_is_huonguudai",data);
}

export async function remove_ts_hocsinh_ace_chitiet(id) {
    return call_post_api(appInfo.api_url + '/hocsinh_chitiet_ace/delete',id);
}

export async function insert_ace_chitiet(data) {
  return call_post_api(appInfo.api_url + "/hocsinh_chitiet_ace/insert_ace_chitiet",data);
}
