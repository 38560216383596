import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { useQuery } from 'react-query';

import {
  campaign_detail_remove,
  campaign_detail_toggle_status,
  select_by_campaign,
} from '../../../api/ts_chiendich_phuhuynh_chitiet_api';
import ToolbarDetailCampaignDataGrid from './ToolbarDetailCampaignDataGrid';
import DetailModal from './DetailModal';
import { StoreContext } from '../../../contexts/store';
import DeleteModal from '../DeleteModal';
import ChangeStatusModal from '../ChangeStatusModal';

const DetailCampaignTemplate = ({ data }) => {
  const grid = useRef(null);
  const [is_show_modal, set_is_show_modal] = useState(false);
  const [is_show_confirm_delete, set_is_show_confirm_delete] = useState(false);
  const [is_show_confirm_change_status, set_is_show_confirm_change_status] =
    useState(false);
  const [list_campaign_details_selected, set_list_campaign_details_selected] =
    useState([]);
  const [selected_campaign_detail, set_selected_campaign_detail] = useState({});
  const { can_accessed, set_notification } = useContext(StoreContext);
  const { id, ten_ph } = data.data;
  const {
    data: campaignDetails,
    isLoading,
    refetch,
  } = useQuery(['campaign-detail', id], () => select_by_campaign(id), {
    enabled: Boolean(id),
  });
  useEffect(() => {
    if (isLoading) {
      grid.current.instance.beginCustomLoading();
    } else {
      grid.current.instance.endCustomLoading();
    }
  }, [isLoading]);
  const btnEdit_click = (data) => {
    set_is_show_modal(true);
    set_selected_campaign_detail(data);
  };
  const btnDelete_click = (data) => {
    set_is_show_confirm_delete(true);
    set_selected_campaign_detail(data);
  };
  const btnChangeStatus_click = (data) => {
    set_is_show_confirm_change_status(true);
    set_selected_campaign_detail(data);
  };
  const cellRender = ({ data }) => {
    return (
      <div>
        {can_accessed('api/chiendich_phuhuynh_chitiet/update') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnEdit_click(data);
            }}
          >
            <i aria-hidden="true" className="icon edit"></i>{' '}
          </span>
        )}
        {can_accessed('api/chiendich_phuhuynh_chitiet/changeStatus') &&

          <span
            style={{ cursor: 'pointer' }}
            className={data.da_xu_ly ? "cmd-disabled" : ""}
            onClick={() => {
              if (!data.da_xu_ly) {
                btnChangeStatus_click(data);
              }

            }}
          >
            <i aria-hidden="true" className="icon check color-green"></i>{' '}
          </span>
        }
        {can_accessed('api/chiendich_phuhuynh_chitiet/delete') && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              btnDelete_click(data);
            }}
          >
            <i aria-hidden="true" className="icon trash"></i>{' '}
          </span>
        )}
      </div>
    );
  };
  const confirmDelete = async () => {
    const res = await campaign_detail_remove(selected_campaign_detail.id);
    if (res.is_success) {
      refetch();
      set_is_show_confirm_delete(false);
      set_notification({
        text: 'Xóa thông tin thành công',
        type: 'success',
      });
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };
  const onChangeStatus = async () => {
    const res = await campaign_detail_toggle_status(
      selected_campaign_detail.id
    );
    if (res.is_success) {
      refetch();
      set_is_show_confirm_change_status(false);
      set_notification({
        text: 'Thay đổi trạng thái thành công',
        type: 'success',
      });
    } else {
      set_notification({ text: res.message, type: 'error' });
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12 padding-left-2rem">
          <ToolbarDetailCampaignDataGrid
            set_is_show_modal={set_is_show_modal}
            ten_ph={ten_ph}
          />
        </div>
        <div className="col-md-12">
          <DataGrid
            ref={grid}
            dataSource={campaignDetails?.data || []}
            showBorders={true}
            columnAutoWidth={true}
            selectedRowKeys={list_campaign_details_selected}
            onSelectionChanged={({ selectedRowKeys }) => {
              set_list_campaign_details_selected(selectedRowKeys);
            }}
          >
            <HeaderFilter visible={true} />
            <Column
              cellRender={cellRender}
              caption="Thao tác"
              width={80}
            ></Column>
            <Column
              dataField="ngay_tu_van"
              dataType="date"
              width={150}
              caption="Ngày tư vấn"
              format="dd/MM/yyyy"
            />
            <Column dataField="da_xu_ly" width={120} caption="Đã xử lý" cellRender={(cell) => {
              if (cell.data.da_xu_ly) return <b className="text-success">Đã xử lý</b>
              if (!cell.data.da_xu_ly) return <p className="text-danger">Chưa xử lý</p>
            }} />
            <Column dataField="noi_dung_tu_van" caption="Nội dung tư vấn" />
          </DataGrid>
        </div>
      </div>

      <DetailModal
        is_show_model={is_show_modal}
        onCancel={() => {
          set_is_show_modal(false);
        }}
        onSuccess={() => {
          set_is_show_modal(false);
          refetch();
        }}
        id={selected_campaign_detail.id}
        campaignId={id}
      />
      <DeleteModal
        is_show_confirm_delete={is_show_confirm_delete}
        set_is_show_confirm_delete={set_is_show_confirm_delete}
        confirmDelete={confirmDelete}
      />
      <ChangeStatusModal
        is_show_confirm_change_status={is_show_confirm_change_status}
        set_is_show_confirm_change_status={set_is_show_confirm_change_status}
        handleSubmitChangeStatus={onChangeStatus}
      />
    </>
  );
};

export default DetailCampaignTemplate;
