import React, { useState, useEffect } from "react";
import { Label, Modal, Loader } from "semantic-ui-react";
import { Tabs } from "antd";
import "antd/dist/antd.css";

import ThongTinHocSinhChiTiet from "./detail_hocsinh_tab";
import ThongTinGiaDinh from "./hocsinh_qhgd_tab";
import ThongTinACE from "./ace_hoccung_tab";
import ThongTinConCBNV from "../../pages/ts_thongtin_cbnv/ts_thongtin_cbnv";
const { TabPane } = Tabs;

export default ({ is_show_model, id, onSuccess, onCancel }) => {
  const [id_insert_new_hocsinh, set_id_insert_new_hocsinh] = useState(0);
  const onSuccessChiTietHocSinh = (id_insert) => {
    set_id_insert_new_hocsinh(id_insert);
    onSuccess();
  }
  return (
    <React.Fragment>
      <Modal
        closeIcon={false}
        open={is_show_model}
        size="large"
        // dimmer={"blurring"}
        onClose={onCancel}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Hồ sơ học sinh" key="1">
              <ThongTinHocSinhChiTiet onSuccess={onSuccessChiTietHocSinh} onCancel={onCancel} id={id} />
            </TabPane>
            <TabPane tab="Thông tin quan hệ gia đình" key="2">
              {(id > 0 || id_insert_new_hocsinh > 0) ? (
                <ThongTinGiaDinh onSuccess={onSuccess} onCancel={onCancel} id={id} id_insert_new_hocsinh={id_insert_new_hocsinh} />

              ) : (
                <React.Fragment>
              <div style={{ color: "red" }}>Vui lòng lưu hồ sơ học sinh trước</div>
              <div className="text-align-right">
                    <button onClick={() => onCancel()} className="p-btn p-btn-sm">
                      Đóng
                    
            </button>
            </div>
              </React.Fragment>
              )}
            </TabPane>
            <TabPane tab="Thông tin anh chị em học cùng" key="3">
              {(id > 0 || id_insert_new_hocsinh > 0) ? (
                <ThongTinACE onSuccess={onSuccess} onCancel={onCancel} id={id} id_insert_new_hocsinh={id_insert_new_hocsinh} />

              ) : (
                <React.Fragment>
              <div style={{ color: "red" }}>Vui lòng lưu hồ sơ học sinh trước</div>
              <div className="text-align-right">
                    <button onClick={() => onCancel()} className="p-btn p-btn-sm">
                      Đóng
                    
            </button>
            </div>
              </React.Fragment>
              )}
            </TabPane>
            <TabPane tab="Thông tin con CBNV" key="4">
              {(id > 0 || id_insert_new_hocsinh > 0) ? (
                <React.Fragment>
                  <ThongTinConCBNV ts_hocsinh_id={id > 0 ? id : id_insert_new_hocsinh} height={window.innerHeight - 300} />
                  <div className="text-align-right">
                    <button onClick={() => onCancel()} className="p-btn p-btn-sm">
                      Đóng
                    
            </button>
            </div>
                </React.Fragment>

              ) : (
              <React.Fragment>
              <div style={{ color: "red" }}>Vui lòng lưu hồ sơ học sinh trước</div>
              <div className="text-align-right">
                    <button onClick={() => onCancel()} className="p-btn p-btn-sm">
                      Đóng
                    
            </button>
            </div>
              </React.Fragment>
              )}
            </TabPane>
          </Tabs>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};
